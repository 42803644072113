import React from "react";
import { Button } from "react-bootstrap";
import {
  BACK_TO_START,
  PAGE_NOT_FOUND_TEXT,
  PAGE_NOT_FOUND_TITLE,
} from "./strings/string";
import { useHistory } from "react-router-dom";
import "./url-not-found.scss";

const UrlNotFound = () => {
  const history = useHistory();

  const toHome = () => {
    history.push("/");
  };

  return (
    <div>
      <header className="top-header"></header>
      <div>
        <div className="starsec"></div>
        <div className="starthird"></div>
        <div className="starfourth"></div>
        <div className="starfifth"></div>
      </div>
      <div className="lamp__wrap">
        <div className="lamp">
          <div className="cable"></div>
          <div className="cover"></div>
          <div className="in-cover">
            <div className="bulb"></div>
          </div>
          <div className="light"></div>
        </div>
      </div>
      <section className="error">
        <div className="error__content">
          <div className="error__message message">
            <h1 className="message__title">{PAGE_NOT_FOUND_TITLE}</h1>
            <p className="message__text">{PAGE_NOT_FOUND_TEXT}</p>
          </div>
          <div className="error__nav e-nav">
            <Button
              variant="none"
              className="s4-button-accept"
              onClick={toHome}
            >
              <p className="s4-button-text">{BACK_TO_START}</p>
            </Button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default UrlNotFound;
