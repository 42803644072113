import React, { useState } from "react";
import { getColor } from "../../utils";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import ModalRemoveActivity from "./modal-remove-activity";
import { IFirebaseActivity } from "../../models/firebase-activity";
import { ActivityType } from "../../enums/activity";
import ModalEditActivity from "./modal-edit-activity";

interface Props {
  activity: IFirebaseActivity;
}

const DashboardActivityCard = ({ activity }: Props) => {
  const [activityId, setActivityId] = useState("");
  const [openModalRemove, setOpenModalRemove] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);

  const removeActivity = (id: string) => () => {
    setActivityId(id);
    handleOpenDeleteModal();
  };

  const editActivity = (id: string) => () => {
    setActivityId(id);
    handleOpenEditModal();
  };

  const handleOpenDeleteModal = () => {
    setOpenModalRemove(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenModalRemove(false);
  };

  const handleOpenEditModal = () => {
    setOpenModalEdit(true);
  };

  const handleCloseEditModal = () => {
    setOpenModalEdit(false);
  };

  return (
    <div
      className="dashboard-activity-card"
      style={{ backgroundColor: getColor(activity.type as ActivityType) }}
    >
      <h5>{activity.title}</h5>
      <div>
        <CloseIcon onClick={removeActivity(activity.id)} />
        <EditIcon onClick={editActivity(activity.id)} />
      </div>

      <ModalRemoveActivity
        show={openModalRemove}
        handleClose={handleCloseDeleteModal}
        activityId={activityId}
      />

      <ModalEditActivity
        show={openModalEdit}
        handleClose={handleCloseEditModal}
        activity={activity}
      />
    </div>
  );
};

export default DashboardActivityCard;
