import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context-api/context";
import SnackBarAlert from "../S2-select-activities/snack-bar";
import "./modal-alert.scss";
import { Variant } from "../../enums/snackbar";
import { Program } from "../../enums/activity";
import { EmptyCards } from "../../strings/empty-cards";
import {
  SNACKBAR_DEACTIVATE_DAY,
  SNACKBAR_ACTIVATE_DAY,
  SNACKBAR_DEACTIVATE_NIGHT,
  SNACKBAR_ACTIVATE_NIGHT,
} from "../../strings/string";
import { Modal } from "react-bootstrap";
import ModalAlertBody from "./modal-alert-body";

const ModalAlert = () => {
  const {
    setProgram,
    program,
    isShowed,
    setIsShowed,
    isDay,
    dayOne,
    dayTwo,
    dayThree,
    dayFour,
    dayFive,
    daySix,
    daySeven,
    setDayOne,
    setDayTwo,
    setDayThree,
    setDayFour,
    setDayFive,
    setDaySix,
    setDaySeven,
  } = useContext(UserContext);
  const [showModal, setShowModal] = useState(false);
  const [showSnack, setShowSnack] = useState(false);
  const [showSnackEvening, setShowSnackEvening] = useState(false);

  const days = [dayOne, dayTwo, dayThree, dayFour, dayFive, daySix, daySeven];

  const handleCloseModal = () => {
    setShowModal(false);
    setIsShowed && setIsShowed(false);
  };

  const handleSetDay = () => {
    setProgram([Program.DAY, Program.NIGHT]);
    setShowModal(false);
    setIsShowed && setIsShowed(false);
    setShowSnack(true);
    setTimeout(() => {
      setShowSnack(false);
    }, 3000);
  };

  const handleUnSetDay = () => {
    resetDay();
    setProgram([Program.NIGHT]);
    setShowModal(false);
    setIsShowed && setIsShowed(false);
    setShowSnack(true);
    setTimeout(() => {
      setShowSnack(false);
    }, 3000);
  };

  const handleSetEvening = () => {
    setProgram([Program.DAY, Program.NIGHT]);
    setShowModal(false);
    setIsShowed && setIsShowed(false);
    setShowSnackEvening(true);
    setTimeout(() => {
      setShowSnack(false);
    }, 3000);
  };

  const handleUnSetEvening = () => {
    resetEvening();
    setProgram([Program.DAY]);
    setShowModal(false);
    setIsShowed && setIsShowed(false);
    setShowSnackEvening(true);
    setTimeout(() => {
      setShowSnack(false);
    }, 3000);
  };

  useEffect(() => {
    isShowed === true && setShowModal(true);
  }, [isShowed]);

  const resetDay = () => {
    days.forEach((day) => {
      day.day &&
        day.day.forEach((value, key) => {
          if (value.title !== "") {
            day.day.set(key, {
              ...EmptyCards[0],
              id: Math.random().toString(),
            });
          }
        });
    });
    setDays();
  };

  const resetEvening = () => {
    days.forEach((day) => {
      day.evening &&
        day.evening.minidisco.forEach((value, key) => {
          if (value.title !== "") {
            day.evening.minidisco.set(key, {
              ...EmptyCards[0],
              id: Math.random().toString(),
            });
          }
        });
      day.evening.showTime.forEach((value, key) => {
        if (value.title !== "") {
          day.evening.showTime.set(key, {
            ...EmptyCards[0],
            id: Math.random().toString(),
          });
        }
      });
      day.evening.lateNight &&
        day.evening.lateNight.forEach((value, key) => {
          if (value.title !== "") {
            day.evening.lateNight!.set(key, {
              ...EmptyCards[0],
              id: Math.random().toString(),
            });
          }
        });
    });
    setDays();
  };

  const setDays = () => {
    setDayOne({
      day: dayOne.day,
      evening: dayOne.evening,
    });
    setDayTwo({
      day: dayTwo.day,
      evening: dayTwo.evening,
    });
    setDayThree({
      day: dayThree.day,
      evening: dayThree.evening,
    });
    setDayFour({
      day: dayFour.day,
      evening: dayFour.evening,
    });
    setDayFive({
      day: dayFive.day,
      evening: dayFive.evening,
    });
    setDaySix({
      day: daySix.day,
      evening: daySix.evening,
    });
    setDaySeven({
      day: daySeven.day,
      evening: daySeven.evening,
    });
  };

  const dayOrNightBodyContent = isDay ? (
    <ModalAlertBody
      programType={Program.DAY}
      handleCloseModal={handleCloseModal}
      handleSet={handleSetDay}
      handleUnSet={handleUnSetDay}
      isDay={isDay}
    />
  ) : (
    <ModalAlertBody
      programType={Program.NIGHT}
      handleCloseModal={handleCloseModal}
      handleSet={handleSetEvening}
      handleUnSet={handleUnSetEvening}
      isDay={isDay}
    />
  );

  return (
    <div>
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        centered
        dialogClassName="modal-container-program"
      >
        {dayOrNightBodyContent}
      </Modal>
      {showSnack && !program.includes(Program.DAY) && (
        <SnackBarAlert
          variant={Variant.SUCCESS}
          text={SNACKBAR_DEACTIVATE_DAY}
        />
      )}
      {showSnack && program.includes(Program.DAY) && (
        <SnackBarAlert variant={Variant.SUCCESS} text={SNACKBAR_ACTIVATE_DAY} />
      )}
      {showSnackEvening && !program.includes(Program.NIGHT) && (
        <SnackBarAlert
          variant={Variant.SUCCESS}
          text={SNACKBAR_DEACTIVATE_NIGHT}
        />
      )}
      {showSnackEvening && program.includes(Program.NIGHT) && (
        <SnackBarAlert
          variant={Variant.SUCCESS}
          text={SNACKBAR_ACTIVATE_NIGHT}
        />
      )}
    </div>
  );
};

export default ModalAlert;
