import React, { useEffect, useState } from "react";
import "./activity-card.scss";
import { Detail } from "../../models/activity";
import "./activity-detail.scss";
import { ActivityType } from "../../enums/activity";
import smile_detail from "./../../assets/detail-icons/smile-detail.svg";
import mask_detail from "./../../assets/detail-icons/mask-detail.svg";
import summer_detail from "./../../assets/detail-icons/summer-detail.svg";
import hearth_detail from "./../../assets/detail-icons/hearth-detail.svg";
import sport_detail from "./../../assets/detail-icons/sport-detail.svg";
import brain from "./../../assets/detail-icons/brain.svg";
import drink from "./../../assets/detail-icons/drink.svg";
import ProgressiveImage from "react-progressive-graceful-image";
import pixi from "./../../assets/pixi-expanded.jpeg";
import { CLOSE } from "../../strings/string";
import { getImage, truncateString } from "../../utils";
import { Modal } from "react-bootstrap";

const ActivityDetail = (props: Detail) => {
  const [actualIcon, setActualIcon] = useState("");

  const handleCloseModal = () => {
    props.setShowModal(false);
  };

  useEffect(() => {
    switch (props.type) {
      case ActivityType.MINICLUB:
        setActualIcon(smile_detail);
        break;
      case ActivityType.THEMATIC:
        setActualIcon(mask_detail);
        break;
      case ActivityType.SUMMER:
        setActualIcon(summer_detail);
        break;
      case ActivityType.FITNESS:
        setActualIcon(hearth_detail);
        break;
      case ActivityType.SPORT:
        setActualIcon(sport_detail);
        break;
      case ActivityType.MINIDISCO:
        setActualIcon(smile_detail);
        break;
      case ActivityType.SHOWTIME:
        setActualIcon(brain);
        break;
      case ActivityType.LATE_NIGHT:
        setActualIcon(drink);
        break;
      default:
        setActualIcon(summer_detail);
        break;
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Modal
        show={props.showModal}
        onHide={handleCloseModal}
        centered
        dialogClassName="modal-container "
      >
        <ProgressiveImage src={getImage(props.img)} placeholder={pixi}>
          {(src: any, loading: any) => (
            <img
              style={{
                opacity: loading ? 0.7 : 1,
              }}
              src={src}
              alt="background-modal"
              className="image-container"
            />
          )}
        </ProgressiveImage>
        <div className="bottom-detail-container">
          <div className="bottom-left-container">
            <p className="detail-title">{truncateString(props.title, 30)}</p>
            <p className="detail-describtion">
              {truncateString(props.description, 250)}
            </p>
          </div>

          <div
            className="info-tab-detail"
            style={{
              backgroundColor: props.background,
            }}
          >
            <div className="info-sub-container">
              <img src={actualIcon} alt={props.title} />

              <p className="activity-kind">{props.type}</p>
            </div>
            <div className="close-container">
              <div onClick={handleCloseModal}>
                <div className="close-info-detail" />
                <p className="close-info-text">{CLOSE}</p>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ActivityDetail;
