import React from "react";
import { Button, Modal } from "react-bootstrap";
import { Collection } from "../../enums/collections";
import { db } from "../../firebase/firebase-init";

interface Props {
  show: boolean;
  handleClose: () => void;
  orderId?: string;
}

const ModalRemoveOrder = ({ show, handleClose, orderId }: Props) => {
  const remove = () => {
    db.collection(Collection.ORDERS)
      .doc(orderId)
      .delete()
      .then(() => handleClose());
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className="dashboard-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Odstránenie objednávky</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Chystáte sa odstrániť objednávku č. <b>{orderId}</b>. Ste si istý, že ju
        chcete odstrániť?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Zatvoriť
        </Button>
        <Button variant="primary" onClick={remove}>
          Zmazať
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalRemoveOrder;
