import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import { Collection } from "../../../enums/collections";
import { db } from "../../../firebase/firebase-init";
import { IFirebaseOrder } from "../../../models/firebase-order";
import { DashboardRoutes, Routes } from "../../../enums/routes";
import "./order-detail.scss";
import {
  ANIMATOR_COUNT,
  BACK_TO_ORDERS,
  CONFIG,
  DATE_OF_BEGINNING,
  DAY_COUNT,
  DEFAULT_INFORMATIONS,
  EMAIL,
  NAME,
  PHONE_NUMBER,
} from "../../../strings/string";

interface OrderParams {
  orderId: string;
}

const OrderDetail = () => {
  const [order, setOrder] = useState<IFirebaseOrder>();

  let history = useHistory();

  let { orderId } = useParams<OrderParams>();

  useEffect(() => {
    db.collection(Collection.ORDERS)
      .doc(orderId)
      .onSnapshot((order) =>
        setOrder({ ...order.data(), id: order.id } as IFirebaseOrder)
      );
    // eslint-disable-next-line
  }, []);

  const navigate = () => {
    history.push(`${Routes.DASHBOARD}/${DashboardRoutes.ORDERS}`);
  };

  return (
    <div className="order-detail-container">
      <Button variant="contained" onClick={navigate}>
        {BACK_TO_ORDERS}
      </Button>

      <div className="oder-detail-header">
        <h1>Objednávka č. {order?.id}</h1>
      </div>

      <div className="order-detail-config">
        <Row>
          <Col md="6">
            <h3>{DEFAULT_INFORMATIONS}</h3>
            <p>
              <b>{NAME}</b>{" "}
              {order?.costumer.name + " " + order?.costumer.surname}
            </p>
            <p>
              <b>{EMAIL}</b> {order?.costumer.mail}
            </p>
            <p>
              <b>{PHONE_NUMBER}</b> {order?.costumer.phone}
            </p>
          </Col>

          <Col md="6">
            <h3>{CONFIG}</h3>
            <p>
              <b>{ANIMATOR_COUNT}</b> {order?.config.animators}
            </p>
            <p>
              <b>{DATE_OF_BEGINNING}</b>{" "}
              {order?.config.date.toDate().toLocaleDateString("sk")}
            </p>
            <p>
              <b>{DAY_COUNT}</b> {order?.config.days}
            </p>
            <Link to={`/pdf/${order?.id}`} target="_blank">
              <Button variant="contained">PDF na stiahnutie</Button>
            </Link>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default OrderDetail;
