import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { Program } from "../../enums/activity";
import { IDayMap } from "../../models/day-map";
import { getColor, truncateString } from "../../utils";

type Props = {
  activities: Map<string, IDayMap>;
  handleDragEnd: (result: DropResult) => void;
  type?: Program | string;
  handleRemoveCard?: (card: IDayMap) => void;
};

const DragAndDropTimes = ({
  activities,
  handleDragEnd,
  type,
  handleRemoveCard,
}: Props) => {
  const timelineTimes = Array.from(activities.keys()).map((key) => (
    <p className={type === "day" ? "time-line" : "time-line-evening"} key={key}>
      {key}
    </p>
  ));

  const timelineActivities = Array.from(activities.values()).map(
    (value, index) => (
      <Draggable draggableId={index.toString()} index={index} key={index}>
        {(provided) => (
          <div
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
          >
            <div
              className="activities-day"
              style={
                value.type && {
                  backgroundColor: getColor(value.type),
                }
              }
              key={value.index}
            >
              {truncateString(value.title, 22)}
              {value.title.length > 0 && handleRemoveCard && (
                <p
                  className="delete-activity"
                  onClick={() => handleRemoveCard(value)}
                >
                  <FontAwesomeIcon icon={faTimes} color="white" size="1x" />
                </p>
              )}
            </div>
          </div>
        )}
      </Draggable>
    )
  );

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="activities-map-container">
        {(provided) => (
          <div>
            <div {...provided.droppableProps} ref={provided.innerRef}>
              <div className="key-value">
                <div>{timelineTimes}</div>
                <div className="activities-map-container">
                  {timelineActivities}
                </div>
              </div>
              {provided.placeholder}
            </div>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DragAndDropTimes;
