import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { Button, FormLabel, Modal } from "react-bootstrap";
import { Collection } from "../../enums/collections";
import { db } from "../../firebase/firebase-init";
import { IFirebaseActivity } from "../../models/firebase-activity";
import {
  CATEGORIY,
  DESCRIPTION,
  EDIT_ACTIVITY,
  LONG_DESCRIPTION,
  PROGRAM_TITLE,
  TITLE,
} from "../../strings/string";
import { FirebaseCategory } from "./add-activity";

interface Props {
  show: boolean;
  handleClose: () => void;
  activity: IFirebaseActivity;
}

const ModalEditActivity = ({ show, handleClose, activity }: Props) => {
  const [categories, setCategories] = useState<FirebaseCategory[]>([]);
  const [selectedCategory, setSelectedCategory] = useState(activity.type);
  const [title, setTitle] = useState(activity.title);
  const [description, setDescription] = useState(activity.description);
  const [longDescription, setlongDescription] = useState(
    activity.detail.description
  );
  const [program, setProgram] = useState(activity.program);

  const handleChange = (event: SelectChangeEvent<string>) => {
    setSelectedCategory(event.target.value);
  };

  const handleChangeTitle = (event: ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const handleChangeDescription = (event: ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value);
  };

  const handleChangeLongDescription = (
    event: ChangeEvent<HTMLTextAreaElement>
  ) => {
    setlongDescription(event.target.value);
  };

  const handleChangeProgram = (event: ChangeEvent<HTMLInputElement>) => {
    setProgram(event.target.value);
  };

  const prevent = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };

  useEffect(() => {
    db.collection(Collection.CATEGORIES).onSnapshot((categories) =>
      categories.forEach((category) => {
        setCategories((prevstate) => [
          ...prevstate,
          category.data() as FirebaseCategory,
        ]);
      })
    );
  }, []);

  const menuItems = categories
    .filter((category) => category.program === program)
    .map((category, id) => (
      <MenuItem key={id} value={category.title}>
        {category.title}
      </MenuItem>
    ));

  const edit = () => {
    db.collection(Collection.ACTIVITIES)
      .doc(activity.id)
      .update({
        title: title,
        description: description,
        program: program,
        type: selectedCategory,
        detail: {
          description: longDescription,
          img: activity.detail.img,
        },
      })
      .then(() => handleClose());
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className="dashboard-modal-edit-activity dashboard-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>{EDIT_ACTIVITY}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={prevent}>
          <FormControl fullWidth>
            <InputLabel>{CATEGORIY}</InputLabel>

            <Select
              value={selectedCategory}
              label={CATEGORIY}
              onChange={handleChange}
            >
              {menuItems}
            </Select>
          </FormControl>

          <TextField
            fullWidth
            label={TITLE}
            variant="outlined"
            value={title}
            onChange={handleChangeTitle}
          />

          <TextField
            fullWidth
            label={DESCRIPTION}
            variant="outlined"
            value={description}
            onChange={handleChangeDescription}
          />
          <TextareaAutosize
            placeholder={LONG_DESCRIPTION}
            minRows={3}
            style={{ width: "100%" }}
            value={longDescription}
            onChange={handleChangeLongDescription}
          />

          <FormControl>
            <FormLabel>{PROGRAM_TITLE}</FormLabel>
            <RadioGroup row value={program} onChange={handleChangeProgram}>
              <FormControlLabel value="day" control={<Radio />} label="Denný" />
              <FormControlLabel
                value="night"
                control={<Radio />}
                label="Večerný"
              />
            </RadioGroup>
          </FormControl>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Zatvoriť
        </Button>
        <Button variant="primary" onClick={edit}>
          Upraviť
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalEditActivity;
