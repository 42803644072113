export enum ActivityColorDay {
  MINICLUB = "#F87851",
  SPORT = "#EDBF32",
  THEMTIC = "#6762EF",
  FITNESS = "#D3488E",
  SUMMER = "#188DB1",
  WINTER = "#007ba8",
  EASTER = "#00b315",
  HALLOWEEN = "#db7c00",
  SANTA = "#ba0000",
  CHRISTMAS = "#6000ba",
  PAW_PATROL = "#a100ba",
  WALT_DISNEY = "#ba007f",
  INDIANS = "#ff9100",
  PIRATS = "#000000",
}

export enum ActivityColorEvening {
  MINIDISCO = "#D3488E",
  SHOWTIME = "#902FC4",
  LATENIGHT = "#512FC4",
}

export enum Collection {
  ACTIVITIES = "activities",
  HEADER = "header",
  TAGS = "tags",
  CATEGORIES = "categories",
  ORDERS = "orders",
  ADMINS = "admins",
  COMBINATIONS = "combinations",
}

export enum Documents {
  ONE_ANIMATOR = "oneAnimator",
  TWO_ANIMATORS = "twoAnimators",
  THREE_ANIMATORS = "threeAnimators",
  ONE_ANIMATOR_WEEKEND = "oneAnimatorWeekend",
  TWO_ANIMATORS_WEEKEND = "twoAnimatorsWeekend",
  THREE_ANIMATORS_WEEKEND = "threeAnimatorsWeekend",
}
