import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { IModalBack } from "../../models/modal-back";
import "./modal-back.scss";

const ModalBack = (props: IModalBack) => {
  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
    props.setIsOpenModal(false);
  };

  useEffect(() => {
    props.openModal === true && setShowModal(true);
  }, [props.openModal]);

  return (
    <div>
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        centered
        dialogClassName="modal-container-program"
      >
        <div className="container-modal-program">
          <div>
            <p className="title-modal-program">{props.title}</p>

            <div className="modal-program-info">{props.text}</div>
            <div className="modal-program-buttons">
              <p onClick={handleCloseModal} className="button-cancel-select">
                {props.answearLeft}
              </p>
              <Link to={props.link}>
                <p className="button-deactivate">{props.answearRight}</p>
              </Link>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ModalBack;
