import React from "react";
import { CongratsIcon } from "../../assets/congrats-icon";
import { DONE, PDF_DONE, DONE_TEXT, BACK_TO_START } from "../../strings/string";
import { Button } from "react-bootstrap";

import "./success-page.scss";
import { useHistory } from "react-router-dom";
import PdfSuccess from "./pdf-success";

const SuccessPage = () => {
  const history = useHistory();

  const navigate = () => {
    history.push("/");
    sessionStorage.clear();
    window.location.reload();
  };

  return (
    <div className="s4-container">
      <div className="s4-program-done">
        <div className="s4-congratulation-icon-container">
          <CongratsIcon />
        </div>
        <p className="s4-congratulation">{DONE}</p>
        <p className="s4-your-program">{PDF_DONE}</p>
      </div>
      <p className="s4-info-program">{DONE_TEXT}</p>
      <div className="s4-button-accept-container">
        <Button variant="none" className="s4-button-accept" onClick={navigate}>
          <p>{BACK_TO_START}</p>
        </Button>
      </div>
      <div className="s4-pdf-container">
        <PdfSuccess />
      </div>
    </div>
  );
};

export default SuccessPage;
