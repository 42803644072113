export const LockEvening = () => {
	return (
		<div>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="36.92"
				height="51.047"
				viewBox="0 0 36.92 51.047"
			>
				<g id="lcok" transform="translate(-80.994)">
					<g id="Group_208" data-name="Group 208" transform="translate(80.994)">
						<path
							id="Path_51"
							data-name="Path 51"
							d="M99.454,0A14.945,14.945,0,0,0,84.526,14.928v8.526a.4.4,0,0,1-.213.291c-.807.269-1.463.5-2.063.734a2.065,2.065,0,0,0-1.256,1.845v19.59a2.08,2.08,0,0,0,1.247,1.846,46.727,46.727,0,0,0,34.426,0,2.079,2.079,0,0,0,1.247-1.846V26.324a2.066,2.066,0,0,0-1.256-1.845c-.6-.231-1.256-.465-2.063-.734a.4.4,0,0,1-.213-.291V14.928A14.945,14.945,0,0,0,99.454,0ZM95.388,32.291a4.066,4.066,0,0,1,8.131,0,4,4,0,0,1-2.033,3.442v6.722a2.033,2.033,0,1,1-4.066,0V35.733A4.005,4.005,0,0,1,95.388,32.291Zm13.861-17.363v7.356a46.6,46.6,0,0,0-19.59,0V14.928a9.8,9.8,0,0,1,19.59,0Z"
							transform="translate(-80.994)"
							fill="#f8f5ff"
						/>
					</g>
				</g>
			</svg>
		</div>
	);
};
