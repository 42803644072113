import React, { useContext } from "react";
import { HowManyDays } from "../../strings/string";
import "./activities-summary.scss";
import { UserContext } from "../../context-api/context";
import SectionSummary from "./section-summary";

const ActivitiesSummary = () => {
  const { countDays, schedule } = useContext(UserContext);

  const multipleWeekScheduleMap = schedule.map(
    (scheduleWeek, scheduleWeekIndex) => (
      <div className="container-screen-three" key={scheduleWeekIndex}>
        {HowManyDays.map(
          (item, index) =>
            index < countDays && (
              <div
                key={item.day}
                className="section-summary-day-evening"
                style={{ minWidth: countDays === 4 ? "40%" : 330 }}
              >
                <SectionSummary
                  day={item.day}
                  numberDay={item.numberDay}
                  weekIndex={scheduleWeekIndex}
                />
              </div>
            )
        )}
      </div>
    )
  );

  const oneWeekMap = HowManyDays.map(
    (item, index) =>
      index < countDays && (
        <div
          key={item.day}
          className="section-summary-day-evening"
          style={{ minWidth: countDays === 4 ? "40%" : 330 }}
        >
          <SectionSummary day={item.day} numberDay={item.numberDay} />
        </div>
      )
  );

  const scheduleMap =
    schedule.length > 0 ? (
      multipleWeekScheduleMap
    ) : (
      <div className="container-screen-three">{oneWeekMap}</div>
    );

  return <>{scheduleMap}</>;
};

export default ActivitiesSummary;
