import { Button } from "@mui/material";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { logIn, logOut } from "../auth";
import { UserContext } from "../context-api/context";
import { Routes } from "../enums/routes";
import { LOGOUT } from "../strings/string";
import "./auth-component.scss";
import Login from "./../assets/LOGIN.svg";

const AuthComponent = () => {
  const { user } = useContext(UserContext);

  const userContent = user ? (
    <div className="auth-component-user">
      <p className="user-name">{user.name}</p>
      <img src={user.iconUri} alt="user" />

      {user.isAdmin && (
        <Link to={Routes.DASHBOARD}>
          <Button variant="contained">Dashboard</Button>
        </Link>
      )}

      <Button variant="contained" onClick={logOut}>
        {LOGOUT}
      </Button>
    </div>
  ) : (
    <div>
      <img src={Login} alt="login" onClick={logIn} className="login-image" />
    </div>
  );

  return <div className="auth-component">{userContent}</div>;
};

export default AuthComponent;
