import React, { useContext } from "react";
import { UserContext } from "../../context-api/context";
import { Program } from "../../enums/activity";
import {
  ACTIVATE_DAY_PROGRAM,
  ACTIVATE_NIGHT_PROGRAM,
  DAY_MODAL_OFF,
  DAY_MODAL_ON,
  DEACTIVATE_DAY_PROGRAM,
  DEACTIVATE_NIGHT_PROGRAM,
  DEACTIVATE_PROGRAM,
  KEEP_PROGRAM,
  NIGHT_MODAL_OFF,
  NIGHT_MODAL_ON,
  NO_THANKS,
  YES_UNLOCK,
} from "../../strings/string";

type Props = {
  programType: Program;
  handleCloseModal: () => void;
  handleSet: () => void;
  handleUnSet: () => void;
  isDay: boolean;
};

const ModalAlertBody = ({
  programType,
  handleCloseModal,
  handleSet,
  handleUnSet,
  isDay,
}: Props) => {
  const { program } = useContext(UserContext);

  const isProgramTypeIncluded = program.includes(programType);

  const activateOrDeactivate = isProgramTypeIncluded ? (
    <p className="title-modal-program">
      {isDay ? DEACTIVATE_DAY_PROGRAM : DEACTIVATE_NIGHT_PROGRAM}
    </p>
  ) : (
    <p className="title-modal-program">
      {isDay ? ACTIVATE_DAY_PROGRAM : ACTIVATE_NIGHT_PROGRAM}
    </p>
  );

  const programOnOrOff = isProgramTypeIncluded ? (
    <p>{isDay ? DAY_MODAL_OFF : NIGHT_MODAL_OFF}</p>
  ) : (
    <p>{isDay ? DAY_MODAL_ON : NIGHT_MODAL_ON}</p>
  );

  const keepProgram = isProgramTypeIncluded ? KEEP_PROGRAM : NO_THANKS;

  const setOrUnset = isProgramTypeIncluded ? handleUnSet : handleSet;

  const deactivateOrUnlock = isProgramTypeIncluded
    ? DEACTIVATE_PROGRAM
    : YES_UNLOCK;

  return (
    <div className="container-modal-program">
      <div>
        {activateOrDeactivate}
        <div className="modal-program-info">{programOnOrOff}</div>
        <div className="modal-program-buttons">
          <p onClick={handleCloseModal} className="button-cancel-select">
            {keepProgram}
          </p>
          <p onClick={setOrUnset} className="button-deactivate">
            {deactivateOrUnlock}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ModalAlertBody;
