import React from "react";
import { Schedule } from "../../models/firebase-order";
import { IEveningTimes } from "./pdf";
import ScheduleDayColumn from "./schedule-day-column";

interface Props {
  schedule?: Schedule;
  times: string[];
  eveningTimes?: IEveningTimes;
}

const ActiveDays = ({ schedule, times, eveningTimes }: Props) => {
  return (
    <>
      {schedule?.dayOne && (
        <ScheduleDayColumn
          times={times}
          day={schedule?.dayOne}
          eveningTimes={eveningTimes}
        />
      )}
      {schedule?.dayTwo && (
        <ScheduleDayColumn
          times={times}
          day={schedule?.dayTwo}
          eveningTimes={eveningTimes}
        />
      )}
      {schedule?.dayThree && (
        <ScheduleDayColumn
          times={times}
          day={schedule?.dayThree}
          eveningTimes={eveningTimes}
        />
      )}
      {schedule?.dayFour && (
        <ScheduleDayColumn
          times={times}
          day={schedule?.dayFour}
          eveningTimes={eveningTimes}
        />
      )}
      {schedule?.dayFive && (
        <ScheduleDayColumn
          times={times}
          day={schedule?.dayFive}
          eveningTimes={eveningTimes}
        />
      )}
      {schedule?.daySix && (
        <ScheduleDayColumn
          times={times}
          day={schedule?.daySix}
          eveningTimes={eveningTimes}
        />
      )}
      {schedule?.daySeven && (
        <ScheduleDayColumn
          times={times}
          day={schedule?.daySeven}
          eveningTimes={eveningTimes}
        />
      )}
    </>
  );
};

export default ActiveDays;
