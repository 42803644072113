import React, { useContext, useEffect, useRef, useState } from "react";
import "./count-animators.scss";
import { UserContext } from "../../context-api/context";
import {
  ANIMATOR_TITLE,
  SNACKBAR_OFFER_ACTUALIZED,
} from "../../strings/string";
import SnackBarAlert from "../S2-select-activities/snack-bar";
import { Variant } from "../../enums/snackbar";
import { db } from "../../firebase/firebase-init";
import { Collection } from "../../enums/collections";
import Shimmer from "react-shimmer-effect";
import UseDays from "../../custom-hooks/use-days";
import { isSessionStorageDayEmpty } from "../../utils";

const CountAnimators = () => {
  const { setEmptyTimeline } = UseDays();
  const { setCounter, counter } = useContext(UserContext);
  const [animatorCountOptions, setAnimatorsCountOption] = useState<number[]>(
    []
  );
  const [isLoaded, setIsloaded] = useState(false);

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    fetchAnimators();
  }, []);

  const handleChangeCountOne = (value: number) => {
    setCounter(value);
    setEmptyTimeline(value);
    if (counter > 0) {
      setIsVisible(true);
      setTimeout(() => {
        setIsVisible(false);
      }, 3000);
    }
  };

  const didMount = useRef(false);

  const whenSessionStorageIsEmptySetTimeline = () => {
    const _dayOne = JSON.parse(sessionStorage.getItem("dayOne")!);
    const _dayTwo = JSON.parse(sessionStorage.getItem("dayTwo")!);
    const _dayThree = JSON.parse(sessionStorage.getItem("dayThree")!);
    const _dayFour = JSON.parse(sessionStorage.getItem("dayFour")!);
    const _dayFive = JSON.parse(sessionStorage.getItem("dayFive")!);
    const _daySix = JSON.parse(sessionStorage.getItem("daySix")!);
    const _daySeven = JSON.parse(sessionStorage.getItem("daySeven")!);

    isSessionStorageDayEmpty(_dayOne) &&
      isSessionStorageDayEmpty(_dayTwo) &&
      isSessionStorageDayEmpty(_dayThree) &&
      isSessionStorageDayEmpty(_dayFour) &&
      isSessionStorageDayEmpty(_dayFive) &&
      isSessionStorageDayEmpty(_daySix) &&
      isSessionStorageDayEmpty(_daySeven) &&
      setEmptyTimeline(1);
  };

  useEffect(() => {
    didMount.current
      ? whenSessionStorageIsEmptySetTimeline()
      : (didMount.current = true);

    // eslint-disable-next-line
  }, [animatorCountOptions]);

  const fetchAnimators = async () => {
    await db
      .collection(Collection.HEADER)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          if (doc.exists) {
            setAnimatorsCountOption(doc.data().animators);
          }
        });
      });
    setIsloaded(true);
  };

  const animators = [1, 2, 3];

  const shimmerMap = animators.map((index) => (
    <Shimmer key={index}>
      <p className="number-animators" />
    </Shimmer>
  ));

  const animatorCountMap = animatorCountOptions.map((option) => (
    <div
      onClick={() => counter !== option && handleChangeCountOne(option)}
      key={option}
    >
      <p
        className={
          counter === option ? "number-animators-active" : "number-animators"
        }
      >
        {option}
      </p>
    </div>
  ));

  const showSnackBarAlert = isVisible && (
    <SnackBarAlert variant={Variant.SUCCESS} text={SNACKBAR_OFFER_ACTUALIZED} />
  );

  const isAnimatorCountLoaded = isLoaded ? (
    <div className="animator-count">{animatorCountMap}</div>
  ) : (
    <div className="animator-count">{shimmerMap}</div>
  );

  return (
    <div>
      {showSnackBarAlert}
      <div className="count-animator-container">
        <p className="count-title">{ANIMATOR_TITLE}</p>
        <div>{isAnimatorCountLoaded}</div>
      </div>
    </div>
  );
};

export default CountAnimators;
