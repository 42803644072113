import { useContext, useEffect, useState } from "react";
import { UserContext } from "../context-api/context";
import { Days } from "../enums/activity";
import { Collection, Documents } from "../enums/collections";
import { db } from "../firebase/firebase-init";
import { ICombinations, IProgram } from "../models/program";
import { createWeekendTimeline } from "../utils";

type ReturnType = {
  setEmptyTimeline: (count: number) => void;
  fetchActualDay: (
    data: IProgram,
    numberDay: string | undefined,
    weekIndex: number | undefined,
    withSchedule: boolean
  ) => void;
  isLoaded: boolean;
};

const UseDays = (): ReturnType => {
  const {
    dayOne,
    dayTwo,
    dayThree,
    dayFour,
    dayFive,
    daySix,
    daySeven,
    setDayOne,
    setDayTwo,
    setDayThree,
    setDayFour,
    setDayFive,
    setDaySix,
    setDaySeven,
    dateStart,
    schedule,
    setSchedule,
  } = useContext(UserContext);

  const [oneAnimator, setOneAnimator] = useState<ICombinations | null>(null);
  const [twoAnimators, setTwoAnimators] = useState<ICombinations | null>(null);
  const [threeAnimators, setThreeAnimators] = useState<ICombinations | null>(
    null
  );
  const [oneAnimatorWeekend, setOneAnimatorWeekend] =
    useState<ICombinations | null>(null);
  const [twoAnimatorsWeekend, setTwoAnimatorsWeekend] =
    useState<ICombinations | null>(null);
  const [threeAnimatorsWeekend, setThreeAnimatorsWeekend] =
    useState<ICombinations | null>(null);

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    db.collection(Collection.COMBINATIONS)
      .doc(Documents.ONE_ANIMATOR)
      .get()
      .then((combination) => {
        setOneAnimator(combination.data() as ICombinations);
      });

    db.collection(Collection.COMBINATIONS)
      .doc(Documents.TWO_ANIMATORS)
      .get()
      .then((combination) => {
        setTwoAnimators(combination.data() as ICombinations);
      });

    db.collection(Collection.COMBINATIONS)
      .doc(Documents.THREE_ANIMATORS)
      .get()
      .then((combination) => {
        setThreeAnimators(combination.data() as ICombinations);
      });
    db.collection(Collection.COMBINATIONS)
      .doc(Documents.ONE_ANIMATOR_WEEKEND)
      .get()
      .then((combination) => {
        setOneAnimatorWeekend(combination.data() as ICombinations);
      });

    db.collection(Collection.COMBINATIONS)
      .doc(Documents.TWO_ANIMATORS_WEEKEND)
      .get()
      .then((combination) => {
        setTwoAnimatorsWeekend(combination.data() as ICombinations);
      });

    db.collection(Collection.COMBINATIONS)
      .doc(Documents.THREE_ANIMATORS_WEEKEND)
      .get()
      .then((combination) => {
        setThreeAnimatorsWeekend(combination.data() as ICombinations);
      });
  }, []);

  useEffect(() => {
    oneAnimator &&
      oneAnimatorWeekend &&
      twoAnimators &&
      twoAnimatorsWeekend &&
      threeAnimators &&
      threeAnimatorsWeekend &&
      setIsLoaded(true);
  }, [
    oneAnimator,
    oneAnimatorWeekend,
    twoAnimators,
    twoAnimatorsWeekend,
    threeAnimators,
    threeAnimatorsWeekend,
  ]);

  const fetchActualDay = (
    data: IProgram,
    numberDay: string | undefined,
    weekIndex?: number | undefined,
    withSchedule = false
  ) => {
    let copy = schedule;
    switch (numberDay) {
      case Days.FIRST:
        if (schedule.length === 0 || !withSchedule) {
          setDayOne(data);
        } else {
          copy[weekIndex!][0] = data;
          setSchedule(copy);
        }
        break;

      case Days.SECOND:
        if (schedule.length === 0 || !withSchedule) {
          setDayTwo(data);
        } else {
          copy[weekIndex!][1] = data;
          setSchedule(copy);
        }
        break;

      case Days.THIRD:
        if (schedule.length === 0 || !withSchedule) {
          setDayThree(data);
        } else {
          copy[weekIndex!][2] = data;
          setSchedule(copy);
        }
        break;

      case Days.FOURTH:
        if (schedule.length === 0 || !withSchedule) {
          setDayFour(data);
        } else {
          copy[weekIndex!][3] = data;
          setSchedule(copy);
        }
        break;

      case Days.FIFTH:
        if (schedule.length === 0 || !withSchedule) {
          setDayFive(data);
        } else {
          copy[weekIndex!][4] = data;
          setSchedule(copy);
        }
        break;

      case Days.SIXTH:
        if (schedule.length === 0 || !withSchedule) {
          setDaySix(data);
        } else {
          copy[weekIndex!][5] = data;
          setSchedule(copy);
        }
        break;

      case Days.SEVENTH:
        if (schedule.length === 0 || !withSchedule) {
          setDaySeven(data);
        } else {
          copy[weekIndex!][6] = data;
          setSchedule(copy);
        }
        break;
    }
  };

  const setEmptyTimeline = (count: number) => {
    dayOne.day.clear();
    dayTwo.day.clear();
    dayThree.day.clear();
    dayFour.day.clear();
    dayFive.day.clear();
    daySix.day.clear();
    daySeven.day.clear();

    dayOne.evening.minidisco.clear();
    dayTwo.evening.minidisco.clear();
    dayThree.evening.minidisco.clear();
    dayFour.evening.minidisco.clear();
    dayFive.evening.minidisco.clear();
    daySix.evening.minidisco.clear();
    daySeven.evening.minidisco.clear();

    dayOne.evening.showTime.clear();
    dayTwo.evening.showTime.clear();
    dayThree.evening.showTime.clear();
    dayFour.evening.showTime.clear();
    dayFive.evening.showTime.clear();
    daySix.evening.showTime.clear();
    daySeven.evening.showTime.clear();

    dayOne.evening.lateNight.clear();
    dayTwo.evening.lateNight.clear();
    dayThree.evening.lateNight.clear();
    dayFour.evening.lateNight.clear();
    dayFive.evening.lateNight.clear();
    daySix.evening.lateNight.clear();
    daySeven.evening.lateNight.clear();

    let selectedAnimatorCount = oneAnimator;
    let selectedAnimatorCountWeekend = oneAnimatorWeekend;
    if (count === 1) {
      selectedAnimatorCount = oneAnimator;
      selectedAnimatorCountWeekend = oneAnimatorWeekend;
    }
    if (count === 2) {
      selectedAnimatorCount = twoAnimators;
      selectedAnimatorCountWeekend = twoAnimatorsWeekend;
    }
    if (count === 3) {
      selectedAnimatorCount = threeAnimators;
      selectedAnimatorCountWeekend = threeAnimatorsWeekend;
    }

    selectedAnimatorCount?.times.forEach((time) => {
      dayOne.day.set(time, { title: "" });
      dayTwo.day.set(time, { title: "" });
      dayThree.day.set(time, { title: "" });
      dayFour.day.set(time, { title: "" });
      dayFive.day.set(time, { title: "" });
      daySix.day.set(time, { title: "" });
      daySeven.day.set(time, { title: "" });
    });

    selectedAnimatorCount?.minidisko.forEach((time) => {
      dayOne.evening.minidisco.set(time, { title: "" });
      dayTwo.evening.minidisco.set(time, { title: "" });
      dayThree.evening.minidisco.set(time, { title: "" });
      dayFour.evening.minidisco.set(time, { title: "" });
      dayFive.evening.minidisco.set(time, { title: "" });
      daySix.evening.minidisco.set(time, { title: "" });
      daySeven.evening.minidisco.set(time, { title: "" });
    });

    selectedAnimatorCount?.showtime.forEach((time) => {
      dayOne.evening.showTime.set(time, { title: "" });
      dayTwo.evening.showTime.set(time, { title: "" });
      dayThree.evening.showTime.set(time, { title: "" });
      dayFour.evening.showTime.set(time, { title: "" });
      dayFive.evening.showTime.set(time, { title: "" });
      daySix.evening.showTime.set(time, { title: "" });
      daySeven.evening.showTime.set(time, { title: "" });
    });

    selectedAnimatorCount?.lateNight.forEach((time) => {
      dayOne.evening.lateNight.set(time, { title: "" });
      dayTwo.evening.lateNight.set(time, { title: "" });
      dayThree.evening.lateNight.set(time, { title: "" });
      dayFour.evening.lateNight.set(time, { title: "" });
      dayFive.evening.lateNight.set(time, { title: "" });
      daySix.evening.lateNight.set(time, { title: "" });
      daySeven.evening.lateNight.set(time, { title: "" });
    });

    const dayOneDate = new Date(dateStart);
    if (dayOneDate.getDay() === 6 || dayOneDate.getDay() === 0) {
      setDayOne(createWeekendTimeline(selectedAnimatorCountWeekend));
    } else {
      setDayOne({
        day: dayOne.day,
        evening: {
          minidisco: dayOne.evening.minidisco,
          showTime: dayOne.evening.showTime,
          lateNight: dayOne.evening.lateNight,
        },
      });
    }

    const dayTwoDate = new Date(dateStart);
    dayTwoDate.setDate(dayTwoDate.getDate() + 1);
    if (dayTwoDate.getDay() === 6 || dayTwoDate.getDay() === 0) {
      setDayTwo(createWeekendTimeline(selectedAnimatorCountWeekend));
    } else {
      setDayTwo({
        day: dayTwo.day,
        evening: {
          minidisco: dayTwo.evening.minidisco,
          showTime: dayTwo.evening.showTime,
          lateNight: dayTwo.evening.lateNight,
        },
      });
    }

    const dayThreeDate = new Date(dateStart);
    dayThreeDate.setDate(dayThreeDate.getDate() + 2);
    if (dayThreeDate.getDay() === 6 || dayThreeDate.getDay() === 0) {
      setDayThree(createWeekendTimeline(selectedAnimatorCountWeekend));
    } else {
      setDayThree({
        day: dayThree.day,
        evening: {
          minidisco: dayThree.evening.minidisco,
          showTime: dayThree.evening.showTime,
          lateNight: dayThree.evening.lateNight,
        },
      });
    }

    const dayFourDate = new Date(dateStart);
    dayFourDate.setDate(dayFourDate.getDate() + 3);
    if (dayFourDate.getDay() === 6 || dayFourDate.getDay() === 0) {
      setDayFour(createWeekendTimeline(selectedAnimatorCountWeekend));
    } else {
      setDayFour({
        day: dayFour.day,
        evening: {
          minidisco: dayFour.evening.minidisco,
          showTime: dayFour.evening.showTime,
          lateNight: dayFour.evening.lateNight,
        },
      });
    }

    const dayFiveDate = new Date(dateStart);
    dayFiveDate.setDate(dayFiveDate.getDate() + 4);
    if (dayFiveDate.getDay() === 6 || dayFiveDate.getDay() === 0) {
      setDayFive(createWeekendTimeline(selectedAnimatorCountWeekend));
    } else {
      setDayFive({
        day: dayFive.day,
        evening: {
          minidisco: dayFive.evening.minidisco,
          showTime: dayFive.evening.showTime,
          lateNight: dayFive.evening.lateNight,
        },
      });
    }

    const daySixDate = new Date(dateStart);
    daySixDate.setDate(daySixDate.getDate() + 5);
    if (daySixDate.getDay() === 6 || daySixDate.getDay() === 0) {
      setDaySix(createWeekendTimeline(selectedAnimatorCountWeekend));
    } else {
      setDaySix({
        day: daySix.day,
        evening: {
          minidisco: daySix.evening.minidisco,
          showTime: daySix.evening.showTime,
          lateNight: daySix.evening.lateNight,
        },
      });
    }

    const daySevenDate = new Date(dateStart);
    daySevenDate.setDate(daySevenDate.getDate() + 6);
    if (daySevenDate.getDay() === 6 || daySevenDate.getDay() === 0) {
      setDaySeven(createWeekendTimeline(selectedAnimatorCountWeekend));
    } else {
      setDaySeven({
        day: daySeven.day,
        evening: {
          minidisco: daySeven.evening.minidisco,
          showTime: daySeven.evening.showTime,
          lateNight: daySeven.evening.lateNight,
        },
      });
    }
  };
  return { setEmptyTimeline, fetchActualDay, isLoaded } as const;
};

export default UseDays;
