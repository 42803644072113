import React from "react";
import { IFirebaseOrder } from "../../models/firebase-order";
import { addDays } from "../../utils";
import "./pdf-header.scss";

interface Props {
  order?: IFirebaseOrder;
}

const PdfHeader = ({ order }: Props) => {
  return (
    <div className="pdf-header">
      <div className="pdf-header-box">čas/deň</div>
      {order?.schedule[0].dayOne && (
        <div className="pdf-header-box">
          {addDays(0, order?.config.date.toDate())}
        </div>
      )}
      {order?.schedule[0].dayTwo && (
        <div className="pdf-header-box">
          {addDays(1, order?.config.date.toDate())}
        </div>
      )}
      {order?.schedule[0].dayThree && (
        <div className="pdf-header-box">
          {addDays(2, order?.config.date.toDate())}
        </div>
      )}
      {order?.schedule[0].dayFour && (
        <div className="pdf-header-box">
          {addDays(3, order?.config.date.toDate())}
        </div>
      )}
      {order?.schedule[0].dayFive && (
        <div className="pdf-header-box">
          {addDays(4, order?.config.date.toDate())}
        </div>
      )}
      {order?.schedule[0].daySix && (
        <div className="pdf-header-box">
          {addDays(5, order?.config.date.toDate())}
        </div>
      )}
      {order?.schedule[0].daySeven && (
        <div className="pdf-header-box">
          {addDays(6, order?.config.date.toDate())}
        </div>
      )}
    </div>
  );
};

export default PdfHeader;
