export enum DashboardRoutes {
  ADMINISTRATORS = "administratori",
  ORDERS = "objednavky",
  USERS = "pouzivatelia",
  ACTIVITIES = "aktivity",
  COMBINATIONS = "kombinacie",
}

export enum Routes {
  HOME = "/",
  ACTIVITIES = "/activities",
  SEND_ORDER = "/send-order",
  SUCCESS = "/success",
  DASHBOARD = "/dashboard",
  ONLY_PDF = "/pdf",
  PDF = "/pdf/:orderId",
  ERROR = "*",
}
