import React, { useEffect, useState } from "react";
import "./tags.scss";
import { ITags } from "../../models/tags";
import smile from "./../../assets/smile.svg";
import mask from "./../../assets/mask.svg";
import sun from "./../../assets/sun.svg";
import hearth from "./../../assets/hearth.svg";
import sport from "./../../assets/sport.svg";
import smileEvening from "./../../assets/smileEvening.svg";
import brain from "./../../assets/brain.svg";
import brain_evening from "./../../assets/brainEveningTag.svg";
import drink from "./../../assets/drink.svg";
import drink_evening from "./../../assets/drink-evening.svg";
import { CategoryIcons } from "../../enums/activity";

const Tags = (props: ITags) => {
  const [actualIcon, setActualIcon] = useState("");
  useEffect(() => {
    switch (props.icon) {
      case CategoryIcons.SMILE:
        setActualIcon(smile);
        break;
      case CategoryIcons.MASK:
        setActualIcon(mask);
        break;
      case CategoryIcons.SUN:
        setActualIcon(sun);
        break;
      case CategoryIcons.HEARTH:
        setActualIcon(hearth);
        break;
      case CategoryIcons.BOW:
        setActualIcon(sport);
        break;
      case CategoryIcons.SMILE_NIGHT:
        props.clickedTag ? setActualIcon(smile) : setActualIcon(smileEvening);
        break;
      case CategoryIcons.BRAIN:
        props.clickedTag ? setActualIcon(brain_evening) : setActualIcon(brain);
        break;
      case CategoryIcons.DRINK:
        props.clickedTag ? setActualIcon(drink_evening) : setActualIcon(drink);

        break;
    }
  }, [props.icon, props.clickedTag]);

  return (
    <div className="tags-container">
      <div
        className={
          props.clickedTag
            ? "single-tag-active"
            : props.type === "day"
            ? "single-tag"
            : "single-tag-evening"
        }
      >
        <div>
          <img src={actualIcon} alt={props.icon} />
        </div>
        <div className="tag-title">{props.title}</div>
      </div>
    </div>
  );
};

export default Tags;
