export const AlertIcon = () => {
	return (
		<div>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="30.718"
				height="28.947"
				viewBox="0 0 30.718 28.947"
			>
				<path
					id="Path_291"
					data-name="Path 291"
					d="M30.08,36.909,19.229,16.962a4.5,4.5,0,0,0-7.739,0L.639,36.909a4.5,4.5,0,0,0,3.869,6.8h21.7A4.5,4.5,0,0,0,30.08,36.909Zm-14.721,3.2a1.8,1.8,0,1,1,1.8-1.8A1.8,1.8,0,0,1,15.359,40.107Zm1.8-7.2a1.8,1.8,0,0,1-3.6,0v-9a1.8,1.8,0,1,1,3.6,0Z"
					transform="translate(0 -14.759)"
					fill="#fff"
				/>
			</svg>
		</div>
	);
};
