export const ArrowIcon = () => {
	return (
		<div>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="42"
				height="13"
				viewBox="0 0 42 13"
			>
				<path
					id="Polygon_3"
					data-name="Polygon 3"
					d="M21,0,42,13H0Z"
					transform="translate(42 13) rotate(180)"
					fill="#f8f5ff"
				/>
			</svg>
		</div>
	);
};
