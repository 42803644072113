import React, { useContext, useRef } from "react";
import { DayEvening, SingleDay, SingleEvening } from "../../strings/string";
import { IDay } from "../../models/number-of-day";
import "./section-summary.scss";
import { UserContext } from "../../context-api/context";
import TimeLineSummary from "./timeline-summary";
import { Program } from "../../enums/activity";

const SectionSummary = (props: IDay) => {
  const { program } = useContext(UserContext);

  const MapDays = !program.includes(Program.NIGHT)
    ? SingleDay
    : !program.includes(Program.DAY)
    ? SingleEvening
    : DayEvening;

  const ref = useRef(null);

  const timelineSummaryDaysMap = MapDays.map((item) => (
    <TimeLineSummary
      key={item.title_section}
      title={item.title_section}
      type={item.type}
      numberDay={props.numberDay}
      weekIndex={props.weekIndex}
    />
  ));

  return (
    <div ref={ref} className="section-summary">
      {timelineSummaryDaysMap}
    </div>
  );
};

export default SectionSummary;
