import React, { useEffect, useState } from "react";
import { ScheduleDay, Day } from "../../models/firebase-order";
import { IEveningTimes } from "./pdf";
import "./schedule-day-row.scss";
import { createSchedule } from "../../utils";

interface Props {
  day?: ScheduleDay;
  times: string[];
  eveningTimes?: IEveningTimes;
}

const ScheduleDayColumn = ({ day, times, eveningTimes }: Props) => {
  const [daySchedule, setDaySchedule] = useState<Day[]>([]);
  const [lateNightSchedule, setLateNightSchedule] = useState<Day[]>([]);
  const [showtimeSchedule, setShowtimeSchedule] = useState<Day[]>([]);
  const [minidiskoSchedule, setMinidiskoSchedule] = useState<Day[]>([]);

  useEffect(() => {
    day && setDaySchedule(createSchedule(times, day?.day));
    createEveningSchedule();
    // eslint-disable-next-line
  }, [day, times, eveningTimes]);

  const createEveningSchedule = () => {
    if (day && eveningTimes) {
      setLateNightSchedule(
        createSchedule(eveningTimes.lateNight, day.evening.lateNight)
      );
      setShowtimeSchedule(
        createSchedule(eveningTimes.showtime, day.evening.showTime)
      );
      setMinidiskoSchedule(
        createSchedule(eveningTimes.minidisko, day.evening.minidisco)
      );
    }
  };

  const getScheduleActivityStyle = (
    schedule: boolean,
    empty: boolean = false
  ) =>
    empty
      ? schedule
        ? "schedule-activity-evening-empty"
        : "schedule-activity-empty"
      : schedule
      ? "schedule-activity-evening"
      : "schedule-activity";

  const createActivity = (
    scheduledDay: Day,
    index: number,
    nightSchedule: boolean = false
  ) =>
    scheduledDay.activity !== "" ? (
      <div className={getScheduleActivityStyle(nightSchedule)} key={index}>
        {scheduledDay.activity}
      </div>
    ) : (
      <div
        className={getScheduleActivityStyle(nightSchedule, true)}
        key={index}
      />
    );

  const dayColumn = daySchedule?.map((activity, index) =>
    createActivity(activity, index)
  );

  const isEveningProgramSet = day?.evening && (
    <>
      <div className="schedule-activities">
        {minidiskoSchedule.map((activity, index) =>
          createActivity(activity, index, true)
        )}
      </div>
      <div className="schedule-activities">
        {showtimeSchedule.map((activity, index) =>
          createActivity(activity, index, true)
        )}
      </div>
      <div className="schedule-activities">
        {lateNightSchedule.map((activity, index) =>
          createActivity(activity, index, true)
        )}
      </div>
    </>
  );

  return (
    <div className="schedule-day">
      <div className="schedule-activities">{dayColumn}</div>
      {isEveningProgramSet}
    </div>
  );
};

export default ScheduleDayColumn;
