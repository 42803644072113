import { Box, Toolbar } from "@mui/material";
import "./dashboard.scss";

import React from "react";
import { DashboardRoutes } from "../enums/routes";
import { useWindowSize } from "../custom-hooks/window-size";
import PcDrawer from "./drawers/pc-drawer";
import MobileDrawer from "./drawers/mobile-drawer";
import DashboardAppBar from "./dashboard-appbar";
import DashboardRouter from "./dashboard-router";

const Dashboard = () => {
  const drawerWidth = 240;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const windowSize = useWindowSize();

  const navigation = [
    { link: DashboardRoutes.ADMINISTRATORS, text: "administrátori" },
    { link: DashboardRoutes.ORDERS, text: "objednávky" },
    { link: DashboardRoutes.USERS, text: "používatelia" },
    { link: DashboardRoutes.ACTIVITIES, text: "aktivity" },
    { link: DashboardRoutes.COMBINATIONS, text: "kombinácie" },
  ];

  return (
    <Box sx={{ display: "flex" }}>
      <DashboardAppBar
        drawerWidth={drawerWidth}
        handleDrawerToggle={handleDrawerToggle}
      />
      <Box
        component="nav"
        sx={{
          width: { sm: drawerWidth },
          flexShrink: { sm: 0 },
        }}
        aria-label="mailbox folders"
      >
        <MobileDrawer
          navigation={navigation}
          drawerWidth={drawerWidth}
          handleDrawerToggle={handleDrawerToggle}
          mobileOpen={mobileOpen}
        />
        <PcDrawer navigation={navigation} drawerWidth={drawerWidth} />
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          backgroundColor: "#F9FAFC",
          minHeight: "100vh",
          width:
            windowSize.width! > 600
              ? { sm: `calc(100% - ${drawerWidth}px)` }
              : "100%",
        }}
      >
        <Toolbar />
        <DashboardRouter />
      </Box>
    </Box>
  );
};

export default Dashboard;
