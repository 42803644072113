import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TextField } from "@material-ui/core";
import React, { ChangeEvent, FormEvent, useState } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { Collection, Documents } from "../../enums/collections";
import { subTimeline } from "../../enums/sub-timeline";
import { db } from "../../firebase/firebase-init";
import { ICombinations } from "../../models/program";
import {
  DAY_PROGRAM,
  LATE_NIGHT,
  MINIDISCO,
  SHOWTIME,
  TIME,
} from "../../strings/string";
import {
  getAnimatorCountTitle,
  removeTimeFromCombination,
  reorder,
} from "../../utils";
import "./combinations-section.scss";

interface Props {
  combination: ICombinations | null;
  animatorCount: Documents;
}

const CombinationsTimeline = ({ combination, animatorCount }: Props) => {
  const [dayTimeline, setDayTimeline] = useState<string>("");
  const [minidiscoTimeline, setMinidiscoTimeline] = useState<string>("");
  const [showtimeTimeline, setShowtimeTimeline] = useState<string>("");
  const [lateNightTimeline, setLateNightTimeline] = useState<string>("");

  const combinationMap = (
    times: string[] | undefined,
    subTimeline: subTimeline
  ) =>
    times?.map((time, index) => (
      <Draggable draggableId={index.toString()} index={index} key={index}>
        {(provided) => (
          <div
            className="combination-row-time"
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            {time}
            <FontAwesomeIcon
              icon={faTimes}
              color="red"
              size="1x"
              onClick={removeTimeFromCombination(
                subTimeline,
                time,
                combination,
                animatorCount
              )}
            />
          </div>
        )}
      </Draggable>
    ));

  const handleSubmit =
    (formType: subTimeline) => (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (!combination) return;
      const newTimeline: ICombinations = {
        times:
          formType === subTimeline.DAY
            ? [...combination.times, dayTimeline]
            : combination.times,
        minidisko:
          formType === subTimeline.MINIDISCO
            ? [...combination.minidisko, minidiscoTimeline]
            : combination.minidisko,
        showtime:
          formType === subTimeline.SHOWTIME
            ? [...combination.showtime, showtimeTimeline]
            : combination.showtime,
        lateNight:
          formType === subTimeline.LATE_NIGHT
            ? [...combination.lateNight, lateNightTimeline]
            : combination.lateNight,
      };

      db.collection(Collection.COMBINATIONS)
        .doc(animatorCount)
        .update(newTimeline)
        .then(() => {
          formType === subTimeline.DAY && setDayTimeline("");
          formType === subTimeline.MINIDISCO && setMinidiscoTimeline("");
          formType === subTimeline.SHOWTIME && setShowtimeTimeline("");
          formType === subTimeline.LATE_NIGHT && setLateNightTimeline("");
        });
    };

  const handleChangeTime =
    (inputType: subTimeline) => (event: ChangeEvent<HTMLInputElement>) => {
      inputType === subTimeline.DAY && setDayTimeline(event.target.value);
      inputType === subTimeline.MINIDISCO &&
        setMinidiscoTimeline(event.target.value);
      inputType === subTimeline.SHOWTIME &&
        setShowtimeTimeline(event.target.value);
      inputType === subTimeline.LATE_NIGHT &&
        setLateNightTimeline(event.target.value);
    };

  const onDragEnd = (dragType: subTimeline) => (result: DropResult) => {
    if (
      !result.destination ||
      result.destination.index === result.source.index ||
      !combination
    )
      return;

    const array =
      dragType === subTimeline.DAY
        ? combination?.times
        : dragType === subTimeline.MINIDISCO
        ? combination?.minidisko
        : dragType === subTimeline.SHOWTIME
        ? combination?.showtime
        : combination?.lateNight;

    const newOrderTimeline = reorder(
      array,
      result.source.index,
      result.destination.index
    );

    const newTimeline: ICombinations = {
      times:
        dragType === subTimeline.DAY ? newOrderTimeline : combination.times,
      minidisko:
        dragType === subTimeline.MINIDISCO
          ? newOrderTimeline
          : combination.minidisko,
      showtime:
        dragType === subTimeline.SHOWTIME
          ? newOrderTimeline
          : combination.showtime,
      lateNight:
        dragType === subTimeline.LATE_NIGHT
          ? newOrderTimeline
          : combination.lateNight,
    };

    db.collection(Collection.COMBINATIONS)
      .doc(animatorCount)
      .update(newTimeline);
  };

  const combinationsData = [
    {
      title: DAY_PROGRAM,
      combinationTimeline: combination?.times,
      subTimelineType: subTimeline.DAY,
      value: dayTimeline,
    },
    {
      title: MINIDISCO,
      combinationTimeline: combination?.minidisko,
      subTimelineType: subTimeline.MINIDISCO,
      value: minidiscoTimeline,
    },
    {
      title: SHOWTIME,
      combinationTimeline: combination?.showtime,
      subTimelineType: subTimeline.SHOWTIME,
      value: showtimeTimeline,
    },
    {
      title: LATE_NIGHT,
      combinationTimeline: combination?.lateNight,
      subTimelineType: subTimeline.LATE_NIGHT,
      value: lateNightTimeline,
    },
  ];

  const combinationsSection = (
    <div className="combination-row">
      {combinationsData.map((item, index) => (
        <div key={index}>
          <h2 className="combination-title">{item.title}</h2>
          <DragDropContext onDragEnd={onDragEnd(item.subTimelineType)}>
            <Droppable droppableId={item.subTimelineType}>
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="combination-column"
                >
                  {combinationMap(
                    item.combinationTimeline,
                    item.subTimelineType
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <form onSubmit={handleSubmit(item.subTimelineType)}>
            <TextField
              label={TIME}
              variant="outlined"
              onChange={handleChangeTime(item.subTimelineType)}
              value={item.value}
            />
          </form>
        </div>
      ))}
    </div>
  );

  return (
    <div className="combination-timeline">
      <h1>{getAnimatorCountTitle(animatorCount)}</h1>
      {combinationsSection}
    </div>
  );
};

export default CombinationsTimeline;
