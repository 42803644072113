import React, { useContext } from "react";
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../context-api/context";
import UseDays from "../../custom-hooks/use-days";
import {
  ADD_WEEK,
  CONTINUE,
  NEW_WEEK,
  NEW_WEEK_TEXT,
} from "../../strings/string";
import { createScheduleDay } from "../../utils";
import "./modal-add-new-week.scss";

interface Props {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  setIsProgramDone: React.Dispatch<React.SetStateAction<boolean>>;
  setPercentage?: React.Dispatch<React.SetStateAction<number[]>>;
}

const ModalAddNewWeek = ({
  show,
  setShow,
  setIsProgramDone,
  setPercentage,
}: Props) => {
  const {
    setSchedule,
    dayOne,
    dayTwo,
    dayThree,
    dayFour,
    dayFive,
    daySix,
    daySeven,
    countDays,
    schedule,
    counter,
  } = useContext(UserContext);

  const history = useHistory();
  const { setEmptyTimeline } = UseDays();

  const handleClose = () => {
    setShow(false);
  };

  const handleAddNewWeek = () => {
    setSchedule((previous) => [
      ...previous,
      [
        createScheduleDay(dayOne),
        createScheduleDay(dayTwo),
        createScheduleDay(dayThree),
        createScheduleDay(dayFour),
        createScheduleDay(dayFive),
        createScheduleDay(daySix),
        createScheduleDay(daySeven),
      ],
    ]);

    setEmptyTimeline(counter);
    setIsProgramDone(false);
    setPercentage && setPercentage([0, 0, 0, 0, 0, 0, 0]);
    handleClose();
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleContinue = () => {
    schedule.length > 0 &&
      setSchedule((previous) => [
        ...previous,
        [
          createScheduleDay(dayOne),
          createScheduleDay(dayTwo),
          createScheduleDay(dayThree),
          createScheduleDay(dayFour),
          createScheduleDay(dayFive),
          createScheduleDay(daySix),
          createScheduleDay(daySeven),
        ],
      ]);
    history.push("/send-order");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <Modal
      show={show && countDays === 7}
      dialogClassName="modal-add-new-week"
      onHide={handleClose}
    >
      <div className="modal-add-new-week modal-add-new-week-container">
        <h1>{NEW_WEEK}</h1>
        <p>{NEW_WEEK_TEXT}</p>
        <div className="modal-program-buttons">
          <p className="button-add-week" onClick={handleAddNewWeek}>
            {ADD_WEEK}
          </p>
          <p className="buttom-continue" onClick={handleContinue}>
            {CONTINUE}
          </p>
        </div>
      </div>
    </Modal>
  );
};

export default ModalAddNewWeek;
