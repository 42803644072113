import React, { useContext, useEffect, useRef, useState } from "react";
import "./count-days.scss";
import { UserContext } from "../../context-api/context";
import { DAYS_TITLE, SNACKBAR_OFFER_ACTUALIZED } from "../../strings/string";
import SnackBarAlert from "../S2-select-activities/snack-bar";
import { Variant } from "../../enums/snackbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import { db } from "../../firebase/firebase-init";
import { Collection } from "../../enums/collections";
import Shimmer from "react-shimmer-effect";

const CountDays = () => {
  const { setCountDays, countDays, setSchedule } = useContext(UserContext);
  const [daysCountOptions, setDaysCountOptions] = useState<number[]>([]);

  const [isVisible, setIsVisible] = useState(false);
  const [showDrop, setShowDrop] = useState(false);
  const [isLoaded, setIsloaded] = useState(false);
  const dropRef = useRef<HTMLDivElement>(null);

  const handleCountDay = (value: number) => {
    value < 7 && setSchedule([]);
    setCountDays(value);
    setShowDrop(false);
    if (countDays > 0) {
      setIsVisible(true);
      setTimeout(() => {
        setIsVisible(false);
      }, 3000);
    }
  };

  useEffect(() => {
    fetchDays();
  }, []);

  useEffect(() => {
    countDays === 0 &&
      daysCountOptions.length > 0 &&
      handleCountDay(daysCountOptions[0]);
    // eslint-disable-next-line
  }, [daysCountOptions]);

  const fetchDays = async () => {
    await db
      .collection(Collection.HEADER)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          if (doc.exists) {
            setDaysCountOptions(doc.data().days);
          }
        });
      });
    setIsloaded(true);
  };

  const handleClickDrop = () => {
    setShowDrop(true);
  };

  /**
   * Alert if clicked on outside of element
   */
  const handleClickOutside = (event: MouseEvent) => {
    if (dropRef.current && !dropRef.current.contains(event.target as Node)) {
      setShowDrop(false);
    }
  };

  useEffect(() => {
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropRef]);

  const dayCountMap = daysCountOptions.map((option) => (
    <div
      onClick={() => countDays !== option && handleCountDay(option)}
      key={option}
      className="row-number"
    >
      <p
        className={
          countDays === option ? "number-dropdown-active" : "number-dropdown"
        }
      >
        {option}
      </p>
    </div>
  ));

  const showSnackBarAlert = isVisible && (
    <SnackBarAlert variant={Variant.SUCCESS} text={SNACKBAR_OFFER_ACTUALIZED} />
  );

  const isCountDaysLoaded = isLoaded ? (
    <div className="day-count" onClick={handleClickDrop}>
      <div className="number-day-active">
        {countDays}
        <FontAwesomeIcon icon={faSortDown} color="white" size="1x" />
      </div>
    </div>
  ) : (
    <div className="day-count">
      <Shimmer>
        <p className="number-day-active" />
      </Shimmer>
    </div>
  );

  const showDropdown = showDrop ? (
    <div className="column-days" ref={dropRef}>
      <div className="top-active-number">
        {countDays}
        <FontAwesomeIcon icon={faSortUp} color="white" size="1x" />
      </div>
      <div className="divider" />

      {dayCountMap}
    </div>
  ) : (
    isCountDaysLoaded
  );

  return (
    <div>
      {showSnackBarAlert}
      <div className="count-day-container">
        <p className="count-title">{DAYS_TITLE}</p>
        {showDropdown}
      </div>
    </div>
  );
};

export default CountDays;
