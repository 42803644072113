import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Collection } from "../../enums/collections";
import { DashboardRoutes, Routes } from "../../enums/routes";
import { db } from "../../firebase/firebase-init";
import { ACTIVITIES } from "../../strings/string";

const ActivityCard = () => {
  const [activitiesCount, setActivitiesCount] = useState(0);

  useEffect(() => {
    db.collection(Collection.ACTIVITIES).onSnapshot((snapShot) =>
      setActivitiesCount(snapShot.size)
    );
  }, []);

  return (
    <Link to={`${Routes.DASHBOARD}/${DashboardRoutes.ACTIVITIES}`}>
      <div className="dashboard-card green-gradient">
        <h3>{ACTIVITIES}</h3>
        <p>{activitiesCount}</p>
      </div>
    </Link>
  );
};

export default ActivityCard;
