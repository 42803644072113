import { Button } from "@mui/material";
import { useHistory } from "react-router-dom";
import "./orders.scss";
import { DataGrid, GridRenderCellParams } from "@mui/x-data-grid";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import ModalRemoveOrder from "./modal-remove-order";
import { DashboardRoutes, Routes } from "../../enums/routes";
import { IFirebaseOrder } from "../../models/firebase-order";
import { createData } from "../../utils";

interface Props {
  tableRows: IFirebaseOrder[];
}

const OrderTable = ({ tableRows }: Props) => {
  const [openModal, setOpenModal] = useState(false);
  const [orderId, setOrderId] = useState("");

  const history = useHistory();

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleRemove = (orderId: string) => () => {
    setOrderId(orderId);
    handleOpen();
  };

  const navigateToDetail = (id: string) => () => {
    history.push(`${Routes.DASHBOARD}/${DashboardRoutes.ORDERS}/${id}`);
  };

  const columns = [
    { field: "id", headerName: "ID", flex: 1, minWidth: 200 },
    { field: "customer", headerName: "Meno", flex: 1, minWidth: 200 },
    { field: "date", headerName: "Dátum", flex: 1, minWidth: 200 },
    {
      field: "detail",
      headerName: "Detail",
      renderCell: (params: GridRenderCellParams<string>) => (
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#c31702",
            "&:hover": { backgroundColor: "#db4e17" },
          }}
          onClick={navigateToDetail(params.value)}
        >
          Detail
        </Button>
      ),
    },
    {
      field: "delete",
      headerName: "Zmazať",
      renderCell: (params: GridRenderCellParams<string>) => (
        <CloseIcon
          sx={{ color: "#c31702", cursor: "pointer" }}
          onClick={handleRemove(params.value)}
        />
      ),
      flex: 1,
    },
  ];

  return (
    <div className="order-table-container">
      <DataGrid
        sx={{ backgroundColor: "#ffffff" }}
        rows={createData(tableRows)}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        checkboxSelection={false}
      />

      <ModalRemoveOrder
        show={openModal}
        handleClose={handleClose}
        orderId={orderId}
      />
    </div>
  );
};

export default OrderTable;
