import React from "react";
import { IEveningTimes } from "./pdf";
import "./times.scss";

interface Props {
  times: string[];
  eveningTimes?: IEveningTimes;
}

const Times = ({ times, eveningTimes }: Props) => {
  return (
    <div className="times-container">
      {times.map((time, index) => (
        <div key={index} className="times-box">
          <h3>{time}</h3>
        </div>
      ))}
      {eveningTimes?.minidisko.map((time, index) => (
        <div key={index} className="times-box-evening">
          <h3>{time}</h3>
        </div>
      ))}
      {eveningTimes?.showtime.map((time, index) => (
        <div key={index} className="times-box-evening">
          <h3>{time}</h3>
        </div>
      ))}
      {eveningTimes?.lateNight.map((time, index) => (
        <div key={index} className="times-box-evening">
          <h3>{time}</h3>
        </div>
      ))}
    </div>
  );
};

export default Times;
