export enum Variant {
  SUCCESS,
  ERROR,
  ALERT,
}

export enum VariantColor {
  SUCCESS = "#3CD19E",
  ERROR = "#EA4031",
  ALERT = "#F6BB31",
}
