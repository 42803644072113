import React from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { UserContext } from "../context-api/context";
import { Routes } from "../enums/routes";
import FirstScreen from "./S1-basic-configuration/first-screen";
import Activities from "./S2-select-activities/activities";
import SendOrder from "./S3-send-order/send-order";
import SuccessPage from "./S4-success/success-page";
import NotFound from "../url-not-found";
import AuthComponent from "./auth-component";
import Dashboard from "../dashboard/dashboard";
import AdminAccess from "./admin-access";
import Pdf from "./pdf/pdf";

const Layout = () => {
  const location = useLocation();

  const showAuthComponent = () =>
    location.pathname.includes(Routes.DASHBOARD) ||
    location.pathname.includes(Routes.ONLY_PDF);

  return (
    <>
      {!showAuthComponent() && <AuthComponent />}
      <Switch>
        <Route exact path={Routes.HOME}>
          <FirstScreen />
        </Route>
        <Route path={Routes.ACTIVITIES}>
          <UserContext.Consumer>
            {(value) => <Activities {...value.inTimeLine} />}
          </UserContext.Consumer>
        </Route>
        <Route path={Routes.SEND_ORDER}>
          <SendOrder />
        </Route>
        <Route path={Routes.SUCCESS}>
          <SuccessPage />
        </Route>
        <Route path={Routes.DASHBOARD}>
          <AdminAccess>
            <Dashboard />
          </AdminAccess>
        </Route>
        <Route path={Routes.PDF}>
          <Pdf />
        </Route>
        <Route path={Routes.ERROR} component={NotFound} />
      </Switch>
    </>
  );
};

export default Layout;
