import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Collection } from "../../enums/collections";
import { DashboardRoutes, Routes } from "../../enums/routes";
import { db } from "../../firebase/firebase-init";
import { ADMINS } from "../../strings/string";

const AdminsCard = () => {
  const [adminCount, setAdminCount] = useState(0);

  useEffect(() => {
    db.collection(Collection.ADMINS).onSnapshot((snapShot) =>
      setAdminCount(snapShot.size)
    );
  }, []);

  return (
    <Link to={`${Routes.DASHBOARD}/${DashboardRoutes.ADMINISTRATORS}`}>
      <div className="dashboard-card red-gradient">
        <h3>{ADMINS}</h3>
        <p>{adminCount}</p>
      </div>
    </Link>
  );
};

export default AdminsCard;
