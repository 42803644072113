import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Collection } from "../../enums/collections";
import { DashboardRoutes, Routes } from "../../enums/routes";
import { db } from "../../firebase/firebase-init";
import { ORDERS } from "../../strings/string";

const OrdersCard = () => {
  const [ordersCount, setOrdersCount] = useState(0);

  useEffect(() => {
    db.collection(Collection.ORDERS).onSnapshot((snapShot) =>
      setOrdersCount(snapShot.size)
    );
  }, []);

  return (
    <Link to={`${Routes.DASHBOARD}/${DashboardRoutes.ORDERS}`}>
      <div className="dashboard-card blue-gradient">
        <h3>{ORDERS}</h3>
        <p>{ordersCount}</p>
      </div>
    </Link>
  );
};

export default OrdersCard;
