import { Drawer } from "@mui/material";
import React from "react";
import { NavLink, useRouteMatch } from "react-router-dom";
import { DashboardRoutes, Routes } from "../../enums/routes";

interface Props {
  navigation: {
    link: DashboardRoutes;
    text: string;
  }[];
  drawerWidth: number;
}

const PcDrawer = ({ navigation, drawerWidth }: Props) => {
  let { url } = useRouteMatch();

  const drawer = (
    <div className="dashboard-navigation">
      <NavLink
        exact
        to={Routes.DASHBOARD}
        activeClassName="active-link"
        className="dashboard-navigation-link"
      >
        dashboard
      </NavLink>
      {navigation.map((navigation, index) => (
        <NavLink
          key={index}
          activeClassName="active-link"
          className="dashboard-navigation-link"
          to={`${url}/${navigation.link}`}
        >
          {navigation.text}
        </NavLink>
      ))}
    </div>
  );

  return (
    <Drawer
      variant="permanent"
      sx={{
        display: { xs: "none", sm: "block" },
        "& .MuiDrawer-paper": {
          backgroundColor: "#111827",
          padding: "10px",
          boxSizing: "border-box",
          width: drawerWidth,
        },
      }}
      open
    >
      {drawer}
    </Drawer>
  );
};

export default PcDrawer;
