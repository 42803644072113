import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import { DownloadIcon } from "../../assets/download-icon";
import { PdfIcon } from "../../assets/pdf-icon";
import { UserContext } from "../../context-api/context";
import "./pdf-success.scss";
import { DOWNLOAD_PDF, PROGRAM_IN_PDF } from "../../strings/string";
import { Link } from "react-router-dom";

const PdfSuccess = () => {
  const { orderId } = useContext(UserContext);

  const showDisabledButton = orderId ? (
    <Link to={`/pdf/${orderId}`} target="_blank">
      <Button variant="none" className="button-pdf">
        <DownloadIcon />
        <p className="button-font">{PROGRAM_IN_PDF}</p>
      </Button>
    </Link>
  ) : (
    <Button variant="none" className="button-pdf button-pdf-disabled" disabled>
      <p className="button-font">{PROGRAM_IN_PDF}</p>
    </Button>
  );

  return (
    <div className="pdf-container">
      <div className="pdf-title-row">
        <PdfIcon />
        <p>{DOWNLOAD_PDF}</p>
      </div>

      {showDisabledButton}
    </div>
  );
};

export default PdfSuccess;
