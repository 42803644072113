import React, { useContext, useEffect } from "react";
import { useState } from "react";
import "./activity-card.scss";
import { FirestoreActivity } from "../../models/activity";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserContext } from "../../context-api/context";
import { faCheck, faPlus } from "@fortawesome/free-solid-svg-icons";
import ActivityDetail from "./activity-detail";
import SnackBarAlert from "./snack-bar";
import { Variant } from "../../enums/snackbar";
import { ActivityType, Days, Program } from "../../enums/activity";
import { Collection } from "../../enums/collections";
import { db } from "../../firebase/firebase-init";
import {
  MORE_INFO,
  SNACKBAR_ACTIVITY_ADDED,
  SNACKBAR_ACTIVITY_REMOVED,
  SNACKBAR_ACTIVITY_LIMIT,
} from "../../strings/string";
import { getColor, truncateString } from "../../utils";
import { IDayMap } from "../../models/day-map";
import UseDays from "../../custom-hooks/use-days";

const ActivityCard = (props: FirestoreActivity & { dayOff: boolean }) => {
  const {
    program,
    counter,
    countDays,
    isRemovedCard,
    setIsRemovedCard,
    dayOne,
    dayTwo,
    dayThree,
    dayFour,
    dayFive,
    daySix,
    daySeven,
    schedule,
    dateStart,
  } = useContext(UserContext);

  const { fetchActualDay } = UseDays();

  const [showModal, setShowModal] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [actualDay, setActualDay] = useState(
    props.numberDay === Days.FIRST
      ? dayOne
      : props.numberDay === Days.SECOND
      ? dayTwo
      : props.numberDay === Days.THIRD
      ? dayThree
      : props.numberDay === Days.FOURTH
      ? dayFour
      : props.numberDay === Days.FIFTH
      ? dayFive
      : props.numberDay === Days.SIXTH
      ? daySix
      : daySeven
  );

  const [fullActivitity, setFullActivity] = useState(false);
  const [activityAdded, setActivityAdded] = useState(false);
  const [activityDeleted, setActivityDeleted] = useState(false);

  const [background, setBackground] = useState("");
  const [activeCard, setActiveCard] = useState<FirestoreActivity>();

  const [chooseDay, setChooseDay] = useState<string[]>([]);

  useEffect(() => {
    db.collection(Collection.ACTIVITIES).onSnapshot((snapshot) => {
      snapshot.forEach((doc) => {
        if (doc.exists && doc.id === props.id) {
          setActiveCard({
            ...doc.data(),
            id: doc.id,
            chooseDay: chooseDay,
          } as FirestoreActivity);
        }
      });
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let found = false;
    if (props.program === Program.DAY) {
      actualDay.day.forEach((item) => {
        if (item.title === props.title && item.chooseDay) {
          setActiveCard({
            ...activeCard,
            chooseDay: item.chooseDay,
          } as FirestoreActivity);
          setChooseDay(item.chooseDay);
          found = true;
        }
      });
    } else {
      actualDay.evening.minidisco.forEach((item) => {
        if (item.title === props.title && item.chooseDay) {
          setActiveCard({
            ...activeCard,
            chooseDay: item.chooseDay,
          } as FirestoreActivity);
          setChooseDay(item.chooseDay);
          found = true;
        }
      });
      actualDay.evening.showTime.forEach((item) => {
        if (item.title === props.title && item.chooseDay) {
          setActiveCard({
            ...activeCard,
            chooseDay: item.chooseDay,
          } as FirestoreActivity);
          setChooseDay(item.chooseDay);
          found = true;
        }
      });
      actualDay.evening.lateNight?.forEach((item) => {
        if (item.title === props.title && item.chooseDay) {
          setActiveCard({
            ...activeCard,
            chooseDay: item.chooseDay,
          } as FirestoreActivity);
          setChooseDay(item.chooseDay);
          found = true;
        }
      });
    }
    if (!found) {
      setChooseDay([]);
    }
    // eslint-disable-next-line
  }, [actualDay]);

  useEffect(() => {
    setBackground(getColor(props.type));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    props.dayOff && setIsChecked(false);
  }, [props.dayOff]);

  useEffect(() => {
    switch (props.numberDay) {
      case Days.FIRST:
        setActualDay(dayOne);
        break;

      case Days.SECOND:
        setActualDay(dayTwo);
        break;

      case Days.THIRD:
        setActualDay(dayThree);
        break;

      case Days.FOURTH:
        setActualDay(dayFour);
        break;

      case Days.FIFTH:
        setActualDay(dayFive);
        break;

      case Days.SIXTH:
        setActualDay(daySix);
        break;

      case Days.SEVENTH:
        setActualDay(daySeven);
        break;
    }
  }, [
    dayOne,
    dayTwo,
    dayThree,
    dayFour,
    dayFive,
    daySix,
    daySeven,
    props.numberDay,
  ]);

  const handleUnCheck = (fromTimeLine: boolean) => {
    let _map: Map<string, IDayMap> = new Map();
    _map =
      props.program === Program.DAY
        ? actualDay.day
        : props.type === ActivityType.SHOWTIME
        ? actualDay.evening.showTime
        : props.type === ActivityType.LATE_NIGHT
        ? actualDay.evening.lateNight
        : actualDay.evening.minidisco;

    let final: Map<string, any> = _map;
    _map.forEach((value, key) => {
      if (fromTimeLine) {
        if (value === isRemovedCard) {
          _map.set(key, {
            title: "",
          });
        }
      } else if (value.id === activeCard!.id) {
        _map.set(key, { title: "" });
      }
    });

    const _array = chooseDay.filter((item) => item !== props.numberDay!);
    setChooseDay(_array);

    if (props.program === Program.DAY) {
      fetchActualDay(
        {
          day: final,
          evening: actualDay.evening,
        },
        props.numberDay,
        undefined,
        false
      );
    } else {
      const fetchData = {
        day: actualDay.day,
        evening: {
          minidisco:
            props.type === ActivityType.MINIDISCO
              ? _map
              : actualDay.evening.minidisco,
          showTime:
            props.type === ActivityType.SHOWTIME
              ? _map
              : actualDay.evening.showTime,
          lateNight:
            props.type === ActivityType.LATE_NIGHT
              ? _map
              : actualDay.evening.lateNight,
        },
      };

      fetchActualDay(fetchData, props.numberDay, undefined, false);
    }
    setActivityDeleted(true);
    setTimeout(() => {
      setActivityDeleted(false);
    }, 3000);

    setActivityAdded(false);
    setIsChecked(false);
    setIsRemovedCard(null);
  };

  useEffect(() => {
    isRemovedCard && isRemovedCard.title === props.title && handleUnCheck(true);

    // eslint-disable-next-line
  }, [isRemovedCard, props.title]);

  useEffect(() => {
    !program.includes(props.program) && setIsChecked(false);
  }, [program, props.program]);

  useEffect(() => {
    chooseDay.includes(props.numberDay!) && setIsChecked(true);
    // eslint-disable-next-line
  }, [chooseDay]);

  useEffect(() => {
    setIsChecked(false);
  }, [schedule]);

  useEffect(() => {
    setIsChecked(false);
  }, [counter, countDays, dateStart]);

  const handleCheck = () => {
    if (chooseDay.includes(props.numberDay!)) {
      return;
    }

    let sizeDay = 0;
    let sizeMinidisco = 0;
    let sizeShowTime = 0;
    let sizeLateNight = 0;

    actualDay.day.forEach((value) => {
      value.title !== "" && sizeDay++;
    });

    actualDay.evening.minidisco.forEach((value) => {
      value.title !== "" && sizeMinidisco++;
    });

    actualDay.evening.showTime.forEach((value) => {
      value.title !== "" && sizeShowTime++;
    });

    actualDay.evening.lateNight &&
      actualDay.evening.lateNight.forEach((value) => {
        value.title !== "" && sizeLateNight++;
      });

    const _isFull =
      (props.type === ActivityType.MINIDISCO &&
        sizeMinidisco === actualDay.evening.minidisco.size) ||
      (props.type === ActivityType.SHOWTIME &&
        sizeShowTime === actualDay.evening.showTime.size) ||
      (props.type === ActivityType.LATE_NIGHT &&
        (actualDay.evening.lateNight
          ? sizeLateNight === actualDay.evening.lateNight.size
          : true));

    if (
      (props.program === Program.DAY && sizeDay < actualDay.day.size) ||
      (props.program === Program.NIGHT && !_isFull)
    ) {
      let _array: Map<string, any> = new Map();
      _array =
        props.program === Program.DAY
          ? actualDay.day
          : props.type === ActivityType.SHOWTIME
          ? actualDay.evening.showTime
          : props.type === ActivityType.LATE_NIGHT
          ? actualDay.evening.lateNight
          : actualDay.evening.minidisco;

      let final: Map<string, any> = _array;
      _array.forEach((value, key) => {
        value !== activeCard && final.set(key, value);
      });

      let _choosedDays: string[] = chooseDay;

      switch (props.numberDay) {
        case Days.FIRST:
          _choosedDays.push(Days.FIRST);
          break;

        case Days.SECOND:
          _choosedDays.push(Days.SECOND);
          break;

        case Days.THIRD:
          _choosedDays.push(Days.THIRD);
          break;

        case Days.FOURTH:
          _choosedDays.push(Days.FOURTH);
          break;

        case Days.FIFTH:
          _choosedDays.push(Days.FIFTH);
          break;

        case Days.SIXTH:
          _choosedDays.push(Days.SIXTH);
          break;

        case Days.SEVENTH:
          _choosedDays.push(Days.SEVENTH);
          break;
      }

      if (props.program === Program.DAY) {
        let isSet = false;
        _array.forEach((value, key) => {
          if (!isSet && value.title === "") {
            final.set(key, { ...activeCard, chooseDay: _choosedDays });
            isSet = true;
          }
        });

        const newcard = final;

        fetchActualDay(
          {
            day: newcard,
            evening: actualDay.evening,
          },
          props.numberDay,
          undefined,
          false
        );
      } else {
        switch (props.type) {
          case ActivityType.SHOWTIME:
            let isSetShowtime = false;
            _array.forEach((value, key) => {
              if (!isSetShowtime && value.title === "") {
                final.set(key, { ...activeCard, chooseDay: _choosedDays });
                isSetShowtime = true;
              }
            });

            fetchActualDay(
              {
                day: actualDay.day,
                evening: {
                  minidisco: actualDay.evening.minidisco,
                  showTime: final,
                  lateNight: actualDay.evening.lateNight,
                },
              },
              props.numberDay,
              undefined,
              false
            );
            break;

          case ActivityType.LATE_NIGHT:
            if (actualDay.evening.lateNight) {
              let isSet = false;
              _array.forEach((value, key) => {
                if (!isSet && value.title === "") {
                  final.set(key, { ...activeCard, chooseDay: _choosedDays });
                  isSet = true;
                }
              });

              fetchActualDay(
                {
                  day: actualDay.day,
                  evening: {
                    minidisco: actualDay.evening.minidisco,
                    showTime: actualDay.evening.showTime,
                    lateNight: final,
                  },
                },
                props.numberDay,
                undefined,
                false
              );
            }
            break;

          case ActivityType.MINIDISCO:
            let isSet = false;
            _array.forEach((value, key) => {
              if (!isSet && value.title === "") {
                final.set(key, { ...activeCard, chooseDay: _choosedDays });
                isSet = true;
              }
            });

            fetchActualDay(
              {
                day: actualDay.day,
                evening: {
                  minidisco: final,
                  showTime: actualDay.evening.showTime,
                  lateNight: actualDay.evening.lateNight,
                },
              },
              props.numberDay,
              undefined,
              false
            );

            break;
        }
      }
      setActivityDeleted(false);
      setActivityAdded(true);

      setTimeout(() => {
        setActivityAdded(false);
      }, 3000);

      setIsChecked(true);
    } else {
      setFullActivity(true);
      setTimeout(() => {
        setFullActivity(false);
      }, 3000);
    }
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const isActivityChecked = isChecked ? (
    <div className="check-activity">
      <div className="button-check-active" onClick={() => handleUnCheck(false)}>
        <FontAwesomeIcon icon={faCheck} color="white" size="1x" />
      </div>
      <p className="title-activity">{truncateString(props.title, 16)}</p>
    </div>
  ) : (
    <div className="check-activity">
      <div className="button-check" onClick={handleCheck}>
        <FontAwesomeIcon icon={faPlus} color="white" size="1x" />
      </div>
      <p className="title-activity">{truncateString(props.title, 16)}</p>
    </div>
  );

  return (
    <div
      className={"activity-card-container"}
      style={{ backgroundColor: background }}
    >
      <div>{isActivityChecked}</div>
      <div className="description-box">
        <p className="description-activity">
          {truncateString(props.description)}
        </p>
      </div>
      <div className="more-info-container">
        <div onClick={() => handleOpenModal()} className="more-info-on-click">
          <div className="more-info" />
          <p className="more-info-text">{MORE_INFO}</p>
        </div>
      </div>

      <ActivityDetail
        title={props.title}
        img={props.detail.img}
        description={props.detail.description}
        type={props.type}
        background={background}
        showModal={showModal}
        setShowModal={setShowModal}
      />
      {activityAdded && (
        <SnackBarAlert
          variant={Variant.SUCCESS}
          text={SNACKBAR_ACTIVITY_ADDED}
        />
      )}
      {activityDeleted && (
        <SnackBarAlert
          variant={Variant.SUCCESS}
          text={SNACKBAR_ACTIVITY_REMOVED}
        />
      )}
      {fullActivitity && (
        <SnackBarAlert variant={Variant.ALERT} text={SNACKBAR_ACTIVITY_LIMIT} />
      )}
    </div>
  );
};

export default ActivityCard;
