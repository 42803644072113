import { DropResult } from "react-beautiful-dnd";
import { ActivityType, Days, DetailImages, Program } from "./enums/activity";
import {
  ActivityColorDay,
  ActivityColorEvening,
  Collection,
  Documents,
} from "./enums/collections";
import { subTimeline } from "./enums/sub-timeline";
import { db } from "./firebase/firebase-init";
import { FirestoreActivity } from "./models/activity";
import { IDayMap } from "./models/day-map";
import { IToFirebaseActivity } from "./models/firebase-activity";
import { IFirebaseOrder, IOrderTable } from "./models/firebase-order";
import { Day } from "./models/firebase-order";
import { ICombinations, IProgram, IWeek } from "./models/program";
import { IEvening } from "./models/user-context";
import {
  FRIDAY,
  MONDAY,
  SATURDAY,
  SUNDAY,
  THURSDAY,
  TUESDAY,
  WEDNESDAY,
} from "./strings/string";

import aquagym from "./assets/activity-detail-image/aquagym.svg";
import lukostrelba from "./assets/activity-detail-image/lukostrelba.svg";
import balonove_kreacie from "./assets/activity-detail-image/balonove-kreacie.svg";
import carovny_les from "./assets/activity-detail-image/carovny-les.svg";
import foto_maskot from "./assets/activity-detail-image/fotenie-smaskotom.svg";
import boccia from "./assets/activity-detail-image/boccia.svg";
import bingo from "./assets/activity-detail-image/bingo.svg";
import hollywood_night from "./assets/activity-detail-image/hollywood-night.svg";
import music_quiz from "./assets/activity-detail-image/hudobny-kviz.svg";
import indians from "./assets/activity-detail-image/indiani.svg";
import karaoke_night from "./assets/activity-detail-image/karaoke-night.svg";
import paint_face from "./assets/activity-detail-image/malovanie-na-tvar.svg";
import paint_face_night from "./assets/activity-detail-image/malovanie-tvar-vecer.svg";
import we_painting from "./assets/activity-detail-image/malujeme-si.svg";
import minidisko_maskot from "./assets/activity-detail-image/minidisko-maskot.svg";
import minidisko_show from "./assets/activity-detail-image/minidisko-show.svg";
import nerf_shooting from "./assets/activity-detail-image/nerf-strelba.svg";
import night_tournament from "./assets/activity-detail-image/nocny-turnaj.svg";
import olympiada from "./assets/activity-detail-image/olympiada.svg";
import test from "./assets/activity-detail-image/otestuj-svoje-zmysly.svg";
import pirats from "./assets/activity-detail-image/pirati.svg";
import recycle from "./assets/activity-detail-image/recyklujeme.svg";
import fairy from "./assets/activity-detail-image/rozpravkovo.svg";
import handy from "./assets/activity-detail-image/sikovne-rucky.svg";
import wager from "./assets/activity-detail-image/stavme-sa.svg";
import super_hero from "./assets/activity-detail-image/super-hrdinovia.svg";
import dance from "./assets/activity-detail-image/tancujeme.svg";
import dance_lection from "./assets/activity-detail-image/tanecna-lekcia.svg";
import your_activity from "./assets/activity-detail-image/vasa-aktivita.svg";
import about_slovakia from "./assets/activity-detail-image/vsetko-oSlovensku.svg";
import bracles from "./assets/activity-detail-image/vyroba-naramkov.svg";
import sipky from "./assets/activity-detail-image/sipky.svg";
import { ISessionStorageDay } from "./models/session-storage";

export const getColor = (type: ActivityType): string => {
  switch (type) {
    case ActivityType.MINICLUB:
      return ActivityColorDay.MINICLUB;

    case ActivityType.SPORT:
      return ActivityColorDay.SPORT;

    case ActivityType.THEMATIC:
      return ActivityColorDay.THEMTIC;

    case ActivityType.FITNESS:
      return ActivityColorDay.FITNESS;

    case ActivityType.SUMMER:
      return ActivityColorDay.SUMMER;

    case ActivityType.MINIDISCO:
      return ActivityColorEvening.MINIDISCO;

    case ActivityType.SHOWTIME:
      return ActivityColorEvening.SHOWTIME;

    case ActivityType.LATE_NIGHT:
      return ActivityColorEvening.LATENIGHT;

    case ActivityType.WINTER:
      return ActivityColorDay.WINTER;

    case ActivityType.EASTER:
      return ActivityColorDay.EASTER;

    case ActivityType.HALLOWEEN:
      return ActivityColorDay.HALLOWEEN;

    case ActivityType.SANTA:
      return ActivityColorDay.SANTA;

    case ActivityType.CHRISTMAS:
      return ActivityColorDay.CHRISTMAS;

    case ActivityType.PAW_PATROL:
      return ActivityColorDay.PAW_PATROL;

    case ActivityType.WALT_DISNEY:
      return ActivityColorDay.WALT_DISNEY;

    case ActivityType.INDIANS:
      return ActivityColorDay.INDIANS;

    case ActivityType.PIRATS:
      return ActivityColorDay.PIRATS;
  }
};

export const hasCategorySomeEntry = (
  activityType: ActivityType,
  activities: FirestoreActivity[]
) => activities.some((activity) => activity.type === activityType);

export const shouldShowCategory = (
  categoryTitle: string,
  categoryProgram: string,
  lateNightLength: number,
  activitiesCards: FirestoreActivity[],
  cardType?: string
) =>
  cardType === categoryProgram &&
  (categoryTitle === ActivityType.LATE_NIGHT ? lateNightLength > 0 : true) &&
  hasCategorySomeEntry(categoryTitle as ActivityType, activitiesCards);

export const createData = (tableRows: IFirebaseOrder[]) => {
  const array: IOrderTable[] = [];

  tableRows.forEach((order) =>
    array.push({
      id: order.id,
      customer: `${order.costumer?.name ? order.costumer.name : "-"} ${
        order.costumer?.surname ? order.costumer?.surname : "-"
      }`,
      date: order.created.toDate().toLocaleDateString("sk"),
      detail: order.id,
      delete: order.id,
    } as IOrderTable)
  );

  return array;
};

export const addDays = (days: number, date?: Date) => {
  const daysArray: string[] = [
    SUNDAY,
    MONDAY,
    TUESDAY,
    WEDNESDAY,
    THURSDAY,
    FRIDAY,
    SATURDAY,
  ];
  if (date) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);

    return daysArray[result.getDay()];
  }
};

export const createSchedule = (times: string[], scheduleArray: Day[]) => {
  let array: Day[] = [];
  let index = 0;

  times.forEach((time) => {
    if (scheduleArray[index]?.time === time) {
      array.push(scheduleArray[index++]);
    } else {
      array.push({ time: time, activity: "" });
    }
  });

  return array;
};

export const getAnimatorCount = (order: IFirebaseOrder) =>
  order?.config.animators === 1
    ? "oneAnimator"
    : order?.config.animators === 2
    ? "twoAnimators"
    : "threeAnimators";

export const calculatePrice = (
  counter: number,
  countDays: number,
  schedule: IWeek[]
) => {
  const oneAnimator = 190;
  const twoAnimators = 270;
  const threeAnimators = 350;

  const realCountDays =
    schedule.length > 0 ? countDays * (schedule.length + 1) : countDays;

  let newPrice =
    counter === 1 ? oneAnimator : counter === 2 ? twoAnimators : threeAnimators;

  switch (counter) {
    case 1:
      if (realCountDays === 1) {
        return 190;
      } else if (realCountDays === 30) {
        return 1250;
      } else {
        for (let i = 0; i < realCountDays - 1; i++) {
          if (i === 0) {
            newPrice += 60;
          }
          if (i > 0 && i < 4) {
            newPrice += 50;
          }
          if (i > 3 && i < 9) {
            newPrice += 40;
          }
          if (i > 8 && i < 19) {
            newPrice += 35;
          }
          if (i > 18) {
            newPrice += 30;
          }
        }
        return newPrice;
      }
    case 2:
      if (realCountDays === 1) {
        return 270;
      } else if (realCountDays === 30) {
        return 2270;
      } else {
        for (let i = 0; i < realCountDays - 1; i++) {
          if (i >= 0 && i < 4) {
            newPrice += 100;
          }
          if (i > 3 && i < 9) {
            newPrice += 90;
          }
          if (i > 8 && i < 14) {
            newPrice += 70;
          }
          if (i > 13 && i < 19) {
            newPrice += 60;
          }
          if (i > 18) {
            newPrice += 50;
          }
        }
        return newPrice;
      }
    case 3:
      if (realCountDays === 1) {
        return 350;
      } else if (realCountDays === 30) {
        return 3210;
      } else {
        for (let i = 0; i < realCountDays - 1; i++) {
          if (i >= 0 && i < 4) {
            newPrice += 140;
          }
          if (i > 3 && i < 9) {
            newPrice += 120;
          }
          if (i > 8 && i < 14) {
            newPrice += 100;
          }
          if (i > 13) {
            newPrice += 80;
          }
        }
        return newPrice;
      }
  }
  return 0;
};

export const showPriceShimmer = (counter: number, countDays: number) =>
  counter === 0 && countDays === 0;

export const toFirestore = (
  data: Map<string, IDayMap>
): IToFirebaseActivity[] => {
  let _day: IToFirebaseActivity[] = [];

  if (data.size === 0) return _day;

  Array.from(data.values()).forEach((item: IDayMap, index) => {
    if (item.title !== "") {
      _day.push({
        time: Array.from(data.keys())[index],
        activity: item.title,
      });
    }
  });

  return _day;
};

export const getScheduleDay = (day: {
  day: Map<string, IDayMap>;
  evening: IEvening;
}) => {
  return {
    day: toFirestore(day.day),
    evening: {
      minidisco: toFirestore(day.evening.minidisco),
      showTime: toFirestore(day.evening.showTime),
      lateNight: day.evening.lateNight
        ? toFirestore(day.evening.lateNight)
        : [],
    },
  };
};

export const createScheduleDay = (day: IProgram) => {
  return {
    day: new Map(day.day),
    evening: {
      minidisco: new Map(day.evening.minidisco),
      lateNight: new Map(day.evening.lateNight),
      showTime: new Map(day.evening.showTime),
    },
  };
};

export const toSessionStorageDay = (day: IProgram) =>
  JSON.stringify({
    day: Array.from(day.day.entries()),
    evening: {
      minidisco: Array.from(day.evening.minidisco.entries()),
      showTime: Array.from(day.evening.showTime.entries()),
      lateNight: Array.from(day.evening.lateNight.entries()!),
    },
  });

export const truncateString = (text: string, length: number = 40) =>
  text.length > length ? text.substring(0, length) + "..." : text;

export const getAnimatorCountTitle = (animatorCount: Documents) => {
  switch (animatorCount) {
    case Documents.ONE_ANIMATOR:
      return "Jeden animátor";
    case Documents.TWO_ANIMATORS:
      return "Dvaja animátori";
    case Documents.THREE_ANIMATORS:
      return "Traja animátori";
    case Documents.ONE_ANIMATOR_WEEKEND:
      return "Jeden animátor víkend";
    case Documents.TWO_ANIMATORS_WEEKEND:
      return "Dvaja animátori víkend";
    case Documents.THREE_ANIMATORS_WEEKEND:
      return "Traja animátori víkend";
  }
};

export const removeTimeFromCombination =
  (
    subTime: subTimeline,
    timeToRemove: string,
    combination: ICombinations | null,
    animatorCount: Documents
  ) =>
  () => {
    if (!combination) return;
    const newCombination: ICombinations = {
      times:
        subTime === subTimeline.DAY
          ? combination.times.filter((time) => time !== timeToRemove)
          : combination.times,

      minidisko:
        subTime === subTimeline.MINIDISCO
          ? combination.minidisko.filter((time) => time !== timeToRemove)
          : combination.minidisko,

      showtime:
        subTime === subTimeline.SHOWTIME
          ? combination.showtime.filter((time) => time !== timeToRemove)
          : combination.showtime,

      lateNight:
        subTime === subTimeline.LATE_NIGHT
          ? combination.lateNight.filter((time) => time !== timeToRemove)
          : combination.lateNight,
    };

    db.collection(Collection.COMBINATIONS)
      .doc(animatorCount)
      .update(newCombination);
  };

export const reorder = (
  list: string[],
  startIndex: number,
  endIndex: number
) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const createWeekendTimeline = (combination: ICombinations | null) => {
  const day: Map<string, IDayMap> = new Map();
  const minidisco: Map<string, IDayMap> = new Map();
  const showTime: Map<string, IDayMap> = new Map();
  const lateNight: Map<string, IDayMap> = new Map();

  combination?.times.forEach((time) => {
    day.set(time, { title: "" });
  });

  combination?.minidisko.forEach((time) => {
    minidisco.set(time, { title: "" });
  });

  combination?.showtime.forEach((time) => {
    showTime.set(time, { title: "" });
  });

  combination?.lateNight.forEach((time) => {
    lateNight.set(time, { title: "" });
  });

  return {
    day: day,
    evening: {
      minidisco: minidisco,
      showTime: showTime,
      lateNight: lateNight,
    },
  };
};

export const getTitle = (numberDay: string | undefined): string | undefined => {
  switch (numberDay) {
    case Days.FIRST:
      return "1. deň";

    case Days.SECOND:
      return "2. deň";

    case Days.THIRD:
      return "3. deň";

    case Days.FOURTH:
      return "4. deň";

    case Days.FIFTH:
      return "5. deň";

    case Days.SIXTH:
      return "6. deň";

    case Days.SEVENTH:
      return "7. deň";
  }
};

export const reorderMap = (
  result: DropResult,
  activities: Map<string, IDayMap>
) => {
  const values = Array.from(activities.values());
  const keys = Array.from(activities.keys());

  const [reorderedTab] = values.splice(result.source.index, 1);

  values.splice(result.destination!.index, 0, reorderedTab);

  let _map = new Map<string, IDayMap>();
  values.forEach((value, index) => {
    _map.set(keys[index], value);
  });

  return _map;
};

export const getActivitiesCount = (activities: Map<string, IDayMap>) => {
  let _array: Map<string, IDayMap> = new Map();
  activities.forEach((value, key) => {
    value && value.title !== "" && _array.set(key, value);
  });

  return _array.size;
};

export const getActivityTimelineStyles = (programType: string | undefined) =>
  programType === "day"
    ? {
        containerStyle: "timeline-container",
        stickySidebarContainerStyle: "timeline-container sticky-sidebar",
        timelineTitle: "timeline-title",
        columnsTimeline: "columns-timeline-evening",
      }
    : {
        containerStyle: "timeline-container-evening",
        stickySidebarContainerStyle:
          "timeline-container-evening sticky-sidebar",
        timelineTitle: "timeline-title-evening",
        columnsTimeline: "columns-timeline",
      };

export const getTimelineSummaryStyles = (programType: string | undefined) =>
  programType === "day"
    ? {
        containerStyle: "timeline-container-sum",
        timelineTitle: "timeline-title",
        columnsTimeline: "columns-timeline-evening",
      }
    : {
        containerStyle: "timeline-container-evening-sum",
        timelineTitle: "timeline-title-evening",
        columnsTimeline: "columns-timeline-sum",
      };

export const getPercentage = (
  dayOff: boolean,
  program: Program[],
  dayCount: number,
  eveningCount: number,
  totalEveningCount: number,
  totalDayCount: number
) => {
  if (dayOff) {
    return 100;
  } else {
    if (!program.includes(Program.DAY)) {
      return (eveningCount * 100) / totalEveningCount;
    } else {
      return (
        ((dayCount + eveningCount) * 100) / (totalDayCount + totalEveningCount)
      );
    }
  }
};

export const getImage = (img: string): string => {
  switch (img) {
    case DetailImages.AQUAGYM:
      return aquagym;

    case DetailImages.ARCHERY:
      return lukostrelba;

    case DetailImages.BALON_CREATION:
      return balonove_kreacie;

    case DetailImages.BINGO:
      return bingo;

    case DetailImages.BOCCIA:
      return boccia;

    case DetailImages.MAGIC_FORREST:
      return carovny_les;

    case DetailImages.PHOTO_MASKOT:
      return foto_maskot;

    case DetailImages.HOLLYWOOD_NIGHT:
      return hollywood_night;

    case DetailImages.MUSIC_QUIZ:
      return music_quiz;

    case DetailImages.INDIANS:
      return indians;

    case DetailImages.KARAOKE_NIGHT:
      return karaoke_night;

    case DetailImages.FACE_PAINT:
      return paint_face;

    case DetailImages.FACE_PAINT_EVENING:
      return paint_face_night;

    case DetailImages.WE_PAINTING:
      return we_painting;

    case DetailImages.MINIDISKO_MASKOT:
      return minidisko_maskot;

    case DetailImages.MINIDISKO_SHOW:
      return minidisko_show;

    case DetailImages.SHOOTING:
      return nerf_shooting;

    case DetailImages.NIGHT_TOURNAMENT:
      return night_tournament;

    case DetailImages.OLYMPIADA:
      return olympiada;

    case DetailImages.TEST_SENSE:
      return test;

    case DetailImages.PIRATS:
      return pirats;

    case DetailImages.RECYCLE:
      return recycle;

    case DetailImages.FAIRY_TALE:
      return fairy;

    case DetailImages.HANDY:
      return handy;

    case DetailImages.WAGER:
      return wager;

    case DetailImages.SUPER_HERO:
      return super_hero;

    case DetailImages.DANCE:
      return dance;

    case DetailImages.DANCE_LECTION:
      return dance_lection;

    case DetailImages.YOUR_ACTIVITY:
      return your_activity;

    case DetailImages.ABOUT_SK:
      return about_slovakia;

    case DetailImages.BRACELET:
      return bracles;

    case DetailImages.ARROWS:
      return sipky;

    default:
      return "";
  }
};

export const isDayEmpty = (day: IProgram) => {
  if (
    Array.from(day.day.values()).filter((value) => value.title !== "").length >
      0 ||
    Array.from(day.evening.lateNight.values()).filter(
      (value) => value.title !== ""
    ).length > 0 ||
    Array.from(day.evening.minidisco.values()).filter(
      (value) => value.title !== ""
    ).length > 0 ||
    Array.from(day.evening.showTime.values()).filter(
      (value) => value.title !== ""
    ).length > 0
  ) {
    return false;
  } else return true;
};

export const createEmptyDay = (): IProgram =>
  Object({
    day: new Map(),
    evening: {
      minidisco: new Map(),
      showTime: new Map(),
      lateNight: new Map(),
    },
  });

export const isSessionStorageDayEmpty = (day: ISessionStorageDay) =>
  day.day.length === 0 &&
  day.evening.minidisco.length === 0 &&
  day.evening.showTime.length === 0 &&
  day.evening.lateNight.length === 0
    ? true
    : false;

export const createStartDate = (): Date => {
  const _date = String(sessionStorage.getItem("date"));
  if (_date.includes("GMT")) {
    const midnight = new Date();
    midnight.setHours(0, 0, 0, 0);

    if (new Date(_date).getTime() < midnight.getTime()) {
      sessionStorage.clear();
      return new Date();
    } else {
      return new Date(_date);
    }
  } else return new Date();
};
