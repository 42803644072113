export enum ActivityType {
  MINICLUB = "Miniklub",
  SPORT = "Šport",
  THEMATIC = "Tématické",
  FITNESS = "Fitness a tanec",
  SUMMER = "Leto",
  MINIDISCO = "Minidisko",
  SHOWTIME = "Showtime",
  LATE_NIGHT = "Late night",
  WINTER = "Zima",
  EASTER = "Veľká noc",
  HALLOWEEN = "Halloween",
  SANTA = "Mikuláš",
  CHRISTMAS = "Vianoce a Silvester",
  PAW_PATROL = "Paw patrol",
  WALT_DISNEY = "Walt Disney",
  INDIANS = "Indiani",
  PIRATS = "Piráti",
}

export enum Program {
  DAY = "day",
  NIGHT = "night",
}

export enum Days {
  FIRST = "first",
  SECOND = "second",
  THIRD = "third",
  FOURTH = "fourth",
  FIFTH = "fifth",
  SIXTH = "sixth",
  SEVENTH = "seventh",
}

export enum CategoryIcons {
  SMILE = "smile",
  MASK = "mask",
  HEARTH = "hearth",
  BOW = "bow",
  SUN = "sun",
  SMILE_NIGHT = "smile-night",
  BRAIN = "brain",
  DRINK = "drink",
}

export enum DetailImages {
  AQUAGYM = "aquagym",
  ARCHERY = "lukostrelba",
  BALON_CREATION = "balonove-kreacie",
  BINGO = "bingo",
  BOCCIA = "boccia",
  MAGIC_FORREST = "carovny-les",
  PHOTO_MASKOT = "fotenie-smaskotom",
  HOLLYWOOD_NIGHT = "hollywood-night",
  MUSIC_QUIZ = "hudobny-kviz",
  INDIANS = "indiani",
  KARAOKE_NIGHT = "karaoke-night",
  FACE_PAINT = "malovanie-na-tvar",
  FACE_PAINT_EVENING = "malovanie-tvar-vecer",
  WE_PAINTING = "malujeme-si",
  MINIDISKO_MASKOT = "minidisko-maskot",
  MINIDISKO_SHOW = "minidisko-show",
  SHOOTING = "nerf-strelba",
  NIGHT_TOURNAMENT = "nocny-turnaj",
  OLYMPIADA = "olympiada",
  TEST_SENSE = "otestuj-svoje-zmysly",
  PIRATS = "pirati",
  RECYCLE = "recyklujeme",
  FAIRY_TALE = "rozpravkovo",
  HANDY = "sikovne-rucky",
  WAGER = "stavme-sa",
  SUPER_HERO = "super-hrdinovia",
  DANCE = "tancujeme",
  DANCE_LECTION = "tanecna-lekcia",
  YOUR_ACTIVITY = "vasa-aktivita",
  ABOUT_SK = "vsetko-oSlovensku",
  BRACELET = "vyroba-naramkov",
  ARROWS = "sipky",
}
