import firebase from "firebase";
import { googleProvider } from "./firebase/firebase-init";

export const logIn = () => {
  return firebase.auth().signInWithPopup(googleProvider);
};

export const logOut = () => {
  return firebase.auth().signOut();
};
