export const EmptyCards = [
  {
    id: "20",
    title: "",
    chooseDay: [],
    kind: "day",
    whichDay: ["first", "second", "third"],
  },
  {
    id: "23",
    title: "",
    chooseDay: ["first", "second"],
    kind: "day",
    whichDay: ["first", "second", "third"],
  },
  {
    id: "24",
    title: "",
    chooseDay: ["first", "second"],
    kind: "day",
    whichDay: ["first", "second", "third"],
  },
  {
    id: "25",
    title: "",
    chooseDay: ["first", "second"],
    kind: "day",
    whichDay: ["first", "second", "third"],
  },
  {
    id: "26",
    title: "",
    chooseDay: ["first", "second"],
    kind: "day",
    whichDay: ["first", "second", "third"],
  },
  {
    id: "27",
    title: "",
    chooseDay: ["first", "second"],
    kind: "day",
    whichDay: ["first", "second", "third"],
  },
  {
    id: "28",
    title: "",
    chooseDay: ["first", "second"],
    kind: "day",
    whichDay: ["first", "second", "third"],
  },
  {
    id: "020",
    title: "",
    chooseDay: ["first", "second"],
    kind: "day",
    whichDay: ["first", "second", "third"],
  },
  {
    id: "023",
    title: "",
    chooseDay: ["first", "second"],
    kind: "day",
    whichDay: ["first", "second", "third"],
  },
  {
    id: "024",
    title: "",
    chooseDay: ["first", "second"],
    kind: "day",
    whichDay: ["first", "second", "third"],
  },
  {
    id: "025",
    title: "",
    chooseDay: ["first", "second"],
    kind: "day",
    whichDay: ["first", "second", "third"],
  },
  {
    id: "026",
    title: "",
    chooseDay: ["first", "second"],
    kind: "day",
    whichDay: ["first", "second", "third"],
  },
  {
    id: "027",
    title: "",
    chooseDay: ["first", "second"],
    kind: "day",
    whichDay: ["first", "second", "third"],
  },
  {
    id: "028",
    title: "",
    chooseDay: ["first", "second"],
    kind: "day",
    whichDay: ["first", "second", "third"],
  },
  {
    id: "030",
    title: "",
    chooseDay: ["first", "second"],
    kind: "day",
    whichDay: ["first", "second", "third"],
  },
  {
    id: "033",
    title: "",
    chooseDay: ["first", "second"],
    kind: "day",
    whichDay: ["first", "second", "third"],
  },
  {
    id: "034",
    title: "",
    chooseDay: ["first", "second"],
    kind: "day",
    whichDay: ["first", "second", "third"],
  },
  {
    id: "035",
    title: "",
    chooseDay: ["first", "second"],
    kind: "day",
    whichDay: ["first", "second", "third"],
  },
  {
    id: "036",
    title: "",
    chooseDay: ["first", "second"],
    kind: "day",
    whichDay: ["first", "second", "third"],
  },
  {
    id: "037",
    title: "",
    chooseDay: ["first", "second"],
    kind: "day",
    whichDay: ["first", "second", "third"],
  },
  {
    id: "038",
    title: "",
    chooseDay: ["first", "second"],
    kind: "day",
    whichDay: ["first", "second", "third"],
  },
];

export const EmptyCardsEvening = [
  {
    id: "31",
    title: "",
    chooseDay: ["first"],
    kind: "night",
    whichDay: ["first", "second", "third"],
  },
  {
    id: "33",
    title: "",
    chooseDay: ["first"],
    kind: "night",
    whichDay: ["first", "second", "third"],
  },
  {
    id: "34",
    title: "",
    chooseDay: ["first"],
    kind: "night",
    whichDay: ["first", "second", "third"],
  },
  {
    id: "35",
    title: "",
    chooseDay: ["first"],
    kind: "night",
    whichDay: ["first", "second", "third"],
  },
  {
    id: "031",
    title: "",
    chooseDay: ["first"],
    kind: "night",
    whichDay: ["first", "second", "third"],
  },
  {
    id: "033",
    title: "",
    chooseDay: ["first"],
    kind: "night",
    whichDay: ["first", "second", "third"],
  },
  {
    id: "034",
    title: "",
    chooseDay: ["first"],
    kind: "night",
    whichDay: ["first", "second", "third"],
  },
  {
    id: "035",
    title: "",
    chooseDay: ["first"],
    kind: "night",
    whichDay: ["first", "second", "third"],
  },
  {
    id: "133",
    title: "",
    chooseDay: ["first"],
    kind: "night",
    whichDay: ["first", "second", "third"],
  },
  {
    id: "134",
    title: "",
    chooseDay: ["first"],
    kind: "night",
    whichDay: ["first", "second", "third"],
  },
  {
    id: "235",
    title: "",
    chooseDay: ["first"],
    kind: "night",
    whichDay: ["first", "second", "third"],
  },
  {
    id: "233",
    title: "",
    chooseDay: ["first"],
    kind: "night",
    whichDay: ["first", "second", "third"],
  },
  {
    id: "234",
    title: "",
    chooseDay: ["first"],
    kind: "night",
    whichDay: ["first", "second", "third"],
  },
  {
    id: "235",
    title: "",
    chooseDay: ["first"],
    kind: "night",
    whichDay: ["first", "second", "third"],
  },
];

export const EmptyCardsDayTwo = [
  {
    id: "40",
    title: "",
    chooseDay: ["first", "second"],
    kind: "day",
    whichDay: ["first", "second", "third"],
  },
  {
    id: "41",
    title: "",
    chooseDay: ["first", "second"],
    kind: "day",
    whichDay: ["first", "second", "third"],
  },
  {
    id: "42",
    title: "",
    chooseDay: ["first", "second"],
    kind: "day",
    whichDay: ["first", "second", "third"],
  },
  {
    id: "43",
    title: "",
    chooseDay: ["first", "second"],
    kind: "day",
    whichDay: ["first", "second", "third"],
  },
  {
    id: "44",
    title: "",
    chooseDay: ["first", "second"],
    kind: "day",
    whichDay: ["first", "second", "third"],
  },
];
