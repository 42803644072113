import { Drawer } from "@mui/material";
import React from "react";
import { NavLink, useRouteMatch } from "react-router-dom";
import { DashboardRoutes, Routes } from "../../enums/routes";

type Props = {
  navigation: {
    link: DashboardRoutes;
    text: string;
  }[];
  drawerWidth: number;
  handleDrawerToggle: () => void;
  mobileOpen: boolean;
};

const MobileDrawer = ({
  navigation,
  drawerWidth,
  handleDrawerToggle,
  mobileOpen,
}: Props) => {
  let { url } = useRouteMatch();

  const mobileDrawer = (
    <div className="dashboard-navigation">
      <NavLink
        exact
        to={Routes.DASHBOARD}
        activeClassName="active-link"
        className="dashboard-navigation-link"
        onClick={handleDrawerToggle}
      >
        dashboard
      </NavLink>
      {navigation.map((navigation, index) => (
        <NavLink
          key={index}
          activeClassName="active-link"
          className="dashboard-navigation-link"
          onClick={handleDrawerToggle}
          to={`${url}/${navigation.link}`}
        >
          {navigation.text}
        </NavLink>
      ))}
    </div>
  );

  const container = document.body;

  return (
    <Drawer
      container={container}
      variant="temporary"
      open={mobileOpen}
      onClose={handleDrawerToggle}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      sx={{
        display: { xs: "block", sm: "none" },
        "& .MuiDrawer-paper": {
          boxSizing: "border-box",
          padding: "10px",
          width: drawerWidth,
          backgroundColor: "#111827",
        },
      }}
    >
      {mobileDrawer}
    </Drawer>
  );
};

export default MobileDrawer;
