import React from "react";
import "./send-order.scss";
import Formular from "./form";
import SummarySelection from "./summary-selection";
import { CONGRATULATION, PROGRAM_DONE, EDIT_TEXT } from "../../strings/string";
import ActivitiesSummary from "./activities-summary";
import { CongratsIcon } from "../../assets/congrats-icon";

const SendOrder = () => {
  return (
    <div className="third-creen-container">
      <div className="send-order-container">
        <SummarySelection />
        <div>
          <div className="program-done">
            <div className="congratulation-icon-container">
              <CongratsIcon />
            </div>
            <p className="congratulation">{CONGRATULATION}</p>
            <p className="your-program">{PROGRAM_DONE}</p>
          </div>
          <p className="info-program">{EDIT_TEXT}</p>
        </div>
        <div className="summary-and-form">
          <div className="activities-sum-component">
            <ActivitiesSummary />
          </div>
          <div className="activities-sum-form-pdf">
            <Formular />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SendOrder;
