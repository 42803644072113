import React, { useContext, useEffect, useState } from "react";
import { ISection } from "../../models/section";
import "./activity-timeline.scss";
import { UserContext } from "../../context-api/context";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import { DropResult } from "react-beautiful-dnd";
import { Days, Program } from "../../enums/activity";
import Shimmer from "react-shimmer-effect";
import {
  DAY_OFF,
  LATE_NIGHT,
  MINIDISCO,
  SHOWTIME,
  UNLOCK,
} from "../../strings/string";
import { faCheckCircle, faCircle } from "@fortawesome/free-solid-svg-icons";
import { IDayMap } from "../../models/day-map";
import DragAndDropTimes from "./drag-and-drop-times";
import {
  getActivitiesCount,
  getActivityTimelineStyles,
  reorderMap,
} from "../../utils";
import UseDays from "../../custom-hooks/use-days";
import ActivityCounter from "./activity-counter";

const TimeLine = (
  props: ISection & {
    dayOff: boolean;
    setDayOff: React.Dispatch<React.SetStateAction<boolean>>;
  }
) => {
  const {
    countDays,
    program,
    dayOne,
    setIsRemovedCard,
    dayTwo,
    dayThree,
    setIsDay,
    setIsShowed,
    dayFour,
    dayFive,
    daySix,
    daySeven,
  } = useContext(UserContext);

  const [actualDay, setActualDay] = useState(
    props.numberDay === Days.FIRST
      ? dayOne
      : props.numberDay === Days.SECOND
      ? dayTwo
      : props.numberDay === Days.THIRD
      ? dayThree
      : props.numberDay === Days.FOURTH
      ? dayFour
      : props.numberDay === Days.FIFTH
      ? dayFive
      : props.numberDay === Days.SIXTH
      ? daySix
      : daySeven
  );

  const [activities, setActivities] = useState(
    props.type === Program.DAY ? actualDay.day : actualDay.evening.minidisco
  );

  const handleUnlockDay = () => {
    if (props.type === Program.DAY) {
      setIsShowed(true);
      setIsDay(true);
    } else if (props.type === Program.NIGHT) {
      setIsShowed(true);
      setIsDay(false);
    }
  };

  useEffect(() => {
    switch (props.numberDay) {
      case Days.FIRST:
        setActualDay(dayOne);
        break;

      case Days.SECOND:
        setActualDay(dayTwo);
        break;

      case Days.THIRD:
        setActualDay(dayThree);
        break;

      case Days.FOURTH:
        setActualDay(dayFour);
        break;

      case Days.FIFTH:
        setActualDay(dayFive);
        break;

      case Days.SIXTH:
        setActualDay(daySix);
        break;

      case Days.SEVENTH:
        setActualDay(daySeven);
        break;
    }
  }, [
    dayOne,
    dayTwo,
    dayThree,
    dayFour,
    dayFive,
    daySix,
    daySeven,
    props.numberDay,
  ]);

  useEffect(() => {
    setActivities(
      props.type === "day" ? actualDay.day : actualDay.evening.minidisco
    );
  }, [actualDay.day, actualDay.evening, props.type]);

  const { fetchActualDay } = UseDays();

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    let _map = reorderMap(result, activities);
    setActivities(_map);

    if (props.type === "day") {
      fetchActualDay(
        { day: _map, evening: actualDay.evening },
        props.numberDay,
        props.weekIndex,
        false
      );
    } else {
      fetchActualDay(
        {
          day: actualDay.day,
          evening: {
            minidisco: _map,
            showTime: actualDay.evening.showTime,
            lateNight: actualDay.evening.lateNight,
          },
        },
        props.numberDay,
        props.weekIndex,
        false
      );
    }
  };

  const handleDragEndShowtime = (result: DropResult) => {
    if (!result.destination) return;

    fetchActualDay(
      {
        day: actualDay.day,
        evening: {
          minidisco: actualDay.evening.minidisco,
          showTime: reorderMap(result, actualDay.evening.showTime),
          lateNight: actualDay.evening.lateNight,
        },
      },
      props.numberDay,
      props.weekIndex,
      false
    );
  };

  const handleDragEndLateNight = (result: DropResult) => {
    if (!result.destination) return;

    fetchActualDay(
      {
        day: actualDay.day,
        evening: {
          minidisco: actualDay.evening.minidisco,
          showTime: actualDay.evening.showTime,
          lateNight: reorderMap(result, actualDay.evening.lateNight),
        },
      },
      props.numberDay,
      props.weekIndex,
      false
    );
  };

  const handleRemoveCard = (card: IDayMap) => {
    setIsRemovedCard(card);
  };

  const [dayCount, setDayCount] = useState(0);
  const [eveningCount, setEveningCount] = useState(0);

  useEffect(() => {
    setDayCount(getActivitiesCount(actualDay.day));
    setEveningCount(
      getActivitiesCount(actualDay.evening.minidisco) +
        getActivitiesCount(actualDay.evening.showTime) +
        getActivitiesCount(actualDay.evening.lateNight)
    );
    // eslint-disable-next-line
  }, [dayOne, dayTwo, dayThree, actualDay.evening]);

  const handleChangeDayOff = () => {
    props.setDayOff((state) => !state);

    if (!props.dayOff) {
      const dayMap: Map<string, IDayMap> = new Map();
      const minidiscoMap: Map<string, IDayMap> = new Map();
      const showtimeMap: Map<string, IDayMap> = new Map();
      const lateNightMap: Map<string, IDayMap> = new Map();

      Array.from(actualDay.day.keys()).forEach((key) =>
        dayMap.set(key, { title: "" })
      );
      Array.from(actualDay.evening.minidisco.keys()).forEach((key) =>
        minidiscoMap.set(key, { title: "" })
      );
      Array.from(actualDay.evening.showTime.keys()).forEach((key) =>
        showtimeMap.set(key, { title: "" })
      );
      Array.from(actualDay.evening.lateNight.keys()).forEach((key) =>
        lateNightMap.set(key, { title: "" })
      );

      fetchActualDay(
        {
          day: dayMap,
          evening: {
            minidisco: minidiscoMap,
            showTime: showtimeMap,
            lateNight: lateNightMap,
          },
        },
        props.numberDay,
        props.weekIndex,
        false
      );
    }
  };

  const styles = getActivityTimelineStyles(props.type);

  return (
    <>
      {countDays === 0 ? (
        <Shimmer>
          <div className={styles.containerStyle}></div>
        </Shimmer>
      ) : (
        <div className={styles.stickySidebarContainerStyle}>
          <div className="timeline-header">
            <p className={styles.timelineTitle}>{props.title}</p>

            {props.type === "day" && countDays === 7 && (
              <div
                onClick={handleChangeDayOff}
                className={props.dayOff ? "day" : "day-active"}
              >
                <FontAwesomeIcon
                  icon={props.dayOff ? faCircle : faCheckCircle}
                  color="white"
                  size="2x"
                />
                <p>{DAY_OFF}</p>
              </div>
            )}
          </div>
          {(!program.includes(Program.DAY) && props.type === Program.DAY) ||
          (!program.includes(Program.NIGHT) && props.type === Program.NIGHT) ||
          props.dayOff ? (
            <div className="vertical-center">
              {!props.dayOff && (
                <Button className="unlock-day-button" onClick={handleUnlockDay}>
                  <FontAwesomeIcon icon={faUnlockAlt} color="white" size="1x" />
                  <p className="unlock-day-button-text">{UNLOCK}</p>
                </Button>
              )}
            </div>
          ) : (
            <div>
              <div>
                {props.type !== Program.DAY && (
                  <div className="category-title">
                    <p>{MINIDISCO}</p>
                    <div />
                  </div>
                )}
                <div className={styles.columnsTimeline}>
                  <DragAndDropTimes
                    type={props.type}
                    activities={activities}
                    handleDragEnd={handleDragEnd}
                    handleRemoveCard={handleRemoveCard}
                  />
                </div>
                {actualDay.evening.showTime.size > 0 &&
                  props.type !== Program.DAY && (
                    <div>
                      <div className="category-title">
                        <p>{SHOWTIME}</p>
                        <div />
                      </div>
                      <div className="show-time-row">
                        <DragAndDropTimes
                          type={props.type}
                          activities={actualDay.evening.showTime}
                          handleDragEnd={handleDragEndShowtime}
                          handleRemoveCard={handleRemoveCard}
                        />
                      </div>
                    </div>
                  )}
                {actualDay.evening.lateNight.size > 0 &&
                  props.type !== Program.DAY && (
                    <div>
                      <div className="category-title">
                        <p>{LATE_NIGHT}</p>
                        <div />
                      </div>
                      <div className="show-time-row">
                        {actualDay.evening.lateNight && (
                          <DragAndDropTimes
                            type={props.type}
                            activities={actualDay.evening.lateNight}
                            handleDragEnd={handleDragEndLateNight}
                            handleRemoveCard={handleRemoveCard}
                          />
                        )}
                      </div>
                    </div>
                  )}
              </div>

              {props.type === Program.DAY && (
                <ActivityCounter
                  activitiesCount={dayCount}
                  totalCount={activities.size}
                  dayOrNight={Program.DAY}
                />
              )}

              {props.type === Program.NIGHT && (
                <ActivityCounter
                  activitiesCount={eveningCount}
                  totalCount={
                    activities.size +
                    actualDay.evening.showTime.size +
                    (actualDay.evening.lateNight
                      ? actualDay.evening.lateNight.size
                      : 0)
                  }
                  dayOrNight={Program.NIGHT}
                />
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default TimeLine;
