export const DownloadIcon = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="20"
        viewBox="0 0 24 20"
      >
        <g id="download" transform="translate(0 -2)">
          <path
            id="Path_215"
            data-name="Path 215"
            d="M12,16a.749.749,0,0,1-.542-.232l-5.25-5.5A.75.75,0,0,1,6.75,9H9.5V3.25A1.252,1.252,0,0,1,10.75,2h2.5A1.252,1.252,0,0,1,14.5,3.25V9h2.75a.75.75,0,0,1,.542,1.268l-5.25,5.5A.749.749,0,0,1,12,16Z"
            fill="#56576f"
          />
          <path
            id="Path_216"
            data-name="Path 216"
            d="M22.25,22H1.75A1.752,1.752,0,0,1,0,20.25v-.5A1.752,1.752,0,0,1,1.75,18h20.5A1.752,1.752,0,0,1,24,19.75v.5A1.752,1.752,0,0,1,22.25,22Z"
            fill="#56576f"
          />
        </g>
      </svg>
    </div>
  );
};
