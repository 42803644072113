import React, { useContext, useEffect, useState } from "react";
import "./select-activities.scss";
import {
  ACTIVITIES_KIND,
  NIGHT_PROGRAM_NOT_CHOSEN,
  DAY_PROGRAM_NOT_CHOSEN,
} from "../../strings/string";
import Tags from "./tags";
import ActivityCard from "./activity-card";
import { ISection } from "../../models/section";
import { UserContext } from "../../context-api/context";
import { Lock } from "../../assets/lock";
import { LockEvening } from "../../assets/lock-evening";
import { db } from "../../firebase/firebase-init";
import { Collection } from "../../enums/collections";
import { FirestoreActivity, FirestoreCategories } from "../../models/activity";
import { ActivityType, Program } from "../../enums/activity";
import ReactShimmer from "./react-shimmer";
import TagShimmer from "./tagShimmer";
import Shimmer from "react-shimmer-effect";
import { shouldShowCategory } from "../../utils";
import { ProgramType } from "../../enums/program-type";

const SelectActivities = (props: ISection & { dayOff: boolean }) => {
  const { program, countDays } = useContext(UserContext);
  const [clickedTag, setClickedTag] = useState<string[]>([]);
  const [categories, setCategories] = useState<FirestoreCategories[]>([]);
  const [activitiesCards, setActivitiesCards] = useState<FirestoreActivity[]>(
    []
  );
  const [isFetching, setIsFetching] = useState(true);
  const [isFetchingActivities, setIsFetchingActivities] = useState(true);

  useEffect(() => {
    let mounted = true;
    let array: FirestoreCategories[] = [];
    db.collection(Collection.CATEGORIES).onSnapshot((query) => {
      array = [];
      query.forEach((item) => {
        item.exists &&
          array.push({ ...item.data(), id: item.id } as FirestoreCategories);
      });
      if (mounted) {
        setCategories(array);
        setIsFetching(false);
      }
    });
    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    let mountedActive = true;
    let _array: FirestoreActivity[] = [];
    db.collection(Collection.ACTIVITIES).onSnapshot((snapshot) => {
      snapshot.forEach((doc) => {
        doc.exists &&
          _array.push({ ...doc.data(), id: doc.id } as FirestoreActivity);
      });
      if (mountedActive) {
        setActivitiesCards(_array);
        setIsFetchingActivities(false);
      }
      mountedActive = false;
    });
  }, []);

  const handleClickTag = (tag: string) => () => {
    const _array: any[] = clickedTag;
    if (clickedTag.includes(tag)) {
      const removeTag = _array.filter((item) => item !== tag);
      setClickedTag(removeTag);
    } else {
      setClickedTag([...clickedTag, tag]);
    }
  };

  const numberShimmer = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const tagShimmersCount = 3;

  const tagShimmers = Array.from({ length: tagShimmersCount }, (x, i) => i).map(
    (index) => <TagShimmer key={index} />
  );

  const categoryToTag = (category: FirestoreCategories) =>
    shouldShowCategory(
      category.title,
      category.program,
      props.actualDay!.evening.lateNight.size,
      activitiesCards,
      props.type
    ) && (
      <div key={category.title} onClick={handleClickTag(category.title)}>
        <Tags
          icon={category.icon}
          title={category.title}
          type={props.type}
          clickedTag={clickedTag.includes(category.title)}
        />
      </div>
    );

  const tags = categories.map(categoryToTag);

  const tagsMap = isFetching ? tagShimmers : tags;

  const dayOrEvening =
    props.type === "day"
      ? "select-activities-container"
      : "select-activities-container-evening";

  const lockContainerDayEvening =
    props.type === ProgramType.DAY
      ? "lock-container"
      : "lock-container-evening";

  const activitiesKindDayEvening =
    props.type === ProgramType.DAY
      ? "activities-kind"
      : "activities-kind-evening";

  const lockSection =
    (!program.includes(Program.DAY) && props.type === Program.DAY) ||
    (!program.includes(Program.NIGHT) && props.type === Program.NIGHT) ||
    props.dayOff;

  const activitiesMap = activitiesCards.map(
    (item: FirestoreActivity, index: number) =>
      (clickedTag.length !== 0
        ? clickedTag.includes(item.type) && props.type === item.program
        : props.type === item.program) &&
      (item.type === ActivityType.LATE_NIGHT
        ? props.actualDay!.evening.lateNight.size > 0
        : true) && (
        <div className="activity-card-box" key={item.title}>
          <ActivityCard
            id={item.id}
            title={item.title}
            description={item.description}
            numberDay={props.numberDay}
            category={item.category}
            program={item.program}
            type={item.type}
            detail={item.detail}
            dayOff={props.dayOff}
          />
        </div>
      )
  );

  const shouldShowDisabledText = !props.dayOff && (
    <div className="lock-center">
      <div className="lock">
        {props.type === Program.DAY ? <Lock /> : <LockEvening />}
        {props.type === Program.DAY ? (
          <p className="lock-notice">{DAY_PROGRAM_NOT_CHOSEN}</p>
        ) : (
          <p className="lock-notice-evening">{NIGHT_PROGRAM_NOT_CHOSEN}</p>
        )}
      </div>
    </div>
  );

  const shouldShowFetchingShimmer = isFetchingActivities ? (
    <>
      {numberShimmer.map((index) => (
        <ReactShimmer key={index} />
      ))}
    </>
  ) : (
    <>{activitiesMap}</>
  );

  const isSectionLocked = lockSection && (
    <div>
      <div className={lockContainerDayEvening} />

      {shouldShowDisabledText}
    </div>
  );

  return (
    <>
      {countDays === 0 ? (
        <Shimmer>
          <div className={dayOrEvening} />
        </Shimmer>
      ) : (
        <div className={dayOrEvening}>
          <div className="select-activities-subcontainer">
            {isSectionLocked}
            <div className="tags-bar">
              <p className={activitiesKindDayEvening}>{ACTIVITIES_KIND}</p>
              {tagsMap}
            </div>

            <div className="activity-cards">{shouldShowFetchingShimmer}</div>
          </div>
        </div>
      )}
    </>
  );
};

export default SelectActivities;
