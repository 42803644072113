import React, { useContext, useState } from "react";
import "./summary-selection.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import { UserContext } from "../../context-api/context";
import { Program } from "../../enums/activity";
import ModalBack from "./modal-back";
import {
  ANIMATOR_TITLE,
  BACK,
  BEGINNING_DATE,
  DAYS_TITLE,
  PROGRAM_TITLE,
  DAY,
  NIGHT,
  PRICE_TITLE,
} from "../../strings/string";

const SummarySelection = () => {
  const { program, countDays, counter, dateStart, price } =
    useContext(UserContext);

  const [openModal, setIsOpenModal] = useState(false);

  const formatStartDate = (): string => {
    let month = "" + (dateStart!.getMonth() + 1);
    let day = "" + dateStart!.getDate();
    let year = dateStart!.getFullYear();

    if (Number(month) < 10) {
      month = "0" + month;
    }
    if (Number(day) < 10) {
      day = "0" + day;
    }
    return day + "." + month + "." + year;
  };

  const handleOpenModal = () => {
    setIsOpenModal(true);
  };

  return (
    <div className="summary-selection-container">
      <div className="sub-container">
        <div className="summary-row" style={{ height: 60 }}>
          <Button
            className="button-back-s3"
            variant="none"
            onClick={handleOpenModal}
          >
            <FontAwesomeIcon icon={faChevronLeft} color="#56576F" size="1x" />
            <p>{BACK}</p>
          </Button>
        </div>
        <ModalBack
          title="Chcete sa vrátiť späť 
          na výber aktivít?"
          text="Svoj program budete môcť opäť editovať."
          openModal={openModal}
          setIsOpenModal={setIsOpenModal}
          answearLeft="Zrušiť"
          answearRight="Späť na výber"
          link="/activities"
        />
        <div className="summary-row">
          <p className="summary-row-title">{ANIMATOR_TITLE}</p>
          <div className="sum-option">{counter}</div>
        </div>
        <div className="summary-row">
          <p className="summary-row-title">{DAYS_TITLE}</p>
          <div className="sum-option">{countDays}</div>
        </div>
        <div className="summary-row">
          <p className="summary-row-title">{PROGRAM_TITLE}</p>
          <div className="day-evening-row">
            {program.includes(Program.DAY) && (
              <p className="day-evening-option">{DAY}</p>
            )}
            {program.includes(Program.NIGHT) && (
              <p className="day-evening-option">{NIGHT}</p>
            )}
          </div>
        </div>
        <div className="summary-row">
          <p className="summary-row-title">{BEGINNING_DATE}</p>
          <p className="date-sum">{dateStart && formatStartDate()}</p>
        </div>
        <div className="summary-row">
          <p className="summary-row-title">{PRICE_TITLE}</p>
          <p className="summary-row-price">{price} €</p>
        </div>
      </div>
    </div>
  );
};

export default SummarySelection;
