import React, { useContext, useEffect } from "react";
import "./price.scss";
import { PRICE_TITLE } from "../../strings/string";
import { UserContext } from "../../context-api/context";
import Shimmer from "react-shimmer-effect";
import { calculatePrice, showPriceShimmer } from "../../utils";

const Price = () => {
  const { price, setPrice, counter, countDays, schedule } =
    useContext(UserContext);

  useEffect(() => {
    setPrice(calculatePrice(counter, countDays, schedule));
    // eslint-disable-next-line
  }, [counter, countDays, schedule]);

  const priceContent = showPriceShimmer(counter, countDays) ? (
    <Shimmer>
      <p className="price-number">€</p>
    </Shimmer>
  ) : (
    <p className="price-number">{price} €</p>
  );

  return (
    <div className="price-container">
      <p className="price-title">{PRICE_TITLE}</p>
      {priceContent}
    </div>
  );
};

export default Price;
