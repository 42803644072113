import React, { useContext, useEffect, useState } from "react";
import { ISection } from "../../models/section";
import "./timeline-summary.scss";
import { UserContext } from "../../context-api/context";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import { DropResult } from "react-beautiful-dnd";
import { ArrowIcon } from "../../assets/arrow-icon";
import { Days, Program } from "../../enums/activity";
import { UNLOCK, MINIDISCO, SHOWTIME, LATE_NIGHT } from "../../strings/string";
import {
  getActivitiesCount,
  getTimelineSummaryStyles,
  getTitle,
  reorderMap,
} from "../../utils";
import DragAndDropTimes from "../S2-select-activities/drag-and-drop-times";
import UseDays from "../../custom-hooks/use-days";
import ActivityCounter from "../S2-select-activities/activity-counter";

const TimeLineSummary = (props: ISection) => {
  const {
    program,
    dayOne,
    setProgram,
    dayTwo,
    dayThree,
    dayFour,
    dayFive,
    daySix,
    daySeven,
    isDay,
    schedule,
  } = useContext(UserContext);

  const getDay = () => {
    if (schedule.length > 0) {
      return props.numberDay === Days.FIRST
        ? schedule[props.weekIndex!][0]
        : props.numberDay === Days.SECOND
        ? schedule[props.weekIndex!][1]
        : props.numberDay === Days.THIRD
        ? schedule[props.weekIndex!][2]
        : props.numberDay === Days.FOURTH
        ? schedule[props.weekIndex!][3]
        : props.numberDay === Days.FIFTH
        ? schedule[props.weekIndex!][4]
        : props.numberDay === Days.SIXTH
        ? schedule[props.weekIndex!][5]
        : schedule[props.weekIndex!][6];
    } else {
      return props.numberDay === Days.FIRST
        ? dayOne
        : props.numberDay === Days.SECOND
        ? dayTwo
        : props.numberDay === Days.THIRD
        ? dayThree
        : props.numberDay === Days.FOURTH
        ? dayFour
        : props.numberDay === Days.FIFTH
        ? dayFive
        : props.numberDay === Days.SIXTH
        ? daySix
        : daySeven;
    }
  };

  const [actualDay] = useState(getDay());
  const [activities, setActivities] = useState(
    props.type === "day" ? getDay().day : getDay().evening.minidisco
  );

  const handleUnlockDay = () => {
    setProgram([Program.DAY, Program.NIGHT]);
  };

  useEffect(() => {
    setActivities(
      props.type === "day" ? actualDay.day : actualDay.evening.minidisco
    );
  }, [actualDay.day, actualDay.evening, props.type]);

  const { fetchActualDay } = UseDays();

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    let _map = reorderMap(result, activities);
    setActivities(_map);

    if (props.type === "day") {
      fetchActualDay(
        { day: _map, evening: actualDay.evening },
        props.numberDay,
        props.weekIndex,
        true
      );
    } else {
      fetchActualDay(
        {
          day: actualDay.day,
          evening: {
            minidisco: _map,
            showTime: actualDay.evening.showTime,
            lateNight: actualDay.evening.lateNight,
          },
        },
        props.numberDay,
        props.weekIndex,
        true
      );
    }
  };

  const handleDragEndShowtime = (result: DropResult) => {
    if (!result.destination) return;

    fetchActualDay(
      {
        day: actualDay.day,
        evening: {
          minidisco: actualDay.evening.minidisco,
          showTime: reorderMap(result, actualDay.evening.showTime),
          lateNight: actualDay.evening.lateNight,
        },
      },
      props.numberDay,
      props.weekIndex,
      true
    );
  };

  const handleDragEndLateNight = (result: DropResult) => {
    if (!result.destination) return;

    fetchActualDay(
      {
        day: actualDay.day,
        evening: {
          minidisco: actualDay.evening.minidisco,
          showTime: actualDay.evening.showTime,
          lateNight: reorderMap(result, actualDay.evening.lateNight),
        },
      },
      props.numberDay,
      props.weekIndex,
      true
    );
  };

  const [dayCount, setDayCount] = useState(0);
  const [eveningCount, setEveningCount] = useState(0);

  useEffect(() => {
    setDayCount(getActivitiesCount(actualDay.day));
    setEveningCount(
      getActivitiesCount(actualDay.evening.minidisco) +
        getActivitiesCount(actualDay.evening.showTime) +
        getActivitiesCount(actualDay.evening.lateNight)
    );
    // eslint-disable-next-line
  }, []);

  const style = getTimelineSummaryStyles(props.type);

  return (
    <div
      className={style.containerStyle}
      style={{
        borderRadius:
          !program.includes(Program.NIGHT) || !program.includes(Program.DAY)
            ? 20
            : "none",
        height: !isDay && props.type !== "day" ? 343 : "100%",
      }}
    >
      {props.type === "day" ? (
        <div className="timeline-navbar">
          <p className="navbar-title">{getTitle(props.numberDay)}</p>
        </div>
      ) : (
        !program.includes(Program.DAY) &&
        props.type === "night" && (
          <div className="timeline-navbar">
            <p className="navbar-title">{getTitle(props.numberDay)}</p>
          </div>
        )
      )}
      <p className={style.timelineTitle}>{props.title}</p>
      {(!program.includes(Program.DAY) && props.type === "day") ||
      (!program.includes(Program.NIGHT) && props.type === "night") ? (
        <Button className="unlock-button" onClick={handleUnlockDay}>
          <FontAwesomeIcon icon={faUnlockAlt} color="white" size="1x" />
          <p className="unlock-text">{UNLOCK}</p>
        </Button>
      ) : (
        <div className="margin">
          <div>
            {props.type !== Program.DAY && (
              <div className="category-title">
                <p>{MINIDISCO}</p>
                <div />
              </div>
            )}
            <div className={style.columnsTimeline}>
              <DragAndDropTimes
                type={props.type}
                activities={activities}
                handleDragEnd={handleDragEnd}
              />
            </div>
            {actualDay.evening.showTime.size > 0 && props.type !== Program.DAY && (
              <div>
                <div className="category-title">
                  <p>{SHOWTIME}</p>
                  <div />
                </div>
                <div className="columns-timeline-sum">
                  <DragAndDropTimes
                    type={props.type}
                    activities={actualDay.evening.showTime}
                    handleDragEnd={handleDragEndShowtime}
                  />
                </div>
              </div>
            )}
            {actualDay.evening.lateNight.size > 0 &&
              props.type !== Program.DAY && (
                <div>
                  <div className="category-title">
                    <p>{LATE_NIGHT}</p>
                    <div />
                  </div>
                  <div className="columns-timeline-sum">
                    {actualDay.evening.lateNight && (
                      <DragAndDropTimes
                        type={props.type}
                        activities={actualDay.evening.lateNight}
                        handleDragEnd={handleDragEndLateNight}
                      />
                    )}
                  </div>
                </div>
              )}
          </div>
          {props.type === Program.DAY && (
            <ActivityCounter
              activitiesCount={dayCount}
              totalCount={activities.size}
              dayOrNight={Program.DAY}
            />
          )}

          {props.type === Program.NIGHT && (
            <ActivityCounter
              activitiesCount={eveningCount}
              totalCount={
                activities.size +
                actualDay.evening.showTime.size +
                (actualDay.evening.lateNight
                  ? actualDay.evening.lateNight.size
                  : 0)
              }
              dayOrNight={Program.NIGHT}
            />
          )}
          {props.type === Program.DAY && program.includes(Program.NIGHT) && (
            <div className="arrow-down">
              <ArrowIcon />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TimeLineSummary;
