export const CongratsIcon = () => {
	return (
		<svg
			id="check"
			xmlns="http://www.w3.org/2000/svg"
			width="40.873"
			height="40.873"
			viewBox="0 0 40.873 40.873"
		>
			<g id="Group_94" data-name="Group 94" transform="translate(9.705 5.135)">
				<g id="Group_93" data-name="Group 93">
					<path
						id="Path_30"
						data-name="Path 30"
						d="M151.567,64.78a1.54,1.54,0,0,0-2.178,0l-18.48,18.431-6.667-7.242a1.54,1.54,0,1,0-2.267,2.087l7.753,8.42a1.539,1.539,0,0,0,1.1.5h.033a1.542,1.542,0,0,0,1.088-.449l19.615-19.563A1.54,1.54,0,0,0,151.567,64.78Z"
						transform="translate(-121.568 -64.327)"
						fill="#68cea8"
					/>
				</g>
			</g>
			<g id="Group_96" data-name="Group 96">
				<g id="Group_95" data-name="Group 95">
					<path
						id="Path_31"
						data-name="Path 31"
						d="M39.332,18.9a1.54,1.54,0,0,0-1.54,1.54A17.355,17.355,0,1,1,20.436,3.081a1.54,1.54,0,1,0,0-3.081A20.436,20.436,0,1,0,40.873,20.436,1.54,1.54,0,0,0,39.332,18.9Z"
						fill="#68cea8"
					/>
				</g>
			</g>
		</svg>
	);
};
