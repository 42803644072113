import React, { useContext, useEffect, useState } from "react";
import "./select-activities.scss";
import {
  ACTIVITY_WAS_NOT_SELECTED,
  DayEvening,
  DAY_NOT_COMPLETED,
  DAY_NOT_COMPLETED_TEXT,
  PROGRAM_COMPLETED,
} from "../../strings/string";
import SelectActivities from "./select-activities";
import { IDay } from "../../models/number-of-day";
import "./section.scss";
import TimeLine from "./activity-timeline";
import { ProgressBar } from "react-bootstrap";
import { UserContext } from "../../context-api/context";
import SnackBar from "./snack-bar";
import { Variant } from "../../enums/snackbar";
import { Days } from "../../enums/activity";
import ModalBack from "../S3-send-order/modal-back";
import { useHistory } from "react-router-dom";
import { Routes } from "../../enums/routes";
import { getActivitiesCount, getPercentage } from "../../utils";

const Section = (props: IDay) => {
  const {
    program,
    dayOne,
    dayTwo,
    dayThree,
    dayFour,
    dayFive,
    daySix,
    daySeven,
    countDays,
  } = useContext(UserContext);

  const [countSelectedActivities, setCountSelectedActivities] = useState(0);
  const [isFullProgram, setIsFullProgram] = useState(false);
  const [dayOff, setDayOff] = useState(false);
  const history = useHistory();

  useEffect(() => {
    switch (props.numberDay) {
      case Days.FIRST:
        props.setPercentage &&
          props.setPercentage([
            countSelectedActivities,
            props.percentage![1],
            props.percentage![2],
            props.percentage![3],
            props.percentage![4],
            props.percentage![5],
            props.percentage![6],
          ]);
        break;
      case Days.SECOND:
        props.setPercentage &&
          props.setPercentage([
            props.percentage![0],
            countSelectedActivities,
            props.percentage![2],
            props.percentage![3],
            props.percentage![4],
            props.percentage![5],
            props.percentage![6],
          ]);
        break;
      case Days.THIRD:
        props.setPercentage &&
          props.setPercentage([
            props.percentage![0],
            props.percentage![1],
            countSelectedActivities,
            props.percentage![3],
            props.percentage![4],
            props.percentage![5],
            props.percentage![6],
          ]);
        break;
      case Days.FOURTH:
        props.setPercentage &&
          props.setPercentage([
            props.percentage![0],
            props.percentage![1],
            props.percentage![2],
            countSelectedActivities,
            props.percentage![4],
            props.percentage![5],
            props.percentage![6],
          ]);
        break;
      case Days.FIFTH:
        props.setPercentage &&
          props.setPercentage([
            props.percentage![0],
            props.percentage![1],
            props.percentage![2],
            props.percentage![3],
            countSelectedActivities,
            props.percentage![5],
            props.percentage![6],
          ]);
        break;
      case Days.SIXTH:
        props.setPercentage &&
          props.setPercentage([
            props.percentage![0],
            props.percentage![1],
            props.percentage![2],
            props.percentage![3],
            props.percentage![4],
            countSelectedActivities,
            props.percentage![6],
          ]);
        break;
      case Days.SEVENTH:
        props.setPercentage &&
          props.setPercentage([
            props.percentage![0],
            props.percentage![1],
            props.percentage![2],
            props.percentage![3],
            props.percentage![4],
            props.percentage![5],
            countSelectedActivities,
          ]);
        break;
      default:
        break;
    }
    // eslint-disable-next-line
  }, [countSelectedActivities, props.numberDay]);

  const [actualDay, setActualDay] = useState(
    props.numberDay === Days.FIRST
      ? dayOne
      : props.numberDay === Days.SECOND
      ? dayTwo
      : props.numberDay === Days.SECOND
      ? dayThree
      : props.numberDay === Days.FOURTH
      ? dayFour
      : props.numberDay === Days.FIFTH
      ? dayFive
      : props.numberDay === Days.SIXTH
      ? daySix
      : daySeven
  );

  const [dayCount, setDayCount] = useState(0);
  // eslint-disable-next-line
  const [eveningCount, setEveningCount] = useState(0);

  useEffect(() => {
    switch (props.numberDay) {
      case Days.FIRST:
        setActualDay(dayOne);
        break;

      case Days.SECOND:
        setActualDay(dayTwo);
        break;

      case Days.THIRD:
        setActualDay(dayThree);
        break;

      case Days.FOURTH:
        setActualDay(dayFour);
        break;

      case Days.FIFTH:
        setActualDay(dayFive);
        break;

      case Days.SIXTH:
        setActualDay(daySix);
        break;

      case Days.SEVENTH:
        setActualDay(daySeven);
        break;
    }
  }, [
    dayOne,
    dayTwo,
    dayThree,
    dayFour,
    dayFive,
    daySix,
    daySeven,
    props.numberDay,
  ]);

  useEffect(() => {
    setCountSelectedActivities(
      getPercentage(
        dayOff,
        program,
        dayCount,
        eveningCount,
        actualDay.evening.minidisco.size +
          actualDay.evening.showTime.size +
          actualDay.evening.lateNight.size,
        actualDay.day.size
      )
    );
  }, [actualDay, dayCount, eveningCount, program, dayOff]);

  useEffect(() => {
    setDayCount(getActivitiesCount(actualDay.day));
  }, [
    dayOne,
    dayTwo,
    dayThree,
    dayFour,
    dayFive,
    daySix,
    daySeven,
    actualDay.day,
  ]);

  useEffect(() => {
    setEveningCount(
      getActivitiesCount(actualDay.evening.minidisco) +
        getActivitiesCount(actualDay.evening.showTime) +
        getActivitiesCount(actualDay.evening.lateNight)
    );
  }, [dayOne, dayTwo, dayThree, actualDay.evening]);

  const [openModal, setIsOpenModal] = useState(false);

  useEffect(() => {
    props.clickedButton && setIsOpenModal(true);
  }, [props.clickedButton]);

  useEffect(() => {
    countSelectedActivities === 0 &&
      props.clickedButton &&
      setTimeout(() => {
        props.setClickedButton && props.setClickedButton(false);
      }, 3000);
    // eslint-disable-next-line
  }, [props.clickedButton, countSelectedActivities]);

  useEffect(() => {
    openModal === false &&
      props.setClickedButton &&
      props.setClickedButton(false);
    // eslint-disable-next-line
  }, [openModal]);

  useEffect(() => {
    let _zeroFound = false;
    props.percentage?.forEach((value, index) => {
      if (index + 1 <= countDays && !_zeroFound) {
        if (value <= 100 && value > 0) {
          //counter++;
          setIsFullProgram(true);
        } else {
          _zeroFound = true;
          setIsFullProgram(false);
        }
      }
    });
    // eslint-disable-next-line
  }, [props.percentage]);

  useEffect(() => {
    const summ = props.percentage?.reduce(
      (previousValue, currentValue) => previousValue + currentValue
    );

    if (summ === countDays * 100) {
      props.setIsProgramDone!(true);
      props.setOpenAddWeekModal!(true);
    } else {
      props.setIsProgramDone!(false);
    }

    // eslint-disable-next-line
  }, [props.percentage]);

  const daysMap = DayEvening.map((item) => (
    <div
      key={item.title_section}
      className={
        item.type === "day"
          ? "timeline-activities-row"
          : "timeline-activities-row-evening"
      }
    >
      <TimeLine
        title={item.title_section}
        type={item.type}
        numberDay={props.numberDay}
        dayOff={dayOff}
        setDayOff={setDayOff}
      />
      <SelectActivities
        title={item.title_section}
        type={item.type}
        numberDay={props.numberDay}
        actualDay={actualDay}
        dayOff={dayOff}
      />
    </div>
  ));

  return (
    <div>
      <p className="single-day-title">{props.day}</p>
      <div className="progress-bar-section">
        <div>
          <ProgressBar now={countSelectedActivities} label={`${60}%`} srOnly />

          <p className="percentage">
            {countSelectedActivities ? countSelectedActivities.toFixed(1) : 0}%
          </p>
        </div>
      </div>

      <div className="select-activities-section">{daysMap}</div>

      {countSelectedActivities === 100 && (
        <SnackBar variant={Variant.SUCCESS} text={PROGRAM_COMPLETED} />
      )}
      {props.clickedButton && countSelectedActivities === 0 && (
        <SnackBar variant={Variant.ERROR} text={ACTIVITY_WAS_NOT_SELECTED} />
      )}

      {props.clickedButton && isFullProgram && (
        <ModalBack
          title={DAY_NOT_COMPLETED}
          text={DAY_NOT_COMPLETED_TEXT}
          openModal={openModal}
          setIsOpenModal={setIsOpenModal}
          answearLeft="Späť na výber"
          answearRight="Dokončiť objednávku"
          link="/send-order"
        />
      )}
      {props.clickedButton &&
        props.isProgramDone &&
        history.push(Routes.SEND_ORDER)}
    </div>
  );
};

// Section.whyDidYouRender = true;
export default Section;
