import React from "react";
import Shimmer from "react-shimmer-effect";
import "./activity-card.scss";

const ReactShimmer = () => {
  return (
    <Shimmer>
      <div className="activity-card-container">
        <div>
          <div className="check-activity">
            <div className="button-check"></div>
            <p className="title-activity" />
          </div>
        </div>
        <div className="description-box">
          <p className="description-activity" />
        </div>
        <div className="more-info-container">
          <div>
            <div className="more-info" />
            <p className="more-info-text" />
          </div>
        </div>
      </div>
    </Shimmer>
  );
};

export default ReactShimmer;
