import React, { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../../context-api/context";
import "./date-start.scss";
import "react-datepicker/dist/react-datepicker.css";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SnackBarAlert from "../S2-select-activities/snack-bar";
import { Variant } from "../../enums/snackbar";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import calendar from "./../../assets/calendar.svg";
import {
  BEGINNING_DATE,
  SNACKBAR_OFFER_ACTUALIZED,
} from "../../strings/string";
import UseDays from "../../custom-hooks/use-days";

const DateStart = () => {
  const { dateStart, setDateStart, counter } = useContext(UserContext);
  const { setEmptyTimeline } = UseDays();

  const [isVisible, setIsVisible] = useState(false);

  const handleChangeDate = async (date: any) => {
    setDateStart(date);
    setIsVisible(true);
    setTimeout(() => {
      setIsVisible(false);
    }, 3000);
  };

  const didMount = useRef(false);

  useEffect(() => {
    didMount.current ? setEmptyTimeline(counter) : (didMount.current = true);
    // eslint-disable-next-line
  }, [dateStart]);

  const showSnacBarAlert = isVisible && (
    <SnackBarAlert variant={Variant.SUCCESS} text={SNACKBAR_OFFER_ACTUALIZED} />
  );

  return (
    <div>
      {showSnacBarAlert}

      <div className="date-start-block">
        <p className="count-title">{BEGINNING_DATE}</p>

        <div className="date-container">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container>
              <img src={calendar} alt="calendar" />
              <DatePicker
                value={dateStart}
                onChange={handleChangeDate}
                variant="inline"
                disableToolbar
                minDate={new Date()}
                maxDate={new Date().setFullYear(new Date().getFullYear() + 1)}
                format="dd.MM.yyyy"
                InputProps={{
                  disableUnderline: true,
                }}
              />
              <FontAwesomeIcon icon={faCaretDown} color="#56576f" size="1x" />
            </Grid>
          </MuiPickersUtilsProvider>
        </div>
      </div>
    </div>
  );
};

export default DateStart;
