import React from "react";
import { Button, Modal } from "react-bootstrap";
import { Collection } from "../../enums/collections";
import { db } from "../../firebase/firebase-init";
import { CLOSE_MODAL, DELETE, REMOVE_ACTIVITY } from "../../strings/string";

interface Props {
  show: boolean;
  handleClose: () => void;
  activityId?: string;
}

const ModalRemoveActivity = ({ show, handleClose, activityId }: Props) => {
  const remove = () => {
    db.collection(Collection.ACTIVITIES)
      .doc(activityId)
      .delete()
      .then(() => handleClose());
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className="dashboard-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>{REMOVE_ACTIVITY}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Chystáte sa odstrániť aktivitu č. <b>{activityId}</b>. Ste si istý, že
        ju chcete odstrániť?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          {CLOSE_MODAL}
        </Button>
        <Button variant="primary" onClick={remove}>
          {DELETE}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalRemoveActivity;
