// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import firebase from "firebase/app";

// Add the Firebase services that you want to use
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

import { devFirebaseConfig, firebaseConfig } from "./firebase-config";

const ENVIRONMENT = process.env.REACT_APP_STAGE;

const isDevelopment = ENVIRONMENT === "development";
const firebaseConfiguration = isDevelopment
  ? devFirebaseConfig
  : firebaseConfig;

firebase.initializeApp(firebaseConfiguration);

export const db = firebase.firestore();

export const storage = firebase.storage().ref();

export const auth = firebase.auth();

export const googleProvider = new firebase.auth.GoogleAuthProvider();
