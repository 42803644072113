import React from "react";
import "./first-screen.scss";
import { STEP_ONE_TITLE, SUBTITLE } from "../../strings/string";
import BasicSelection from "./basic-selection";

const FirstScreen = () => {
	return (
		<div className="first-screen-layout">
			<p className="main-title">{STEP_ONE_TITLE}</p>
			<p className="subtitle">{SUBTITLE}</p>
			<BasicSelection />
		</div>
	);
};

export default FirstScreen;
