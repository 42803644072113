import { AppBar, IconButton, Toolbar, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useHistory } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";

interface Props {
  drawerWidth: number;
  handleDrawerToggle: () => void;
}

const DashboardAppBar = ({ drawerWidth, handleDrawerToggle }: Props) => {
  const history = useHistory();

  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: "#eee",
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        ml: { sm: `${drawerWidth}px` },
      }}
    >
      <Toolbar>
        <IconButton
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { sm: "none" }, color: "#111" }}
        >
          <MenuIcon />
        </IconButton>
        <div className="dashboard-top-menu">
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ color: "#111" }}
          >
            dashboard
          </Typography>
          <div style={{ display: "flex", alignItems: "center" }}>
            <HomeIcon
              sx={{ color: "#111" }}
              onClick={() => history.push("/")}
            />
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default DashboardAppBar;
