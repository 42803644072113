import React from "react";
import { Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import { DashboardRoutes, Routes } from "../enums/routes";
import Activities from "./activities/activities";
import Admins from "./admins/admins";
import Combinations from "./combinations/combinations";
import DashboardIndex from "./dashboard-index";
import OrderDetail from "./orders/order-detail/order-detail";
import Orders from "./orders/orders";
import UserList from "./user-list/user-list";

const DashboardRouter = () => {
  let { path } = useRouteMatch();
  const location = useLocation();

  return (
    <>
      {!location.pathname.includes(`${Routes.DASHBOARD}/`) && (
        <DashboardIndex />
      )}
      <Switch>
        <Route path={`${path}/${DashboardRoutes.ADMINISTRATORS}`}>
          <Admins />
        </Route>
        <Route path={`${path}/${DashboardRoutes.ORDERS}/:orderId`}>
          <OrderDetail />
        </Route>
        <Route path={`${path}/${DashboardRoutes.ORDERS}`}>
          <Orders />
        </Route>
        <Route path={`${path}/${DashboardRoutes.USERS}`}>
          <UserList />
        </Route>
        <Route path={`${path}/${DashboardRoutes.ACTIVITIES}`}>
          <Activities />
        </Route>
        <Route path={`${path}/${DashboardRoutes.COMBINATIONS}`}>
          <Combinations />
        </Route>
      </Switch>
    </>
  );
};

export default DashboardRouter;
