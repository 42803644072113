import React from "react";
import Shimmer from "react-shimmer-effect";
import "./tags.scss";

const TagShimmer = () => {
  return (
    <Shimmer>
      <div className="tags-container tags-shimmer">
        <div className="single-tag single-tag-shimmer">
          <p className="tag-title" />
        </div>
      </div>
    </Shimmer>
  );
};

export default TagShimmer;
