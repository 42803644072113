import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { DashboardRoutes, Routes } from "../../enums/routes";
import { IActiveUsers } from "../../models/user-list";
import { USERS } from "../../strings/string";

const UsersCard = () => {
  const [usersCount, setUsersCount] = useState(0);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/getAllUsers`)
      .then((response) => response.json())
      .then((data: IActiveUsers) => setUsersCount(data.users.length))
      .catch((err) => console.log(err));
    // eslint-disable-next-line
  }, []);

  return (
    <Link to={`${Routes.DASHBOARD}/${DashboardRoutes.USERS}`}>
      <div className="dashboard-card orange-gradient">
        <h3>{USERS}</h3>
        <p>{usersCount}</p>
      </div>
    </Link>
  );
};

export default UsersCard;
