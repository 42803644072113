import { faCheckCircle, faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import { UserContext } from "../../context-api/context";
import { Program } from "../../enums/activity";
import { DAILY, EVENING } from "../../strings/string";

type Props = {
  handleChange: () => void;
  dayButton?: boolean;
};

const DisableTimeButton = ({ handleChange, dayButton = true }: Props) => {
  const { program } = useContext(UserContext);

  const getProgramType = program.includes(
    dayButton ? Program.DAY : Program.NIGHT
  );

  return (
    <div
      onClick={handleChange}
      className={getProgramType ? "day-active" : "day"}
    >
      <FontAwesomeIcon
        icon={getProgramType ? faCheckCircle : faCircle}
        color="white"
        size="2x"
      />

      <p>{dayButton ? DAILY : EVENING}</p>
    </div>
  );
};

export default DisableTimeButton;
