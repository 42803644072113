import React, { Suspense, useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import { UserContext } from "../src/context-api/context";
import { EmptyCards } from "./strings/empty-cards";
import "./firebase/firebase-init";
import { Program } from "./enums/activity";
import { IUser } from "./models/user";
import { auth, db } from "./firebase/firebase-init";
import { Collection } from "./enums/collections";
import Layout from "./components/layout";
import { IProgram, IWeek } from "./models/program";
import { IDayMap } from "./models/day-map";
import { createEmptyDay, createStartDate, toSessionStorageDay } from "./utils";

const App = () => {
  const [counter, setCounter] = useState(
    Number(sessionStorage.getItem("animators") || 1)
  );
  const [countDays, setCountDays] = useState(0);
  const [program, setProgram] = useState<Program[]>([
    Program.DAY,
    Program.NIGHT,
  ]);
  const [inTimeLine, setInTimeline] = useState(EmptyCards);
  const [dateStart, setDateStart] = useState<Date>(createStartDate());
  const [isRemovedCard, setIsRemovedCard] = useState(null);
  const [isShowed, setIsShowed] = useState(false);
  const [isDay, setIsDay] = useState(false);
  const [user, setUser] = useState<IUser | null>(null);
  const [isFetchingUser, setIsFetchingUser] = useState(true);
  const [orderId, setOrderId] = useState<string>();
  const [schedule, setSchedule] = useState<IWeek[]>([]);
  const [dayOne, setDayOne] = useState<IProgram>(createEmptyDay());
  const [dayTwo, setDayTwo] = useState<IProgram>(createEmptyDay());
  const [dayThree, setDayThree] = useState<IProgram>(createEmptyDay());
  const [dayFour, setDayFour] = useState<IProgram>(createEmptyDay());
  const [dayFive, setDayFive] = useState<IProgram>(createEmptyDay());
  const [daySix, setDaySix] = useState<IProgram>(createEmptyDay());
  const [daySeven, setDaySeven] = useState<IProgram>(createEmptyDay());
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [company, setCompany] = useState("");
  const [mail, setMail] = useState("");
  const [phone, setPhone] = useState("");
  const [event, setEvent] = useState("");
  const [price, setPrice] = useState(0);
  const [sessionLoaded, setSessionLoaded] = useState(false);

  const sendToStorage = () => {
    sessionStorage.setItem("animators", counter.toString());
    sessionStorage.setItem("days", countDays.toString());
    sessionStorage.setItem("price", price.toString());
    sessionStorage.setItem("date", dateStart.toString());

    sessionStorage.setItem("program", JSON.stringify(program));
    sessionStorage.setItem("dayOne", toSessionStorageDay(dayOne));
    sessionStorage.setItem("dayTwo", toSessionStorageDay(dayTwo));
    sessionStorage.setItem("dayThree", toSessionStorageDay(dayThree));
    sessionStorage.setItem("dayFour", toSessionStorageDay(dayFour));
    sessionStorage.setItem("dayFive", toSessionStorageDay(dayFive));
    sessionStorage.setItem("daySix", toSessionStorageDay(daySix));
    sessionStorage.setItem("daySeven", toSessionStorageDay(daySeven));

    if (schedule.length > 0) {
      const _sessionStorageSchedule: any = [];

      schedule.forEach((week) =>
        _sessionStorageSchedule.push(
          week.map((day) => toSessionStorageDay(day))
        )
      );

      sessionStorage.setItem(
        "schedule",
        JSON.stringify(_sessionStorageSchedule)
      );
    }
  };

  const getFromStorage = () => {
    const _price = Number(sessionStorage.getItem("price"));
    _price && setPrice(_price);

    const _days = Number(sessionStorage.getItem("days"));
    _days && setCountDays(_days);

    const _program = JSON.parse(sessionStorage.getItem("program")!);

    if (_program && !_program.includes("day")) {
      setProgram([Program.NIGHT]);
    } else if (_program && !_program.includes("night")) {
      setProgram([Program.DAY]);
    } else {
      setProgram([Program.DAY, Program.NIGHT]);
    }

    const _dayOne = JSON.parse(sessionStorage.getItem("dayOne")!);
    const _dayTwo = JSON.parse(sessionStorage.getItem("dayTwo")!);
    const _dayThree = JSON.parse(sessionStorage.getItem("dayThree")!);
    const _dayFour = JSON.parse(sessionStorage.getItem("dayFour")!);
    const _dayFive = JSON.parse(sessionStorage.getItem("dayFive")!);
    const _daySix = JSON.parse(sessionStorage.getItem("daySix")!);
    const _daySeven = JSON.parse(sessionStorage.getItem("daySeven")!);

    const _sessionStorageSchedule: string[][] = JSON.parse(
      sessionStorage.getItem("schedule")!
    );

    _sessionStorageSchedule &&
      setSchedule(
        _sessionStorageSchedule.map((week) =>
          week.map((day) => {
            const _arrayDay = JSON.parse(day);
            return {
              day: new Map(_arrayDay.day),
              evening: {
                minidisco: new Map(_arrayDay.evening.minidisco),
                showTime: new Map(_arrayDay.evening.showTime),
                lateNight: new Map(_arrayDay.evening.lateNight),
              },
            };
          })
        )
      );

    if (_dayOne) {
      const _day = new Map(_dayOne.day);
      const _minidisco = new Map(_dayOne.evening.minidisco);
      const _showTime = new Map(_dayOne.evening.showTime);
      const _lateNight = new Map(_dayOne.evening.lateNight);
      setDayOne({
        day: _day as Map<string, IDayMap>,
        evening: {
          minidisco: _minidisco as Map<string, IDayMap>,
          showTime: _showTime as Map<string, IDayMap>,
          lateNight: _lateNight as Map<string, IDayMap>,
        },
      });
    }
    if (_dayTwo) {
      const _day = new Map(_dayTwo.day);
      const _minidisco = new Map(_dayTwo.evening.minidisco);
      const _showTime = new Map(_dayTwo.evening.showTime);
      const _lateNight = new Map(_dayTwo.evening.lateNight);
      setDayTwo({
        day: _day as Map<string, IDayMap>,
        evening: {
          minidisco: _minidisco as Map<string, IDayMap>,
          showTime: _showTime as Map<string, IDayMap>,
          lateNight: _lateNight as Map<string, IDayMap>,
        },
      });
    }

    if (_dayThree) {
      const _day = new Map(_dayThree.day);
      const _minidisco = new Map(_dayThree.evening.minidisco);
      const _showTime = new Map(_dayThree.evening.showTime);
      const _lateNight = new Map(_dayThree.evening.lateNight);
      setDayThree({
        day: _day as Map<string, IDayMap>,
        evening: {
          minidisco: _minidisco as Map<string, IDayMap>,
          showTime: _showTime as Map<string, IDayMap>,
          lateNight: _lateNight as Map<string, IDayMap>,
        },
      });
    }

    if (_dayFour) {
      const _day = new Map(_dayFour.day);
      const _minidisco = new Map(_dayFour.evening.minidisco);
      const _showTime = new Map(_dayFour.evening.showTime);
      const _lateNight = new Map(_dayFour.evening.lateNight);
      setDayFour({
        day: _day as Map<string, IDayMap>,
        evening: {
          minidisco: _minidisco as Map<string, IDayMap>,
          showTime: _showTime as Map<string, IDayMap>,
          lateNight: _lateNight as Map<string, IDayMap>,
        },
      });
    }

    if (_dayFive) {
      const _day = new Map(_dayFive.day);
      const _minidisco = new Map(_dayFive.evening.minidisco);
      const _showTime = new Map(_dayFive.evening.showTime);
      const _lateNight = new Map(_dayFive.evening.lateNight);
      setDayFive({
        day: _day as Map<string, IDayMap>,
        evening: {
          minidisco: _minidisco as Map<string, IDayMap>,
          showTime: _showTime as Map<string, IDayMap>,
          lateNight: _lateNight as Map<string, IDayMap>,
        },
      });
    }

    if (_daySix) {
      const _day = new Map(_daySix.day);
      const _minidisco = new Map(_daySix.evening.minidisco);
      const _showTime = new Map(_daySix.evening.showTime);
      const _lateNight = new Map(_daySix.evening.lateNight);
      setDaySix({
        day: _day as Map<string, IDayMap>,
        evening: {
          minidisco: _minidisco as Map<string, IDayMap>,
          showTime: _showTime as Map<string, IDayMap>,
          lateNight: _lateNight as Map<string, IDayMap>,
        },
      });
    }

    if (_daySeven) {
      const _day = new Map(_daySeven.day);
      const _minidisco = new Map(_daySeven.evening.minidisco);
      const _showTime = new Map(_daySeven.evening.showTime);
      const _lateNight = new Map(_daySeven.evening.lateNight);
      setDaySeven({
        day: _day as Map<string, IDayMap>,
        evening: {
          minidisco: _minidisco as Map<string, IDayMap>,
          showTime: _showTime as Map<string, IDayMap>,
          lateNight: _lateNight as Map<string, IDayMap>,
        },
      });
    }

    setSessionLoaded(true);
  };

  useEffect(() => {
    if (sessionLoaded) {
      sendToStorage();
    }
    // eslint-disable-next-line
  }, [
    dayOne,
    dayTwo,
    dayThree,
    dayFour,
    dayFive,
    daySix,
    daySeven,
    program,
    dateStart,
    counter,
    countDays,
    schedule,
  ]);

  useEffect(() => {
    getFromStorage();
  }, []);

  useEffect(() => {
    auth &&
      auth.onAuthStateChanged((userAuth) => {
        if (userAuth && userAuth.email) {
          db.collection(Collection.ADMINS)
            .where("email", "==", userAuth.email)
            .onSnapshot((snapshot) => {
              setUser({
                name: userAuth?.displayName
                  ? userAuth.displayName
                  : userAuth.email!,
                isAdmin: snapshot.empty ? false : true,
                iconUri: userAuth.photoURL!,
              });
              setIsFetchingUser(false);
            });
        } else {
          setUser(null);
          setIsFetchingUser(false);
        }
      });
    // eslint-disable-next-line
  }, []);

  return (
    <BrowserRouter>
      <Suspense fallback="loading">
        <UserContext.Provider
          value={{
            countDays,
            setCountDays,
            counter,
            setCounter,
            program,
            setProgram,
            inTimeLine,
            setInTimeline,
            dateStart,
            setDateStart,
            isRemovedCard,
            setIsRemovedCard,
            dayOne,
            setDayOne,
            dayTwo,
            setDayTwo,
            dayThree,
            setDayThree,
            dayFour,
            setDayFour,
            dayFive,
            setDayFive,
            daySix,
            setDaySix,
            daySeven,
            setDaySeven,
            isShowed,
            setIsShowed,
            isDay,
            setIsDay,
            name,
            setName,
            surname,
            setSurname,
            company,
            setCompany,
            mail,
            setMail,
            phone,
            setPhone,
            event,
            setEvent,
            price,
            setPrice,
            user,
            setUser,
            isFetchingUser,
            setIsFetchingUser,
            orderId,
            setOrderId,
            schedule,
            setSchedule,
          }}
        >
          <Layout />
        </UserContext.Provider>
      </Suspense>
    </BrowserRouter>
  );
};

export default App;
