import React from "react";

export const PdfIcon = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="47.35"
        height="60.511"
        viewBox="0 0 47.35 60.511"
      >
        <g id="pdf" transform="translate(-49.23 11.021) rotate(-7)">
          <path
            id="Path_217"
            data-name="Path 217"
            d="M83.427,11.58a4.539,4.539,0,0,1-4.51-4.51V0H51.185A1.614,1.614,0,0,0,49.6,1.585V54.366a1.614,1.614,0,0,0,1.585,1.585H88.912a1.561,1.561,0,0,0,1.524-1.585V11.58ZM62.765,28.219a4.339,4.339,0,0,1-3.047.853H57.706v3.108c0,1.828-2.194,1.828-2.194,0V23.77c0-.975.427-1.341,1.4-1.341a29.829,29.829,0,0,1,4.754.183C64.35,23.343,64.35,26.939,62.765,28.219Zm12.068,1.4a4.2,4.2,0,0,1-3.535,3.6l-4.205.122c-1.158,0-1.4-.488-1.4-1.585V23.77c0-.975.427-1.341,1.341-1.341a24.888,24.888,0,0,1,4.815.183C74.65,23.343,75.442,26.878,74.833,29.621Zm9.386-5.729a1.034,1.034,0,0,1-.792.244H79.1v2.8h3.657c1.341,0,1.341,1.646,0,1.646H79.1v3.6c0,1.585-1.4,1.585-1.889,1.036a1.743,1.743,0,0,1-.3-1.036V23.77c0-.975.427-1.341,1.341-1.341h5.181C84.646,22.429,84.646,23.526,84.219,23.892Z"
            transform="translate(0 0)"
            fill="#70399c"
          />
          <path
            id="Path_218"
            data-name="Path 218"
            d="M104.324,158h-1.463v3.352H102.8c1.585,0,3.9.3,3.9-1.707C106.7,158.3,105.786,158,104.324,158Z"
            transform="translate(-45.095 -133.925)"
            fill="#70399c"
          />
          <path
            id="Path_219"
            data-name="Path 219"
            d="M171.063,159.2H169.6v7.375a13.257,13.257,0,0,0,3.169-.183c2.072-.731,1.95-4.754,1.219-5.973C173.318,159.322,172.343,159.2,171.063,159.2Z"
            transform="translate(-101.715 -134.942)"
            fill="#70399c"
          />
          <path
            id="Path_220"
            data-name="Path 220"
            d="M261.089,19.409h5.12L259.2,12.4v5.12A1.86,1.86,0,0,0,261.089,19.409Z"
            transform="translate(-177.664 -10.51)"
            fill="#70399c"
          />
        </g>
      </svg>
    </div>
  );
};
