import { createContext } from "react";
import { IUserContext } from "../models/user-context";

export const UserContext = createContext<IUserContext>({
  counter: 1,
  setCounter: () => {},
  countDays: 1,
  setCountDays: () => {},
  program: [],
  setProgram: () => {},
  inTimeLine: [],
  setInTimeline: () => {},
  dayOne: {
    day: new Map(),
    evening: {
      minidisco: new Map(),
      showTime: new Map(),
      lateNight: new Map(),
    },
  },
  setDayOne: () => {},
  dayTwo: {
    day: new Map(),
    evening: {
      minidisco: new Map(),
      showTime: new Map(),
      lateNight: new Map(),
    },
  },
  setDayTwo: () => {},
  dayThree: {
    day: new Map(),
    evening: {
      minidisco: new Map(),
      showTime: new Map(),
      lateNight: new Map(),
    },
  },
  setDayThree: () => {},
  dayFour: {
    day: new Map(),
    evening: {
      minidisco: new Map(),
      showTime: new Map(),
      lateNight: new Map(),
    },
  },
  setDayFour: () => {},
  dayFive: {
    day: new Map(),
    evening: {
      minidisco: new Map(),
      showTime: new Map(),
      lateNight: new Map(),
    },
  },
  setDayFive: () => {},
  daySix: {
    day: new Map(),
    evening: {
      minidisco: new Map(),
      showTime: new Map(),
      lateNight: new Map(),
    },
  },
  setDaySix: () => {},
  daySeven: {
    day: new Map(),
    evening: {
      minidisco: new Map(),
      showTime: new Map(),
      lateNight: new Map(),
    },
  },
  setDaySeven: () => {},
  dateStart: new Date(),
  setDateStart: () => {},
  isRemovedCard: null,
  setIsRemovedCard: () => {},
  isShowed: false,
  setIsShowed: () => {},
  isDay: false,
  setIsDay: () => {},
  name: "",
  setName: () => {},
  surname: "",
  setSurname: () => {},
  company: "",
  setCompany: () => {},
  mail: "",
  setMail: () => {},
  phone: "",
  setPhone: () => {},
  event: "",
  setEvent: () => {},
  price: 0,
  setPrice: () => {},
  user: null,
  setUser: () => {},
  isFetchingUser: true,
  setIsFetchingUser: () => {},
  orderId: undefined,
  setOrderId: () => {},
  schedule: [],
  setSchedule: () => {},
});
