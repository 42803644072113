export const HowManyDays = [
  { day: "Program na 1. deň", numberDay: "first" },
  { day: "Program na 2. deň", numberDay: "second" },
  { day: "Program na 3. deň", numberDay: "third" },
  { day: "Program na 4. deň", numberDay: "fourth" },
  { day: "Program na 5. deň", numberDay: "fifth" },
  { day: "Program na 6. deň", numberDay: "sixth" },
  { day: "Program na 7. deň", numberDay: "seventh" },
];

export const HowManyDaysShimmer = [
  { day: "Program na 1. deň", numberDay: "first" },
];

export const DayEvening = [
  {
    title_section: "Denný program",
    type: "day",
  },
  {
    title_section: "Večerný program",
    type: "night",
  },
];

export const SingleDay = [
  {
    title_section: "Denný program",
    type: "day",
  },
];

export const SingleEvening = [
  {
    title_section: "Večerný program",
    type: "night",
  },
];

export const FORM = [
  { label: "Meno", type: "name" },
  { label: "Priezvisko", type: "surname" },
  { label: "Firma (voliteľné)", type: "company" },
  { label: "E-mail", type: "email" },
  { label: "Tel.Číslo", type: "phone" },
  { label: "Miesto eventu", type: "place" },
];

export const SNACKBAR_WRONG_EVENT = "Nesprávny formát miesta eventu.";
export const SNACKBAR_WRONG_SURNAME = "Nesprávny formát priezviska.";
export const SNACKBAR_WRONG_NAME = "Nesprávny formát mena.";
export const SNACKBAR_WRONG_NUMBER = "Nesprávny formát čísla.";
export const SNACKBAR_WRONG_EMAIL = "Nesprávny formát e-mailu.";
export const SNACKBAR_EMPTY_INPUT = "Vyplňte prosím povinné polia.";
export const SNACKBAR_FORM_SUCCESS = "Formulár bol úspešne odoslaný.";
export const SNACKBAR_OFFER_ACTUALIZED = "Ponuka bola aktualizovaná.";
export const SNACKBAR_DEACTIVATE_DAY = "Deaktivovali ste denný program.";
export const SNACKBAR_DEACTIVATE_NIGHT = "Deaktivovali ste večerný program.";
export const SNACKBAR_ACTIVATE_DAY = "Odomkli ste denný program.";
export const SNACKBAR_ACTIVATE_NIGHT = "Odomkli ste večerný program.";
export const SNACKBAR_DEACTIVATE_ERROR =
  "Nie je možné deaktivovať denný aj večerný program súčasne.";
export const SNACKBAR_ACTIVITY_ADDED = "Aktivita pridaná.";
export const SNACKBAR_ACTIVITY_REMOVED = "Aktivita odobraná.";
export const SNACKBAR_ACTIVITY_LIMIT =
  "Bol dosiahnutý maximálny počet aktivít.";

export const CLOSE_MODAL = "Zatvoriť";
export const DELETE = "Zmazať";
export const REMOVE_ACTIVITY = "Odstránenie aktivity";
export const REMOVE_ADMIN = "Odstrániť administrátora";
export const ADD_ADMIN = "Pridať administrátora";
export const TIME = "Čas";
export const DAY_PROGRAM = "Deň";
export const LATE_NIGHT = "Late Night";
export const MINIDISCO = "Minidisko";
export const SHOWTIME = "Showtime";
export const UNLOCK = "Odomknúť";
export const NIGHT = "Večerný";
export const DAY = "Denný";
export const BACK = "Späť na výber";
export const BACK_TO_START = "Späť na úvod";
export const CHOSEN_ACTIVITIES = "zvolených aktivít";
export const CLOSE = "Zavrieť";

export const BEGINNING_DATE = "Dátum začatia";
export const CONFIRM_CHOICE = "Potvrdiť výber";
export const CONGRATULATION = "Gratulujeme!";
export const PROGRAM_DONE = "Váš program je hotový.";
export const DONE = "Hotovo!";
export const PDF_DONE = "Môžeme to rozbaliť.";
export const PROGRAM_IN_PDF = "Stiahnuť program v PDF";

export const DAY_PROGRAM_NOT_CHOSEN = "Denný program nie je zvolený";
export const NIGHT_PROGRAM_NOT_CHOSEN = "Večerný program nie je zvolený";

export const DEACTIVATE_DAY_PROGRAM = "Deaktivovať denný program?";
export const ACTIVATE_DAY_PROGRAM = "Odomknúť denný program?";
export const DEACTIVATE_NIGHT_PROGRAM = "Deaktivovať večerný program?";
export const ACTIVATE_NIGHT_PROGRAM = "Odomknúť večerný program?";

export const DAY_MODAL_OFF =
  "Potvrdením vypnete možnosť denného programu. Aktuálný výber vaších aktivít bude stratený. Cena sa zníži.";
export const DAY_MODAL_ON =
  "Odomknutím tohto programu cena sa zvýši. Cena za program sa následne navýši. Denný program môžte kedykoľvek deaktivovať.";
export const NIGHT_MODAL_OFF =
  "Potvrdením vypnete možnosť večerného programu. Aktuálný výber vaších aktivít bude stratený. Cena sa zníži.";
export const NIGHT_MODAL_ON =
  "Odomknutím tohto programu cena sa zvýši. Cena za program sa následne navýši. Večerný program môžte kedykoľvek deaktivovať.";

export const EDIT_TEXT =
  "V tomto kroku si môžete dodatočne upraviť poradie aktivít presúvaním položiek. Odošlite nezáväznú objednávku alebo si stiahnite PDF prehľad programu.";

export const DONE_TEXT =
  "Ďakujeme za váš záujem. Čoskoro vás budeme kontaktovať s potvrdením vašej objednávky a informáciami o vašom evente.";

export const PROGRAM_INFO =
  "Stačí už len odoslať vami vyhotovený program pomocou krátkeho formulára a my sa vám čoskoro ozveme aby sme si dohodli detaily.";

export const FORM_TITLE = " Objednávkový formulár";

export const BUTTON_SEND = "Odoslať nezáväznú objednávku";

export const ANIMATOR_TITLE = "Počet animátorov";

export const DAYS_TITLE = "Počet dní";

export const PRICE_TITLE = "Cena";

export const PROGRAM_TITLE = "Program";

export const STEP_ONE_TITLE = "Konfigurátor zábavy";
export const SUBTITLE = "Zadajte základné požiadavky pre váš program";

export const DAY_ONE = "Program na 1. deň";
export const ACTIVITIES_KIND = "Kategórie :";

export const CONFIRM_SELECTION = "Potvrdiť výber";

export const DATE_START = "Dátum začatia";

export const DAILY = "Denný";

export const EVENING = "Večerný";

export const DOWNLOAD_PDF = "Stiahnite si váš program v PDF";

export const PROGRAM_PDF = "Stiahnuť program v PDF";

export const MORE_INFO = "Viac info";

export const LOGIN = "Prihlásiť";

export const LOGOUT = "Odhlásiť";

export const NEW_WEEK = "Nový týždeň";

export const NEW_WEEK_TEXT =
  "Prajete si do vášho programu pridať ďalší týždeň?";

export const ADD_WEEK = "Pridať týždeň";

export const CONTINUE = "Pokračovať";

export const CATEGORIY = "Kategória";

export const TITLE = "Názov";

export const DESCRIPTION = "Popis";

export const LONG_DESCRIPTION = "Dlhší popis";

export const EDIT_ACTIVITY = "Editácia aktivity";

export const DAY_OFF = "Deň voľna";

export const DAY_NOT_COMPLETED =
  "Váš program nie je vyplnený na 100%. Chcete ho dokončit?";

export const DAY_NOT_COMPLETED_TEXT =
  "Vyberte ďalšie voľné aktivity, alebo dokončite výber.";

export const ACTIVITY_WAS_NOT_SELECTED = "Nebola vybraná ešte žiadna aktivita";

export const PROGRAM_COMPLETED = "Skvelé! Váš program je vyplnený na 100%.";

export const ADD_ACTIVITY = "Pridať aktivitu";

export const PROGRAM = "Program";

export const DEFAULT_INFORMATIONS = "Zakladné údaje";

export const NAME = "Meno:";

export const EMAIL = "E-mail:";

export const PHONE_NUMBER = "Telefónne číslo:";

export const CONFIG = "Konfig";

export const ANIMATOR_COUNT = "Počet animátorov:";

export const DATE_OF_BEGINNING = "Deň začatia:";

export const DAY_COUNT = "Počet dní";

export const BACK_TO_ORDERS = "Späť na objednávky";

export const ARCHERY = "lukostrelba";

export const KEEP_PROGRAM = "Ponechať program";

export const NO_THANKS = "Nie, Ďakujem";

export const DEACTIVATE_PROGRAM = "Deaktivovať program";

export const YES_UNLOCK = "Ano, odomknúť";

export const MONDAY = "Pondelok";
export const TUESDAY = "Utorok";
export const WEDNESDAY = "Streda";
export const THURSDAY = "Štvrtok";
export const FRIDAY = "Piatok";
export const SATURDAY = "Sobota";
export const SUNDAY = "Nedeľa";

export const ADMINS = "Administrátori";
export const ORDERS = "Objednávky";
export const ACTIVITIES = "Aktivity";
export const USERS = "Používatelia";

export const PAGE_NOT_FOUND_TITLE = "Stránka sa nenašla";
export const PAGE_NOT_FOUND_TEXT =
  "Je nám ľúto, stránka, ktorú ste hľadali, sa tu nenachádza. Odkaz, ktorý ste nasledovali, môže byť nefunkčný alebo už neexistuje. Skúste to znova alebo sa pozrite na našu stránku.";

export const Timeline = {
  day: {
    basic: ["10:00", "11:00", "12:00", "14:00", "15:00", "16:00", "17:00"],
    afternoon: ["14:00", "15:00", "16:00", "17:00"],
    afternoonTwoHalf: [
      "14:00",
      "14:30",
      "15:00",
      "15:30",
      "16:00",
      "16:30",
      "17:00",
    ],
    afternoonThreeHalf: [
      "14:00",
      "14:30",
      "15:00",
      "15:30",
      "16:00",
      "16:30",
      "17:00",
      "17:30",
    ],
    animatorsTwo: [
      "10:00",
      "10:30",
      "11:00",
      "11:30",
      "12:00",
      "12:30",
      "14:00",
      "14:30",
      "15:00",
      "15:30",
      "16:00",
      "16:30",
      "17:00",
    ],
    animatorsThree: [
      "09:00",
      "10:00",
      "10:30",
      "11:00",
      "11:30",
      "12:00",
      "12:30",
      "14:00",
      "14:30",
      "15:00",
      "15:30",
      "16:00",
      "16:30",
      "17:00",
      "17:30",
    ],
  },
  evening: {
    minidisco: {
      oneAnimator: ["19:00", "19:30"],
      moreAnimators: ["19:00", "19:30", "20:00"],
    },
    showTime: "20:00",
  },
};
