import React from "react";
import { Program } from "../../enums/activity";
import { CHOSEN_ACTIVITIES } from "../../strings/string";
import "./activity-counter.scss";

interface Props {
  activitiesCount: number;
  totalCount: number;
  dayOrNight: Program;
}

const ActivityCounter = ({
  activitiesCount,
  totalCount,
  dayOrNight,
}: Props) => {
  const counterStyle =
    dayOrNight === Program.DAY
      ? "activities-counter day-color"
      : "activities-counter evening-color";

  const selectedActivitiesStyle =
    dayOrNight === Program.DAY
      ? "selected-activities day-color"
      : "selected-activities evening-color";

  return (
    <div>
      <div className="activities-counter-container">
        <div className={counterStyle}>
          {activitiesCount}/{totalCount}
        </div>
        <p className={selectedActivitiesStyle}>{CHOSEN_ACTIVITIES}</p>
      </div>
    </div>
  );
};

export default ActivityCounter;
