import React, { useContext, useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import "./form.scss";
import {
  FORM_TITLE,
  FORM,
  BUTTON_SEND,
  SNACKBAR_EMPTY_INPUT,
  SNACKBAR_WRONG_EMAIL,
  SNACKBAR_WRONG_NUMBER,
  SNACKBAR_WRONG_NAME,
  SNACKBAR_WRONG_SURNAME,
  SNACKBAR_WRONG_EVENT,
  SNACKBAR_FORM_SUCCESS,
} from "../../strings/string";
import { UserContext } from "../../context-api/context";
import SnackBarAlert from "../S2-select-activities/snack-bar";
import { Variant } from "../../enums/snackbar";
import { db } from "../../firebase/firebase-init";
import { Collection } from "../../enums/collections";
import { useHistory } from "react-router-dom";
import { BEGINNING_DATE } from "../../strings/string";
import { getScheduleDay } from "../../utils";
import { IMultipleWeeksSchedule } from "../../models/firebase-activity";
import { FetchUrl } from "../../enums/fetch-url";

const Formular = () => {
  const {
    dateStart,
    name,
    setName,
    surname,
    setSurname,
    company,
    setCompany,
    mail,
    setMail,
    phone,
    setPhone,
    event,
    setEvent,
    program,
    counter,
    countDays,
    price,
    dayOne,
    dayTwo,
    dayThree,
    dayFour,
    dayFive,
    daySix,
    daySeven,
    setOrderId,
    schedule,
  } = useContext(UserContext);

  const inputs = [name, surname, company, mail, phone, event];

  const history = useHistory();

  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const [badFromat, setBadFormat] = useState(false);
  const [badPhoneFormat, setBadPhoneFormat] = useState(false);
  const [badNameFormat, setBadNameFormat] = useState(false);
  const [badSurNameFormat, setBadSurNameFormat] = useState(false);
  const [badEventFormat, setBadEventFormat] = useState(false);
  const [admins, setAdmins] = useState<string[]>([]);

  const getDays = (): {} | [] | undefined => {
    switch (countDays) {
      case 1:
        return [{ dayOne: getScheduleDay(dayOne) }];

      case 2:
        return [
          {
            dayOne: getScheduleDay(dayOne),
            dayTwo: getScheduleDay(dayTwo),
          },
        ];

      case 3:
        return [
          {
            dayOne: getScheduleDay(dayOne),
            dayTwo: getScheduleDay(dayTwo),
            dayThree: getScheduleDay(dayThree),
          },
        ];

      case 4:
        return [
          {
            dayOne: getScheduleDay(dayOne),
            dayTwo: getScheduleDay(dayTwo),
            dayThree: getScheduleDay(dayThree),
            dayFour: getScheduleDay(dayFour),
          },
        ];

      case 5:
        return [
          {
            dayOne: getScheduleDay(dayOne),
            dayTwo: getScheduleDay(dayTwo),
            dayThree: getScheduleDay(dayThree),
            dayFour: getScheduleDay(dayFour),
            dayFive: getScheduleDay(dayFive),
          },
        ];

      case 6:
        return [
          {
            dayOne: getScheduleDay(dayOne),
            dayTwo: getScheduleDay(dayTwo),
            dayThree: getScheduleDay(dayThree),
            dayFour: getScheduleDay(dayFour),
            dayFive: getScheduleDay(dayFive),
            daySix: getScheduleDay(daySix),
          },
        ];

      case 7:
        return schedule.length > 1
          ? createMultipleWeekSchedule()
          : [
              {
                dayOne: getScheduleDay(dayOne),
                dayTwo: getScheduleDay(dayTwo),
                dayThree: getScheduleDay(dayThree),
                dayFour: getScheduleDay(dayFour),
                dayFive: getScheduleDay(dayFive),
                daySix: getScheduleDay(daySix),
                daySeven: getScheduleDay(daySeven),
              },
            ];
    }
  };

  const createMultipleWeekSchedule = () => {
    let array: IMultipleWeeksSchedule[] = [];
    schedule.forEach((scheduleWeek) => {
      array.push({
        dayOne: getScheduleDay(scheduleWeek[0]),
        dayTwo: getScheduleDay(scheduleWeek[1]),
        dayThree: getScheduleDay(scheduleWeek[2]),
        dayFour: getScheduleDay(scheduleWeek[3]),
        dayFive: getScheduleDay(scheduleWeek[4]),
        daySix: getScheduleDay(scheduleWeek[5]),
        daySeven: getScheduleDay(scheduleWeek[6]),
      });
    });
    return array;
  };

  const generateId = async () => {
    const id = new Date().getTime().toString();
    setOrderId(id);
    sendOrder(id);
  };

  useEffect(() => {
    let array: string[] = [];
    db.collection(Collection.ADMINS).onSnapshot((snapshot) => {
      array = [];
      snapshot.forEach((item) => array.push(item.data().email));
      setAdmins(array);
    });
  }, []);

  const sendMailToAdmin = (orderID: string) => {
    fetch(
      `${process.env.REACT_APP_BACKEND_URL}/${FetchUrl.SEND_MAIL_TO_ADMINS}`,
      {
        method: "POST",
        body: JSON.stringify({
          id: orderID,
          dest: admins,
        }),
      }
    );
  };

  const sendMail = (orderID: string) => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/${FetchUrl.SEND_MAIL}`, {
      method: "POST",
      body: JSON.stringify({
        id: orderID,
        dest: mail,
        name: name + " " + surname,
        pdf: `${window.location.origin}/pdf/${orderID}`,
      }),
    });
  };

  const sendOrder = (id: string) => {
    db.collection(Collection.ORDERS)
      .doc(id)
      .set({
        costumer: {
          name: name,
          surname: surname,
          company: company,
          mail: mail,
          phone: phone,
          event: event,
        },
        config: {
          date: dateStart,
          program: program,
          days: countDays,
          animators: counter,
          price: price,
        },
        schedule: getDays(),
        created: new Date(),
      });
    sendMailToAdmin(id);
    sendMail(id);
  };

  const check = () => {
    if (verifyInputs()) {
      generateId();
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
        history.push("/success");
      }, 3000);
    } else {
      if (
        mail === "" ||
        phone === "" ||
        name === "" ||
        surname === "" ||
        event === ""
      ) {
        setShow(true);
        setTimeout(() => {
          setShow(false);
        }, 3000);
      } else if (!handleCheckEmail()) {
        setBadFormat(true);
        setTimeout(() => {
          setBadFormat(false);
        }, 3000);
      } else if (!handleCheckPhone()) {
        setBadPhoneFormat(true);
        setTimeout(() => {
          setBadPhoneFormat(false);
        }, 3000);
      } else if (!handleCheckName()) {
        setBadNameFormat(true);
        setTimeout(() => {
          setBadNameFormat(false);
        }, 3000);
      } else if (!handleCheckSurName()) {
        setBadSurNameFormat(true);
        setTimeout(() => {
          setBadSurNameFormat(false);
        }, 3000);
      } else if (!handleCheckEvent()) {
        setBadEventFormat(true);
        setTimeout(() => {
          setBadEventFormat(false);
        }, 3000);
      } else {
        setShow(true);
        setTimeout(() => {
          setShow(false);
        }, 3000);
      }
    }
  };

  const verifyInputs = (): boolean => {
    return (
      name !== "" &&
      surname !== "" &&
      handleCheckName() &&
      handleCheckSurName() &&
      mail !== "" &&
      handleCheckEmail() &&
      phone !== "" &&
      handleCheckPhone() &&
      event !== "" &&
      handleCheckEvent()
    );
  };
  // eslint-disable-next-line
  const regexEmail = /^((([a-z]|\d|[!#%&'\-=_`{}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#%&'\-=_`{}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;

  const regexPhone = /^([0-9]{10})|((\+421)[0-9]{9})$/;

  const regexName = /^[A-Z].{2,}$/;

  const handleCheckPhone = (): boolean => {
    return phone.match(regexPhone) ? true : false;
  };

  const handleCheckEmail = (): boolean => {
    return mail.match(regexEmail) ? true : false;
  };

  const handleCheckName = (): boolean => {
    return name.match(regexName) ? true : false;
  };

  const handleCheckSurName = (): boolean => {
    return surname.match(regexName) ? true : false;
  };

  const handleCheckEvent = (): boolean => {
    return event.match(regexName) ? true : false;
  };

  const handleChange = (index: number, value: string) => {
    switch (index) {
      case 0:
        setName(value);
        break;

      case 1:
        setSurname(value);
        break;

      case 2:
        setCompany(value);
        break;

      case 3:
        setMail(value);
        break;

      case 4:
        setPhone(value);
        break;

      case 5:
        setEvent(value);
        break;
    }
  };

  const FormMap = FORM.map((item, index) => (
    <div key={item.label}>
      <Form.Group controlId="exampleForm.ControlInput1" className="form-name">
        <Form.Label color="#56576F" className="form-label">
          {item.label}
        </Form.Label>
        <Form.Control
          className="form-control"
          type={item.type}
          defaultValue={inputs[index]}
          onChange={(e) => handleChange(index, e.target.value)}
        />
      </Form.Group>
    </div>
  ));
  return (
    <div>
      <div className="form-container">
        <p className="form-title">{FORM_TITLE}</p>
        <div className="forms">
          {FormMap}
          <div className="form-name">
            <div>
              <Form.Label color="#56576F" className="form-label">
                {BEGINNING_DATE}
              </Form.Label>
            </div>
            <p>{dateStart.toLocaleDateString("sk")}</p>
          </div>
        </div>
        <Button variant="none" className="button-send" onClick={check}>
          {BUTTON_SEND}
        </Button>
      </div>

      {show && (
        <SnackBarAlert text={SNACKBAR_EMPTY_INPUT} variant={Variant.ERROR} />
      )}
      {success && (
        <SnackBarAlert text={SNACKBAR_FORM_SUCCESS} variant={Variant.SUCCESS} />
      )}
      {badFromat && (
        <SnackBarAlert text={SNACKBAR_WRONG_EMAIL} variant={Variant.ERROR} />
      )}
      {badPhoneFormat && (
        <SnackBarAlert text={SNACKBAR_WRONG_NUMBER} variant={Variant.ERROR} />
      )}
      {badNameFormat && (
        <SnackBarAlert text={SNACKBAR_WRONG_NAME} variant={Variant.ERROR} />
      )}
      {badSurNameFormat && (
        <SnackBarAlert text={SNACKBAR_WRONG_SURNAME} variant={Variant.ERROR} />
      )}
      {badEventFormat && (
        <SnackBarAlert text={SNACKBAR_WRONG_EVENT} variant={Variant.ERROR} />
      )}
    </div>
  );
};

export default Formular;
