import React, { useContext, useState } from "react";
import BasicSelection from "../S1-basic-configuration/basic-selection";
import { HowManyDays, HowManyDaysShimmer } from "../../strings/string";
import "./activities.scss";
import Section from "./section";
import { UserContext } from "../../context-api/context";
import { Button } from "react-bootstrap";
import { CONFIRM_CHOICE } from "../../strings/string";
import ModalAddNewWeek from "../modals/modal-add-new-week";

const Activities = () => {
  const { countDays } = useContext(UserContext);
  const [clickedButton, setClickedButton] = useState(false);
  const [percentage, setPercentage] = useState<number[]>([0, 0, 0, 0, 0, 0, 0]);
  const [isProgramDone, setIsProgramDone] = useState(false);
  const [openAddWeekModal, setOpenAddWeekModal] = useState(false);

  const handleClickNext = () => {
    setClickedButton(true);
  };

  const shimmerMap = HowManyDaysShimmer.map((item, index) => (
    <div key={item.day}>
      <Section
        day={item.day}
        numberDay={item.numberDay}
        clickedButton={clickedButton}
        setClickedButton={setClickedButton}
        isProgramDone={isProgramDone}
        setIsProgramDone={setIsProgramDone}
        setOpenAddWeekModal={setOpenAddWeekModal}
      />
    </div>
  ));

  const daysMap = HowManyDays.map(
    (item, index) =>
      index < countDays && (
        <div key={item.day}>
          <Section
            day={item.day}
            numberDay={item.numberDay}
            clickedButton={clickedButton}
            setClickedButton={setClickedButton}
            percentage={percentage}
            setPercentage={setPercentage}
            isProgramDone={isProgramDone}
            setIsProgramDone={setIsProgramDone}
            setOpenAddWeekModal={setOpenAddWeekModal}
          />
        </div>
      )
  );

  const shouldShowShimmer =
    countDays === 0 ? <div>{shimmerMap}</div> : <>{daysMap}</>;

  return (
    <div className="second-screen-container">
      <BasicSelection />

      <div className="day-activity">
        {shouldShowShimmer}
        <div className="confirm-choice-container">
          <Button
            variant="none"
            className="button-accept"
            onClick={handleClickNext}
          >
            <p className="button-accept-text">{CONFIRM_CHOICE}</p>
          </Button>
        </div>
      </div>

      <ModalAddNewWeek
        show={openAddWeekModal}
        setShow={setOpenAddWeekModal}
        setIsProgramDone={setIsProgramDone}
        setPercentage={setPercentage}
      />
    </div>
  );
};

export default Activities;
