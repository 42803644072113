import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ActivityCard from "./index-cards/activity-card";
import AdminsCard from "./index-cards/admins-card";
import OrdersCard from "./index-cards/orders-card";
import UsersCard from "./index-cards/users-card";
import "./dashboard-index.scss";
import Orders from "./orders/orders";

const DashboardIndex = () => {
  return (
    <div>
      <Container fluid className="dashboard-index">
        <Row>
          <Col xl="3">
            <AdminsCard />
          </Col>
          <Col xl="3">
            <ActivityCard />
          </Col>
          <Col xl="3">
            <OrdersCard />
          </Col>
          <Col xl="3">
            <UsersCard />
          </Col>
        </Row>
      </Container>
      <Orders />
    </div>
  );
};

export default DashboardIndex;
