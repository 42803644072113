import React, { useEffect, useState } from "react";
import { Collection } from "../../enums/collections";
import { db } from "../../firebase/firebase-init";
import { IFirebaseOrder } from "../../models/firebase-order";
import PdfHeader from "./pdf-header";
import Times from "./times";
import "./pdf.scss";
import { useParams } from "react-router-dom";
import { getAnimatorCount } from "../../utils";
import ActiveDays from "./active-days";

export interface IEveningTimes {
  minidisko: string[];
  showtime: string[];
  lateNight: string[];
}

const Pdf = () => {
  const [order, setOrder] = useState<IFirebaseOrder>();
  const [times, setTimes] = useState<string[]>([]);
  const [eveningTimes, setEveningTimes] = useState<IEveningTimes>();
  let { orderId } = useParams<{ orderId: string }>();

  useEffect(() => {
    db.collection(Collection.ORDERS)
      .doc(orderId)
      .get()
      .then((order) => {
        setOrder({ ...order.data(), id: order.id } as IFirebaseOrder);
      })
      .then(() => {
        setTimeout(() => {
          window.print();
        }, 1000);
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    order &&
      db
        .collection(Collection.COMBINATIONS)
        .doc(getAnimatorCount(order))
        .get()
        .then((combination) => {
          setTimes(combination.data()!.times as string[]);
          setEveningTimes({
            minidisko: combination.data()!.minidisko,
            showtime: combination.data()!.showtime,
            lateNight: combination.data()!.lateNight,
          });
        });
  }, [order]);

  useEffect(() => {
    document.body.style.background = "white";
  }, []);

  return (
    <div className="pdf">
      <style type="text/css" media="print">
        {" @page { size: A4 portrait; margin: 10px; background-color:white } "}
        {
          " @media print { .pdf-table-container { page-break-after: always; } } "
        }
      </style>

      {order?.schedule.map((orderSchedule) => (
        <>
          <PdfHeader order={order} />
          <div className="pdf-table-container">
            <Times times={times} eveningTimes={eveningTimes} />
            <ActiveDays
              schedule={orderSchedule}
              times={times}
              eveningTimes={eveningTimes}
            />
          </div>
        </>
      ))}
    </div>
  );
};

export default Pdf;
