import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { Collection } from "../../enums/collections";
import { db } from "../../firebase/firebase-init";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { TextareaAutosize, TextField } from "@material-ui/core";
import "./activities.scss";
import {
  Button,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import {
  ADD_ACTIVITY,
  ARCHERY,
  CATEGORIY,
  DESCRIPTION,
  LONG_DESCRIPTION,
  PROGRAM,
  TITLE,
} from "../../strings/string";
import { ProgramType } from "../../enums/program-type";

export interface FirebaseCategory {
  icon: string;
  program: string;
  title: string;
}

const AddActivity = () => {
  const [categories, setCategories] = useState<FirebaseCategory[]>([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [longDescription, setlongDescription] = useState("");
  const [program, setProgram] = useState<string>(ProgramType.DAY);

  const handleChange = (event: SelectChangeEvent<string>) => {
    setSelectedCategory(event.target.value);
  };

  const handleChangeTitle = (event: ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const handleChangeDescription = (event: ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value);
  };

  const handleChangeLongDescription = (
    event: ChangeEvent<HTMLTextAreaElement>
  ) => {
    setlongDescription(event.target.value);
  };

  const handleChangeProgram = (event: ChangeEvent<HTMLInputElement>) => {
    setProgram(event.target.value);
  };

  const addActivity = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    selectedCategory.length > 0 &&
      db
        .collection(Collection.ACTIVITIES)
        .add({
          category: "",
          description: description,
          detail: {
            description: longDescription,
            img: ARCHERY,
          },
          program: program,
          title: title,
          type: selectedCategory,
        })
        .then(() => {
          setSelectedCategory("");
          setTitle("");
          setDescription("");
          setlongDescription("");
          setProgram("day");
        });
  };

  useEffect(() => {
    db.collection(Collection.CATEGORIES).onSnapshot((categories) =>
      categories.forEach((category) => {
        setCategories((prevstate) => [
          ...prevstate,
          category.data() as FirebaseCategory,
        ]);
      })
    );
  }, []);

  const menuItems = categories
    .filter((category) => category.program === program)
    .map((category, id) => (
      <MenuItem key={id} value={category.title}>
        {category.title}
      </MenuItem>
    ));

  return (
    <div className="dashboard-activities-container">
      <form onSubmit={addActivity}>
        <div className="activities-form-row">
          <FormControl fullWidth>
            <InputLabel>Kategória</InputLabel>

            <Select
              required
              value={selectedCategory}
              label={CATEGORIY}
              onChange={handleChange}
            >
              {menuItems}
            </Select>
          </FormControl>

          <TextField
            fullWidth
            label={TITLE}
            variant="outlined"
            value={title}
            required
            onChange={handleChangeTitle}
          />

          <TextField
            fullWidth
            required
            label={DESCRIPTION}
            variant="outlined"
            value={description}
            onChange={handleChangeDescription}
          />
        </div>
        <TextareaAutosize
          required
          placeholder={LONG_DESCRIPTION}
          rows={3}
          style={{ width: "100%" }}
          value={longDescription}
          onChange={handleChangeLongDescription}
        />

        <div className="buttoms-wrapper">
          <FormControl>
            <FormLabel>{PROGRAM}</FormLabel>
            <RadioGroup row value={program} onChange={handleChangeProgram}>
              <FormControlLabel value="day" control={<Radio />} label="Denný" />
              <FormControlLabel
                value="night"
                control={<Radio />}
                label="Večerný"
              />
            </RadioGroup>
          </FormControl>

          <Button
            type="submit"
            variant="contained"
            sx={{ backgroundColor: "red" }}
          >
            {ADD_ACTIVITY}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddActivity;
