import React, { useEffect, useState } from "react";
import { Collection } from "../../enums/collections";
import { db } from "../../firebase/firebase-init";
import { IFirebaseActivity } from "../../models/firebase-activity";
import DashboardActivityCard from "./dashboard-activity-card";

const EditRemoveActivity = () => {
  const [activities, setActivities] = useState<IFirebaseActivity[]>([]);

  useEffect(() => {
    let array: IFirebaseActivity[] = [];
    db.collection(Collection.ACTIVITIES).onSnapshot((activities) => {
      array = [];
      activities.forEach((activity) => {
        array.push({
          ...activity.data(),
          id: activity.id,
        } as IFirebaseActivity);
      });
      setActivities(array);
    });
  }, []);

  const activitiesSection = activities.map((activity) => (
    <DashboardActivityCard activity={activity} key={activity.id} />
  ));

  return <div className="activities-edit-delete">{activitiesSection}</div>;
};

export default EditRemoveActivity;
