import React from "react";
import { Button } from "react-bootstrap";
import "./basic-selection.scss";
import { Link, useLocation } from "react-router-dom";
import CountAnimators from "./count-animators";
import CountDays from "./count-days";
import ProgramTime from "./program-time";
import Price from "./price";
import DateStart from "./date-start";
import { CONFIRM_CHOICE } from "../../strings/string";

const BasicSelection = () => {
  const { pathname } = useLocation();

  const shouldShowContinueButton = !pathname.includes("/activities") && (
    <div className="activities-link">
      <Link to="/activities">
        <Button className="button-s1-confirm">
          <p className="button-text">{CONFIRM_CHOICE}</p>
        </Button>
      </Link>
    </div>
  );

  return (
    <div className="basic-selection">
      <div className="basic-selection-container">
        <div className="inside-container">
          <div className="forms-row">
            <CountAnimators />
            <CountDays />
            <ProgramTime />
            <DateStart />
            <Price />

            {shouldShowContinueButton}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicSelection;
