import React, { useContext, useState } from "react";
import "./program-time.scss";
import { UserContext } from "../../context-api/context";
import { PROGRAM_TITLE, SNACKBAR_DEACTIVATE_ERROR } from "../../strings/string";
import ModalAlert from "./modal-alert";
import SnackBarAlert from "../S2-select-activities/snack-bar";
import { Variant } from "../../enums/snackbar";
import { Program } from "../../enums/activity";
import DisableTimeButton from "./disable-time-button";

const ProgramTime = () => {
  const { program, setIsShowed, setIsDay } = useContext(UserContext);
  const [alertSnack, setAlertSnack] = useState(false);

  const handleSetDay = () => {
    setIsShowed(true);
    setIsDay(true);
  };

  const handleSetEvening = () => {
    setIsShowed(true);
    setIsDay(false);
  };

  const handleShowAlert = () => {
    setAlertSnack(true);
    setTimeout(() => {
      setAlertSnack(false);
    }, 3000);
  };

  const showSnackBarAlert = alertSnack && (
    <SnackBarAlert variant={Variant.ERROR} text={SNACKBAR_DEACTIVATE_ERROR} />
  );

  const programNotIncludeNight = !program.includes(Program.NIGHT) ? (
    <DisableTimeButton handleChange={handleShowAlert} />
  ) : (
    <DisableTimeButton handleChange={handleSetDay} />
  );

  const programNotIncludeDay = !program.includes(Program.DAY) ? (
    <DisableTimeButton handleChange={handleShowAlert} dayButton={false} />
  ) : (
    <DisableTimeButton handleChange={handleSetEvening} dayButton={false} />
  );

  return (
    <div className="timer-container">
      <p className="program-title">{PROGRAM_TITLE}</p>
      <div className="animator-count">
        <div className="time-select">
          {programNotIncludeNight}
          {programNotIncludeDay}
        </div>
      </div>
      <ModalAlert />
      {showSnackBarAlert}
    </div>
  );
};

export default ProgramTime;
