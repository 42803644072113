import { DataGrid, GridRenderCellParams } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { IActiveUsers, IUserTable } from "../../models/user-list";
import "./user-list.scss";

const UserList = () => {
  const [userList, setUserList] = useState<IUserTable[]>([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/getAllUsers`)
      .then((response) => response.json())
      .then((data: IActiveUsers) => {
        data.users.forEach((element) => {
          Object.assign(element, { id: element.uid });
        });
        return data;
      })
      .then((data) => setUserList(data.users))
      .catch((err) => err);
    // eslint-disable-next-line
  }, []);

  const columns = [
    {
      field: "photoURL",
      headerName: "Obrázok",
      renderCell: (params: GridRenderCellParams<string>) =>
        params.value && (
          <img
            src={params.value}
            alt="img"
            width="40"
            height="40"
            className="user-image"
          />
        ),
    },
    { field: "id", headerName: "ID", width: 300 },
    { field: "displayName", headerName: "Meno", width: 250 },
    { field: "email", headerName: "Email", width: 250 },
    {
      field: "metadata.creationTime",
      headerName: "Vytvorené",
      width: 300,
      valueGetter: (params: any) => {
        return params.getValue(params.id, "metadata").creationTime;
      },
    },
    {
      field: "metadata.lastSignInTime",
      headerName: "Posledné prihlásenie",
      width: 300,
      valueGetter: (params: any) => {
        return params.getValue(params.id, "metadata").lastSignInTime;
      },
    },
  ];

  return (
    <div className="user-list-container">
      <DataGrid
        sx={{ backgroundColor: "#ffffff" }}
        rows={userList}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        checkboxSelection={false}
      />
    </div>
  );
};

export default UserList;
