import React, { useEffect, useState } from "react";
import "./tags.scss";
import Snackbar from "@material-ui/core/Snackbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import { ISnackBar } from "../../models/snack-bar";
import { Variant, VariantColor } from "../../enums/snackbar";
import { AlertIcon } from "../../assets/alert-icon";
import "./snack-bar.scss";

const SnackBarAlert = (props: ISnackBar) => {
  const [open, setOpen] = useState(true);
  const [color, setColor] = useState("");

  useEffect(() => {
    switch (props.variant) {
      case Variant.SUCCESS:
        setColor(VariantColor.SUCCESS);
        break;

      case Variant.ALERT:
        setColor(VariantColor.ALERT);
        break;

      case Variant.ERROR:
        setColor(VariantColor.ERROR);
        break;
    }
  }, [props.variant]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Snackbar
      open={open}
      onClose={() => setOpen(false)}
      autoHideDuration={3000}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      style={{ backgroundColor: color }}
      className="snack-bar"
    >
      <div className="snack-bar-body">
        <div className="left-icon-container">
          {props.variant === Variant.SUCCESS ? (
            <FontAwesomeIcon icon={faCheckCircle} color="white" size="2x" />
          ) : (
            <AlertIcon />
          )}
        </div>
        <p className="text">{props.text}</p>

        <div onClick={handleClose}>
          <FontAwesomeIcon
            icon={faTimes}
            color="white"
            size="1x"
            className="icon"
          />
        </div>
      </div>
    </Snackbar>
  );
};

export default SnackBarAlert;
