import "./activities.scss";
import AddActivity from "./add-activity";
import EditRemoveActivity from "./edit-remove-activity";

const Activities = () => {
  return (
    <div>
      <AddActivity />
      <EditRemoveActivity />
    </div>
  );
};

export default Activities;
