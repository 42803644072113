import React, { ChangeEvent, useEffect, useState } from "react";
import { DataGrid, GridSelectionModel } from "@mui/x-data-grid";
import { Button, TextField } from "@mui/material";
import "./admins.scss";
import { db } from "../../firebase/firebase-init";
import { Collection } from "../../enums/collections";
import { IAdmins } from "../../models/admins";
import { ADD_ADMIN, REMOVE_ADMIN } from "../../strings/string";

const Admins = () => {
  const [admins, setadmins] = useState<IAdmins[]>([]);
  const [selected, setSelected] = useState<GridSelectionModel>([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  const columns = [
    { field: "id", headerName: "ID", width: 300 },
    { field: "firstName", headerName: "Meno", width: 250 },
    { field: "lastName", headerName: "Priezvisko", width: 250 },
    { field: "email", headerName: "Email", width: 300 },
  ];

  useEffect(() => {
    let array: IAdmins[] = [];
    db.collection(Collection.ADMINS).onSnapshot((snapshot) => {
      array = [];
      snapshot.forEach((item) =>
        array.push({ id: item.id, ...item.data() } as IAdmins)
      );
      setadmins(array);
    });
  }, []);

  const handleChangeEmail = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setEmail(event.target.value);
  };

  const handleChangeName = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setFirstName(event.target.value);
  };

  const handleChangeSecondName = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setLastName(event.target.value);
  };

  const addAdmin = () => {
    db.collection(Collection.ADMINS)
      .add({
        firstName: firstName,
        lastName: lastName,
        email: email,
      })
      .then(() => {
        setFirstName("");
        setLastName("");
        setEmail("");
      });
  };

  const removeAdmins = () => {
    selected.forEach((admin) => {
      db.collection(Collection.ADMINS)
        .doc(admin as string)
        .delete();
    });
  };

  const showRemoveButton = selected.length > 0 && (
    <div className="admin-remove-button-container">
      <Button
        sx={{
          backgroundColor: "#c31702",
          "&:hover": { backgroundColor: "#db4e17" },
        }}
        variant="contained"
        onClick={removeAdmins}
        className="navigate-back-button"
      >
        {REMOVE_ADMIN}
      </Button>
    </div>
  );

  return (
    <div className="admins-container">
      <div className="add-admin-section">
        <TextField
          id="outlined-basic"
          label="Meno"
          variant="outlined"
          value={firstName}
          onChange={handleChangeName}
        />
        <TextField
          id="outlined-basic"
          label="Priezvisko"
          variant="outlined"
          value={lastName}
          onChange={handleChangeSecondName}
        />
        <TextField
          id="outlined-basic"
          label="E-mail"
          variant="outlined"
          value={email}
          onChange={handleChangeEmail}
        />
      </div>
      <div className="admin-add-button-container">
        <Button
          sx={{
            backgroundColor: "#c31702",
            "&:hover": { backgroundColor: "#db4e17" },
          }}
          variant="contained"
          onClick={addAdmin}
          className="navigate-back-button"
        >
          {ADD_ADMIN}
        </Button>
      </div>
      <DataGrid
        sx={{ backgroundColor: "#ffffff" }}
        rows={admins}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        checkboxSelection
        onSelectionModelChange={(items) => setSelected(items)}
      />

      {showRemoveButton}
    </div>
  );
};

export default Admins;
