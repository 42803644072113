import "./orders.scss";
import { useEffect, useState } from "react";
import { db } from "../../firebase/firebase-init";
import { Collection } from "../../enums/collections";
import { IFirebaseOrder } from "../../models/firebase-order";
import OrderTable from "./order-table";

const Orders = () => {
  const [tableRowsFinished, setTableRowsFinished] = useState<IFirebaseOrder[]>(
    []
  );

  useEffect(() => {
    let array: IFirebaseOrder[] = [];
    db.collection(Collection.ORDERS)
      .orderBy("created", "desc")
      .onSnapshot((snapShot) => {
        array = [];
        snapShot.forEach((order) =>
          array.push({ id: order.id, ...order.data() } as IFirebaseOrder)
        );
        setTableRowsFinished(array);
      });
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <OrderTable tableRows={tableRowsFinished} />
    </div>
  );
};

export default Orders;
