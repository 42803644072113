import React, { useEffect, useState } from "react";
import { Collection, Documents } from "../../enums/collections";
import { db } from "../../firebase/firebase-init";
import { ICombinations } from "../../models/program";
import CombinationSection from "./combinations-section";
import "./combinations.scss";

const Combinations = () => {
  const [oneAnimator, setOneAnimator] = useState<ICombinations | null>(null);
  const [twoAnimators, setTwoAnimators] = useState<ICombinations | null>(null);
  const [threeAnimators, setThreeAnimators] = useState<ICombinations | null>(
    null
  );
  const [oneAnimatorWeekend, setOneAnimatorWeekend] =
    useState<ICombinations | null>(null);
  const [twoAnimatorsWeekend, setTwoAnimatorsWeekend] =
    useState<ICombinations | null>(null);
  const [threeAnimatorsWeekend, setThreeAnimatorsWeekend] =
    useState<ICombinations | null>(null);

  useEffect(() => {
    db.collection(Collection.COMBINATIONS)
      .doc(Documents.ONE_ANIMATOR)
      .onSnapshot((combination) => {
        setOneAnimator(combination.data() as ICombinations);
      });

    db.collection(Collection.COMBINATIONS)
      .doc(Documents.TWO_ANIMATORS)
      .onSnapshot((combination) => {
        setTwoAnimators(combination.data() as ICombinations);
      });

    db.collection(Collection.COMBINATIONS)
      .doc(Documents.THREE_ANIMATORS)
      .onSnapshot((combination) => {
        setThreeAnimators(combination.data() as ICombinations);
      });
    db.collection(Collection.COMBINATIONS)
      .doc(Documents.ONE_ANIMATOR_WEEKEND)
      .onSnapshot((combination) => {
        setOneAnimatorWeekend(combination.data() as ICombinations);
      });

    db.collection(Collection.COMBINATIONS)
      .doc(Documents.TWO_ANIMATORS_WEEKEND)
      .onSnapshot((combination) => {
        setTwoAnimatorsWeekend(combination.data() as ICombinations);
      });

    db.collection(Collection.COMBINATIONS)
      .doc(Documents.THREE_ANIMATORS_WEEKEND)
      .onSnapshot((combination) => {
        setThreeAnimatorsWeekend(combination.data() as ICombinations);
      });
  }, []);

  return (
    <div>
      <CombinationSection
        combination={oneAnimator}
        animatorCount={Documents.ONE_ANIMATOR}
      />
      <CombinationSection
        combination={twoAnimators}
        animatorCount={Documents.TWO_ANIMATORS}
      />
      <CombinationSection
        combination={threeAnimators}
        animatorCount={Documents.THREE_ANIMATORS}
      />
      <CombinationSection
        combination={oneAnimatorWeekend}
        animatorCount={Documents.ONE_ANIMATOR_WEEKEND}
      />
      <CombinationSection
        combination={twoAnimatorsWeekend}
        animatorCount={Documents.TWO_ANIMATORS_WEEKEND}
      />
      <CombinationSection
        combination={threeAnimatorsWeekend}
        animatorCount={Documents.THREE_ANIMATORS_WEEKEND}
      />
    </div>
  );
};

export default Combinations;
