import { CircularProgress } from "@mui/material";
import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import { UserContext } from "../context-api/context";
import "./admin-access.scss";

const AdminAccess: React.FC = ({ children }) => {
  const { user, isFetchingUser } = useContext(UserContext);

  const content = isFetchingUser ? (
    <div className="loader-container">
      <CircularProgress />
    </div>
  ) : (
    <Redirect to={"/"} />
  );

  return user?.isAdmin && !isFetchingUser ? <>{children}</> : content;
};

export default AdminAccess;
